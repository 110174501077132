@font-face {
    font-family: 'Righteous';
    src: url("fonts/Righteous-Regular.ttf");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Montserrat';
    src: url('fonts/Montserrat-Regular.woff') format('woff2'), url('fonts/Montserrat-Regular.woff2') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Montserrat';
    src: url('fonts/Montserrat-Bold.woff2') format('woff2'), url('fonts/Montserrat-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Montserrat';
    src: url('fonts/Montserrat-Light.woff2') format('woff2'), url('fonts/Montserrat-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Montserrat';
    src: url('fonts/Montserrat-ExtraBold.woff2') format('woff2'), url('fonts/Montserrat-ExtraBold.woff') format('woff');
    font-weight: 800;
    font-style: normal;
}

@font-face {
    font-family: 'Montserrat';
    src: url('fonts/Montserrat-SemiBold.woff2') format('woff2'), url('fonts/Montserrat-SemiBold.woff') format('woff');
    font-weight: 600;
    font-style: normal;
}

body h2 {
    font-weight: 500 !important;
}

body {
    font-family: 'Montserrat' !important;
}

body p {
    font-weight: 400 !important;
}

.backtopicon button.btn.scroll-top {
    position: fixed;
    bottom: 60px;
    right: 60px;
    z-index: 1 !important;
}

button.btn.btn-primary:focus {
    outline: none !important;
    box-shadow: none !important;
}

button.btn.btn-primary:active {
    background-color: #277ec8 !important;
    border-color: #277ec8 !important;
}

/**Header Css Starts**/
section#header_sec {position: fixed;top: 0;width: 100%;display: block;transition: top 0.3s;background-color: #fff;z-index: 1111;}
.header_menu .navbar button.navbar-toggler {background-color: #FFF;}
.header_menu .navbar span.navbar-toggler-icon {color: #277ec8 !important;}
div#collapsibleNavbar {position: absolute;right: 0px;}
.header_menu {padding: 10px 100px;}
.header_menu .navbar {padding: 10px 0px;}
.header_menu .navbar ul {display: flex;align-items: center;}
.header_menu .navbar ul.navbar-nav li a {font-size: 14px;color: #277ec8;margin: 0px 15px;font-family: 'Montserrat';font-weight: 400;text-transform: uppercase;}
.header_menu .navbar ul.navbar-nav li a:hover {color: #0D6BA0;text-decoration: none;}
.header_menu .navbar ul.navbar-nav li a.active {color: #00B8E8 !important;border-bottom: 2px solid #00B8E8;font-size: 14px;}
.header_menu .navbar ul.navbar-nav li a.dropdown-toggle {color: #277ec8 !important;cursor: pointer;}
.header_menu .navbar ul.navbar-nav li.dropdown .dropdown-menu.show {background-color: #277ec8;}
.header_menu .navbar ul.navbar-nav li.dropdown .dropdown-menu a.dropdown-item {color: #ffffffa6;border-bottom: 2px solid #277ec8;padding-bottom: 11px;
padding-top: 11px;width: 90%;}
.header_menu .navbar ul.navbar-nav li.dropdown .dropdown-menu a.dropdown-item:hover {color: #fff !important;background-color: transparent !important;}
.header_menu .navbar ul.navbar-nav li.dropdown .dropdown-menu a.dropdown-item.active {color: #fff !important;background-color: transparent !important;}
.header_menu .navbar ul.navbar-nav li a button.btn.btn-primary {background-color: #277ec8;border: none;border-radius: 25px;font-size: 14px;text-transform: uppercase;
font-weight: 600;padding: 11px 22px;font-family: 'Montserrat';color: #fff;}
/**Header Css Ends**/






#mob_platforms {
    display: none;
}

.mobile_slide {
    display: none !important;
}

.desk_view {
    display: block;
}

#eventimg_slidr ol.carousel-indicators {
    display: none;
}

#eventimg18_slidr ol.carousel-indicators {
    display: none;
}

.carousel-inner {
    z-index: 111;
}

.eventslide span.carousel-control-prev-icon:after {
    font-family: "FontAwesome";
    font-weight: 900;
    content: "\f053";
    font-size: 18px;
}

.eventslide span.carousel-control-next-icon:after {
    font-family: "FontAwesome";
    font-weight: 900;
    content: "\f054";
    font-size: 18px;
}

.eventslide span.carousel-control-prev-icon {
    width: 40px;
    height: 40px;
    background-color: #043E6E;
    border-radius: 50%;
}

.eventslide span.carousel-control-next-icon {
    width: 40px;
    height: 40px;
    background-color: #043E6E;
    border-radius: 50%;
}

#eventimg_slidr {
    position: absolute;
    bottom: 60px;
    left: -70px;
    z-index: 1;
}

#eventimg_slidr {
    position: absolute;
    bottom: 60px;
    left: -70px;
    z-index: 1;
}

#eventimg18_slidr {
    position: absolute;
    bottom: 60px;
    left: -70px;
    z-index: 1;
}

#eventimg18_slidr {
    position: absolute;
    bottom: 60px;
    left: -70px;
    z-index: 1;
}

#diwaliimg_slidr {
    position: absolute;
    bottom: 60px;
    left: -70px;
    z-index: 1;
}

#ceobdyimg_slidr {
    position: absolute;
    bottom: 60px;
    left: -70px;
    z-index: 1;
}

#eventimg_slidr span.carousel-control-prev-icon {
    padding-top: 7px;
    padding-right: 2px;
    bottom: -50px;
    position: absolute;
    left: 90px;
}

#eventimg_slidr span.carousel-control-next-icon {
    padding-top: 7px;
    padding-left: 2px;
    position: absolute;
    bottom: -50px;
    left: 0px;
}

#eventimg18_slidr span.carousel-control-prev-icon {
    padding-top: 7px;
    padding-right: 2px;
    bottom: -50px;
    position: absolute;
    left: 90px;
}

#eventimg18_slidr span.carousel-control-next-icon {
    padding-top: 7px;
    padding-left: 2px;
    position: absolute;
    bottom: -50px;
    left: 0px;
}

#diwaliimg_slidr span.carousel-control-prev-icon {
    padding-top: 7px;
    padding-right: 2px;
    bottom: -50px;
    position: absolute;
    left: 90px;
}

#diwaliimg_slidr span.carousel-control-next-icon {
    padding-top: 7px;
    padding-left: 2px;
    position: absolute;
    bottom: -50px;
    left: 0px;
}

#ceobdyimg_slidr span.carousel-control-prev-icon {
    padding-top: 7px;
    padding-right: 2px;
    bottom: -50px;
    position: absolute;
    left: 90px;
}

#ceobdyimg_slidr span.carousel-control-next-icon {
    padding-top: 7px;
    padding-left: 2px;
    position: absolute;
    bottom: -50px;
    left: 0px;
}

#annualimg_slidr span.carousel-control-prev-icon {
    padding-top: 7px;
    padding-right: 2px;
    position: absolute;
    bottom: -50px;
    right: 0px;
}

#partyimg_slidr span.carousel-control-prev-icon {
    padding-top: 7px;
    padding-right: 2px;
    position: absolute;
    bottom: -50px;
    right: 0px;
}

#teambrdyimg_slidr span.carousel-control-prev-icon {
    padding-top: 7px;
    padding-right: 2px;
    position: absolute;
    bottom: -50px;
    right: 0px;
}

#annualimg_slidr span.carousel-control-next-icon {
    padding-top: 7px;
    padding-left: 4px;
    bottom: -50px;
    right: 80px;
    position: absolute;
}

#partyimg_slidr span.carousel-control-next-icon {
    padding-top: 7px;
    padding-left: 2px;
    bottom: -50px;
    right: 80px;
    position: absolute;
}

#teambrdyimg_slidr span.carousel-control-next-icon {
    padding-top: 7px;
    padding-left: 2px;
    bottom: -50px;
    right: 80px;
    position: absolute;
}

#annualimg_slidr {
    position: absolute;
    bottom: 60px;
    right: -70px;
    z-index: 1;
}

#partyimg_slidr {
    position: absolute;
    bottom: 60px;
    right: -70px;
    z-index: 1;
}

#teambrdyimg_slidr {
    position: absolute;
    bottom: 60px;
    right: -70px;
    z-index: 1;
}

.eventslide .carousel-control-prev-icon {
    background-image: none !important;
}

.eventslide .carousel-control-next-icon {
    background-image: none !important;
}

.eventslide a.carousel-control-prev {
    width: 60px;
}

#annualimg_slidr a.carousel-control-prev {
    position: absolute;
    left: 70%;
}

#partyimg_slidr a.carousel-control-prev {
    position: absolute;
    left: 70%;
}

#teambrdyimg_slidr a.carousel-control-prev {
    position: absolute;
    left: 70%;
}

.eventslide a.carousel-control-next {
    width: 60px;
}

#eventimg_slidr a.carousel-control-next {
    left: 140px;
}

#eventimg18_slidr a.carousel-control-next {
    left: 140px;
}

#diwaliimg_slidr a.carousel-control-next {
    left: 140px;
}

#ceobdyimg_slidr a.carousel-control-next {
    left: 140px;
}

.carousel-control-prev,
.carousel-control-next {
    opacity: 1 !important;
}

.carousel-control-prev,
.carousel-control-next {
    opacity: 1 !important;
}

.container-fluid.event_section {
    margin: 80px 0px;
}

.events_links a {
    margin: 0px 10px;
    font-size: 24px;
    padding: 10px 15px;
    font-family: 'Montserrat';
    color: #212020;
    letter-spacing: 1.15px;
    font-weight: 600;
}

.events_links a:hover {
    color: #3ec5e9;
}

.event-active-link {
    color: #fff !important;
    background-color: #3EC5E9;
    border-color: transparent;
    border-radius: 0px;
    border-top-left-radius: 10px;
    border-bottom-right-radius: 10px;
    font-weight: 600;
}

.container-fluid.event_sec1 {
    margin-top: 80px;
    margin-bottom: 80px;
    position: relative;
}

.annual_colrt h2 {
    letter-spacing: 0.7px;
    color: #003974;
    text-transform: uppercase;
    text-align: left;
    font-size: 64px;
    font-weight: 800 !important;
    padding-top: 30px;
    padding-bottom: 30px;
}

.annual_colrt h2 span {
    font-size: 64px;
    font-weight: 300;
}

.annual_colrt p {
    letter-spacing: 0.18px;
    color: #666666;
    font-size: 16px;
    line-height: 30px;
    margin-top: 30px;
}

.annual_colrt h2:before {
    background-color: #D3EDF4;
    content: '';
    position: absolute;
    height: 220px;
    width: 130px;
    z-index: -1;
    top: 0px;
    left: 0px;
}

.annual_colrt hr {
    margin-top: 30px;
    margin-bottom: 30px;
}

.container-fluid.event_sec2 {
    margin-top: 80px;
    margin-bottom: 80px;
    position: relative;
}

img.slide_img_sml {
    border: 5px solid #fff;
}


/**Footer Css Starts**/

button.btn:focus {
    box-shadow: none;
    outline: none;
}

.footer_sec {
    background-color: #043E6E;
    padding: 30px 0px;
}

.footer_sec h6 {
    color: #fff;
    font-size: 16px;
    line-height: 24px;
    font-weight: 600;
    font-family: 'Montserrat';
    margin-bottom: 20px;
}

.footer_sec span {
    color: #fff;
    font-size: 14px;
    line-height: 21px;
    font-weight: 400;
    font-family: 'Montserrat';
}

.footer_sec button.btn.btn-primary {
    background-color: #fff;
    border: none;
    border-radius: 25px;
    font-size: 14px;
    padding: 8px 24px;
    font-family: 'Montserrat';
    font-weight: 700;
    margin-bottom: 20px;
    color: #043E6E;
    margin-top: 20px;
    text-transform: uppercase;
}

.footer_sec ul {
    list-style: none;
    padding: 0px;
}

.footer_sec ul li {
    margin: 10px 0px;
}

.footer_sec ul li a {
    color: #fff;
    font-size: 14px;
    font-weight: 400;
    font-family: 'Montserrat';
}

.footer_sec ul li a:hover {
    text-decoration: none;
}

.all_right_foot {
    background-color: #04365F;
    padding: 5px 0px;
}

.all_right_foot h6 {
    font-weight: 400;
    font-family: 'Montserrat';
    font-size: 14px;
    color: #fff;
    margin-top: 10px;
}

.row.foot_pos {
    position: relative;
}

.foot_icns {
    position: absolute;
    right: 0px;
}

.foot_icns span i.fa {
    margin: 0px 5px;
    color: #fff;
    margin-top: 10px;
}

.contc_us ul li i.fa {
    color: #fff;
    width: 20px;
}

.contc_us ul li i.fa-mobile {
    color: #fff;
    font-size: 24px;
}

button.btn.btnbacktotop {
    position: fixed;
    right: 100px;
    z-index: 111111;
    bottom: 100px;
    background-color: #4BD8F9;
    border-color: #4BD8F9;
    color: #fff;
}


/**Footer Css Ends**/


/**Get quote Section Css Starts**/

.adres_sec {
    background-color: #003974;
    box-shadow: 0px 0px 14px #00000024;
    border-radius: 15px;
    padding: 30px 20px;
}

.adres_sec h4 {
    color: #fff;
    font-size: 24px;
    text-align: left;
    font-weight: 500;
    margin-bottom: 15px;
}

.adres_sec p {
    color: #fff;
    font-size: 16px;
    text-align: left;
    line-height: 30px;
}

.adres_sec h5 {
    text-align: center;
    color: #fff;
    font-size: 28px;
    font-weight: 600;
    text-transform: uppercase;
    font-family: 'Montserrat';
}

.contact-form input::placeholder {
    color: #383838;
    font-size: 14px;
}

.contact-form textarea::placeholder {
    color: #383838;
    font-size: 14px;
}

button.btn_slect.dropdown-toggle {
    width: 100%;
    text-align: left;
    background-color: transparent;
    border: none;
    border-bottom: 1px solid #DDE0E7;
    padding-left: 0px;
}

.contact-form select#country {
    padding-left: 0px;
    margin-top: 3px;
}

.btn_slect.dropdown-toggle::after {
    display: inline-block;
    margin-left: 0;
    vertical-align: .255em;
    content: "";
    border-top: .3em solid;
    border-right: .3em solid transparent;
    border-bottom: 0;
    border-left: .3em solid transparent;
    position: absolute;
    right: 0;
    top: 8px;
}

button.btn_slect.dropdown-toggle:focus {
    outline: none;
}

.custom-control-input.platformValue {
    z-index: 111;
}

.contact-form input {
    padding-left: 0px;
    border: none !important;
    border-bottom: 1px solid #ADADAD !important;
    border-radius: 0px !important;
}

.contact-form select {
    border: none !important;
    border-bottom: 1px solid #ADADAD !important;
    border-radius: 0px !important;
}

.contact-form textarea {
    padding-left: 0px;
    border: none !important;
    border-bottom: 1px solid #ADADAD !important;
    border-radius: 0px !important;
}

span.dropdown-btn {
    border: none !important;
    border-bottom: 1px solid #DDE0E7 !important;
    border-radius: 0px !important;
}

#project span.dropdown-btn span {
    color: #383838;
    font-family: 'Montserrat';
    font-weight: 500;
    font-size: 18px;
    padding: 4px 5px;
}

.multiselect-dropdown .dropdown-btn {
    padding-left: 0px !important;
}

.form-sec h5 {
    color: #00B8E8;
    font-size: 24px;
    font-weight: 600;
}

.form-sec p {
    letter-spacing: 0.32px;
    color: #6E6E6E;
    font-size: 16px;
    margin-top: 20px;
    margin-bottom: 0px;
}

.contact_section {
    margin-top: 100px;
}

.contact-form.md-form label {
    color: #383838;
    font-weight: 500;
    font-size: 14px;
    padding-left: 0px;
    font-family: 'Montserrat';
}

.contact-form.md-form select {
    color: #383838;
    font-weight: 500;
    font-size: 14px;
    font-family: 'Montserrat';
    padding-left: 5px;
}

.contact-form.md-form label.active {
    color: #003974 !important;
    font-size: 14px !important;
    margin-top: -8px;
}

.contact-form.md-form {
    margin-top: 0px;
}

.captcha {
    margin-top: 5px;
    margin-bottom: 49px;
}

button.btn.btn-primary.btn-block {
    background-color: #1EB8E8;
    border-color: #1EB8E8;
    font-family: 'Montserrat';
    font-weight: 600;
    font-size: 24px;
    border-radius: 10px;
}

.container-fluid.map_section {
    padding: 0px;
    overflow: hidden;
    position: relative;
}

.map_text {
    position: absolute;
    top: 0px;
    bottom: 0px;
    left: 0px;
    right: 0px;
    background-color: #00000094;
    padding-left: 140px;
    padding-right: 140px;
}

.map_text h2 {
    color: #fff;
    font-size: 40px;
    text-transform: uppercase;
    font-weight: 600 !important;
    font-family: 'Montserrat';
}

.mapadrs_txt a h6 {
    color: #fff;
    font-size: 24px;
    font-weight: 500;
    font-family: 'Montserrat';
}

.mapadrs_txt p {
    color: #fff;
    font-size: 16px;
    font-weight: 500;
    font-family: 'Montserrat';
    margin-top: 10px;
    line-height: 28px;
}

.mapadrs_txt {
    margin-top: 50px;
}

.mapadrs_txt a {
    cursor: pointer;
}

.mapadrs_txt a {
    text-decoration: underline !important;
    color: #fff !important;
}

.multiselect-dropdown .dropdown-down {
    border: 0 !important;
}

.multiselect-dropdown .dropdown-up {
    border: none !important;
}

span.fa.fa-angle-down {
    font-size: 24px;
    position: absolute;
    right: 5px;
    top: 8px;
    color: #777777;
}

.select_platform {
    position: relative;
    padding-top: 4px;
}

.select_country select.form-control {
    appearance: none;
}

.form_select select.form-control {
    appearance: none;
}

.form_select.input-group-field:after {
    font-family: FontAwesome;
    content: '\f0d7';
    position: absolute;
    top: 8px;
    right: 32px;
    color: #434B67;
    pointer-events: none;
}

.select_budget i.fa.fa-angle-down {
    position: absolute;
    right: 10px;
    top: 0px;
    font-size: 32px;
}

.select_country i.fa.fa-angle-down {
    position: absolute;
    right: 5px;
    top: 8px;
    font-size: 24px;
    color: #777777;
}

hr.slct_hr {
    border-bottom: 1px solid #ADADAD !important;
    margin-bottom: 0px;
    border-top: none;
}

@media (min-width: 1481px) and (max-width: 1601px) {
    canvas#canvas2 {
        width: 200px;
        height: 200px;
    }
    canvas#canvas {
        width: 200px;
        height: 200px;
    }
}

@media (min-width: 1366px) and (max-width: 1480px) {
    button.btn.btn-primary.btn-block {
        border-radius: 10px;
        margin-top: 15px;
    }
    canvas#canvas {
        width: 180px;
        height: 180px;
    }
    .adres_sec p {
        color: #fff;
        font-size: 15px;
        text-align: left;
        line-height: 24px;
    }
    canvas#canvas2 {
        width: 180px;
        height: 180px;
    }
}

@media (min-width: 1200px) and (max-width: 1366px) {
    button.btn.btn-primary.btn-block {
        border-radius: 10px;
        margin-top: 25px !important;
    }
    canvas#canvas {
        width: 150px;
        height: 150px;
    }
    .adres_sec p {
        color: #fff;
        font-size: 14px;
        text-align: left;
        line-height: 24px;
    }
    canvas#canvas2 {
        width: 150px;
        height: 150px;
    }
    .form-row.select_plt .check_icon {
        width: 40px !important;
        height: 40px !important;
        border-radius: 50%;
    }
    .form-row.select_plt .custom-control-label::before {
        width: 12px !important;
        height: 12px !important;
    }
    .form-row.select_plt .custom-control-label::after {
        width: 12px !important;
        height: 12px !important;
    }
    .form-row.select_plt .check_icon.icon_clr1.d-flex.align-items-center span img {
        width: 15px;
    }
    .form-row.select_plt label.custom-control-label {
        padding-left: 20px !important;
        font-size: 13px !important;
        padding-top: 10px !important;
    }
    .col-lg-3.col-md-3.form-check-inline.contact_check {
        margin-top: 10px !important;
        margin-bottom: 20px !important;
    }
}

@media (min-width: 992px) and (max-width: 1199.98px) {
    button.btn.btn-primary.btn-block {
        border-radius: 10px;
        margin-top: 20px;
    }
    canvas#canvas {
        width: 130px;
        height: 130px;
    }
    .adres_sec p {
        color: #fff;
        font-size: 14px;
        text-align: left;
        line-height: 24px;
    }
    canvas#canvas2 {
        width: 130px;
        height: 130px;
    }
    .adres_sec h4 {
        margin-bottom: 5px;
        font-size: 20px;
    }
    .adres_sec h5 {
        font-size: 24px;
    }
    .navbar ul li a.active {
        font-size: 10px;
    }
    .form-row.select_plt .check_icon {
        width: 33px !important;
        height: 33px !important;
        border-radius: 50%;
    }
    .form-row.select_plt .custom-control-label::before {
        width: 11px !important;
        height: 11px !important;
    }
    .form-row.select_plt .custom-control-label::after {
        width: 11px !important;
        height: 11px !important;
    }
    .form-row.select_plt .check_icon.icon_clr1.d-flex.align-items-center span img {
        width: 12px;
    }
    .form-row.select_plt label.custom-control-label {
        padding-left: 14px !important;
        font-size: 10px !important;
        padding-top: 8px !important;
    }
    .col-lg-3.col-md-3.form-check-inline.contact_check {
        margin-top: 10px !important;
        margin-bottom: 20px !important;
    }
}

@media (min-width: 768px) and (max-width: 991.98px) {
    canvas#canvas {
        margin: auto;
        display: table;
    }
    canvas#canvas2 {
        margin: auto;
        display: table;
    }
    .adres_sec h4 {
        text-align: center;
    }
    .adres_sec p {
        text-align: center;
    }
    .adres_sec h5 {
        font-size: 24px;
        margin-top: 20px;
        margin-bottom: 30px;
    }
    .form-sec {
        margin-top: 50px;
    }
    .col-lg-6.pl-5.form-col {
        padding-left: 0px !important;
    }
    .col-lg-7.pl-5.form-col.d-flex.align-items-center {
        padding-left: 15px !important;
    }
}

@media (max-width: 767.98px) {
    .contact-form input {
        padding-right: 20px !important;
    }
    canvas#canvas {
        margin: auto;
        display: table;
    }
    canvas#canvas2 {
        margin: auto;
        display: table;
    }
    .adres_sec h4 {
        text-align: center;
    }
    .adres_sec p {
        text-align: center;
    }
    .adres_sec h5 {
        font-size: 24px;
        margin-top: 20px;
        margin-bottom: 30px;
    }
    .form-sec {
        margin-top: 50px;
    }
    .col-lg-6.pl-5.form-col {
        padding-left: 0px !important;
    }
    .contact-form.md-form label {
        font-size: 14px;
    }
    .contact-form.md-form select {
        font-size: 14px;
    }
    #project span.dropdown-btn span {
        font-size: 14px;
    }
    .col-lg-7.pl-5.form-col.d-flex.align-items-center {
        padding-left: 5px !important;
    }
    .map_text h2 {
        line-height: 24px;
        font-size: 16px;
    }
    .map_text {
        padding-left: 45px;
        padding-right: 30px;
    }
}


/**Get quote Section Css Starts**/


/**Home Page Css Starts**/

.banr_ctxt button.btn.btn-primary {
    background-color: #fff;
    border-color: #fff;
    border: 1px solid #fff;
    border-radius: 40px;
    padding: 10px 42px;
    font-size: 14px;
    font-family: 'Montserrat';
    margin-top: 40px;
    color: #043E6E;
    font-weight: 600;
}

.banr_ctxt button.btn.btn-primary:active {
    background-color: #fff;
    border-color: #fff;
    border: 1px solid #fff;
}

.slider_sec .carousel-indicators li {
    background-color: #ffffff94;
    position: relative;
    width: 20px !important;
    height: 20px !important;
    border-radius: 50% !important;
    opacity: 1;
    right: 58%;
}

.slider_sec .carousel-indicators li .active {
    background-color: #1EB8E2 !important;
}

.slider_sec #banr_slider .carousel-inner {
    position: unset;
}

.carousel-indicators .active {
    opacity: 1 !important;
    background-color: #1EB8E2 !important;
}

.slider_sec a.carousel-control-prev {
    display: none;
}

.slider_sec a.carousel-control-next {
    display: none;
}

.picsum-img-wrapper img {
    width: 100%;
}

.typeapp_box .typeapp_img {
    overflow: hidden;
}

#app_carousel:focus {
    outline: none;
}

#blogslide:focus {
    outline: none;
}

img.hm_pic1_web {
    width: 1370px;
    position: relative;
    top: 70px;
    left: -70px;
    z-index: 9999;
}

.apps_slider_sec a.carousel-control-next span {
    background-color: #043E6E;
    position: absolute;
    right: 0px;
    width: 50px;
    height: 50px;
    border-radius: 50%;
}

.apps_slider_sec a.carousel-control-prev span {
    background-color: #F2F3F8;
    position: absolute;
    left: 0px;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    border: 2px solid #043E6F;
}

.apps_slider_sec .carousel .carousel-control-prev-icon {
    background-image: none !important;
}

.apps_slider_sec .carousel .carousel-control-next-icon {
    background-image: none !important;
}

.apps_slider_sec a.carousel-control-prev span:after {
    font-family: "FontAwesome";
    content: "\f060";
    font-weight: 300 !important;
    font-size: 22px;
    color: #043E6E;
    position: absolute;
    top: 6px;
    left: 0px;
    right: 0px;
    padding-right: 2px;
}

.apps_slider_sec a.carousel-control-next span:after {
    font-family: "FontAwesome";
    content: "\f061";
    font-weight: 300 !important;
    font-size: 22px;
    color: #fff;
    position: absolute;
    top: 8px;
    left: 0px;
    right: 0px;
    padding-left: 2px;
}

.apps_slider_sec a.carousel-control-prev {
    position: absolute;
    left: -100px;
    opacity: 1;
    z-index: 1;
    width: 100px;
}

.apps_slider_sec a.carousel-control-prev {
    position: absolute;
    left: -100px;
    opacity: 1;
    z-index: 1;
    width: 100px;
    height: 60px;
    top: 50%;
}

.apps_slider_sec a.carousel-control-next {
    position: absolute;
    right: -100px;
    opacity: 1;
    width: 100px;
    height: 60px;
    top: 50%;
}

.apps_slider_sec ol.carousel-indicators {
    display: none;
}

.container-fluid.slider_sec {
    padding: 0px;
    margin-top: 144px;
}

.slider_sec .carousel-caption {
    position: absolute;
    right: 0%;
    top: 0%;
    left: 0%;
    z-index: 10;
    padding-top: 20px;
    padding-bottom: 20px;
    color: #fff;
    text-align: left;
}

.banr_ctxt {
    margin-top: 30%;
}

.slid_bnr_sec {
    padding-left: 8%;
    padding-right: 8%;
}

.slider_sec .carousel-caption h5 {
    font-size: 28px;
    text-transform: uppercase;
    line-height: 42px;
    font-family: 'Montserrat';
    font-weight: normal;
    padding-left: 15px;
    padding-right: 15px;
}

.slider_sec .carousel-caption h2 {
    font-size: 45px;
    text-transform: uppercase;
    font-weight: 800 !important;
    font-family: 'Montserrat';
    background-image: linear-gradient(to right, rgba(255, 255, 255, 0.23), rgba(255, 255, 255, 0));
    padding: 5px 15px;
}

.slider_sec .carousel-caption p {
    font-size: 18px;
    line-height: 27px;
    font-weight: 600;
    font-family: 'Montserrat';
    padding-left: 15px;
    margin-top: 15px;
}

#space_sec {
    padding-right: 8%;
    padding-left: 8%;
}

.slide_ryt {
    margin-top: -30px;
    position: relative;
}

.hm_apc2 {
    position: absolute;
    top: 40px;
    left: 30px;
    width: 390px;
}

.hm_apc1 {
    position: absolute;
    right: 0px;
    top: 60px;
    z-index: 1;
    width: 390px;
}

.slide_ryt .hm_appbg {
    margin-top: 50px;
}

.what_partnershp img {
    max-width: 75%;
    padding-top: 20px;
}

.whatdo_sec h2 {
    color: #0D6BA0;
    font-size: 32px;
    text-align: left;
    text-transform: uppercase;
    letter-spacing: 0.42px;
    font-family: 'Montserrat';
}

.whatwe_do p {
    color: #666666;
    font-size: 18px;
    font-style: normal;
    line-height: 30px;
    font-family: 'Montserrat';
    font-weight: 300;
}

.what_partnershp hr {
    background-color: #116DA1;
    height: 2px;
    width: 100px;
    margin-left: 0px;
    margin-top: 20px;
}

.whatwe_do hr {
    background-color: #116DA1;
    height: 2px;
    width: 100px;
    margin-left: 0px;
    margin-top: 20px;
}

.container-fluid.whatdo_sec {
    padding-right: 8%;
    padding-left: 0px;
}

.whatwe_do {
    margin-top: 80px;
}

.partnership_col {
    align-items: center;
    display: flex;
}

.partnership_col .agora_pimg {
    width: 320px;
}

.container-fluid.partnershp_sec {
    background-color: #f2f3f8;
    padding-top: 80px;
}

.partnershp_sec h2 {
    text-align: center;
    color: #0D6BA0;
    font-size: 32px;
    font-family: 'Montserrat';
    text-transform: uppercase;
    letter-spacing: 0.42px;
}

.partnershp_sec hr {
    background-color: #116DA1;
    height: 2px;
    width: 100px;
    margin-top: 20px;
}

.partner_content {
    background: #fff;
    box-shadow: 0px -42px 22px #00000005;
    border-radius: 30px 30px 0px 0px;
    padding: 30px 40px;
}

.partner_content h4 {
    letter-spacing: 0.3px;
    color: #1D1D1D;
    text-transform: uppercase;
    text-align: center;
    font-weight: 600 !important;
    font-size: 20px;
    margin-bottom: 20px;
}

.partner_content p {
    font-size: 16px;
    line-height: 30px;
    text-align: center;
    letter-spacing: 0.18px;
    color: #666666;
}

.specl_sec {
    background-color: #fff;
    padding-bottom: 100px;
    padding-top: 80px;
    overflow: hidden;
    margin-top: -6px;
}

.serve_icn img {
    margin: auto;
    display: table;
    margin-top: 44px;
}

.serve_icn {
    position: relative;
    background-color: #f2f3f8;
    width: 170px;
    height: 170px;
    border-radius: 50%;
    margin: auto;
    top: 85px;
    border: 7px solid #fff;
    margin-top: -30px;
}

.serve_box {
    background-color: #fff;
    padding: 20px 30px;
    border: 1px solid #D6D6D6;
    border-radius: 7px;
    min-height: 340px;
    max-height: 360px;
}

.row.serve_row {
    margin-top: -30px;
}

.serve_box.text-center:hover {
    box-shadow: 0 0px 30px 0 rgba(18, 18, 18, 0), 0 0px 20px 0 rgba(0, 0, 0, 0.11);
    cursor: pointer;
}

.phn_apppic img {
    margin-left: 50px;
    margin-top: -25px;
}

.phn_imgblock {
    display: none;
}

img.circleapp {
    position: absolute;
    width: 80%;
    top: 10px;
    left: -10px;
}

.row.apps_colimg {
    position: relative;
}

.side_techicn img {
    width: 65px;
}

span.java_img {
    position: absolute;
    top: 50px;
    right: 140px;
    text-transform: uppercase;
    font-size: 12px;
    font-family: 'Montserrat';
    font-weight: 600;
}

span.sft_img {
    position: absolute;
    top: 135px;
    right: 90px;
    text-transform: uppercase;
    font-size: 12px;
    font-family: 'Montserrat';
    font-weight: 600;
}

span#cbrgimg2 {
    top: 137px;
    right: 130px;
}

span#cbrgimg3 {
    top: 286px;
    right: 72px !important;
}

span#cbrgimg4 {
    top: 438px;
    right: 125px;
}

span#cbrgimg5 {
    top: 550px;
    right: 225px;
}

span#safe_img2 {
    right: 135px;
    top: 150px;
}

span#safe_img1 {
    right: 225px;
    top: 30px;
}

span#compete_img1 {
    top: 20px;
    right: 247px;
}

span#compete_img2 {
    top: 140px;
    right: 128px;
}

span#compete_img3 {
    top: 293px;
    right: 42px;
}

span#compete_img4 {
    top: 449px;
    right: 122px;
}

span#compete_img5 {
    top: 565px;
    right: 255px;
}

span.kotlin_ap {
    right: 60px !important;
}

span.exp_ap {
    right: 45px !important;
}

span.cde_img {
    position: absolute;
    top: 232px;
    right: 15px;
    text-transform: uppercase;
    font-size: 12px;
    font-family: 'Montserrat';
    font-weight: 600;
}

span.mys_img {
    position: absolute;
    top: 328px;
    right: 85px;
    text-transform: uppercase;
    font-size: 12px;
    font-family: 'Montserrat';
    font-weight: 600;
}

span.php_img {
    position: absolute;
    top: 415px;
    right: 140px;
    text-transform: uppercase;
    font-size: 12px;
    font-family: 'Montserrat';
    font-weight: 600;
}

#srv_pa2 {
    display: none;
}

.serve_icn:hover>#srv_pa2 {
    display: block;
}

.serve_icn:hover>#srv_pa1 {
    display: none;
}

.serve_icn:hover {
    background-color: #053d6e;
    cursor: pointer;
    -webkit-transition: background-color 0.3s ease-out;
    -moz-transition: background-color 0.3s ease-out;
    -o-transition: background-color 0.3s ease-out;
    transition: background-color 0.3s ease-out;
}

.serve_box h4 {
    font-size: 18px;
    color: #1D1D1D;
    line-height: 27px;
    font-family: 'Montserrat';
    text-transform: uppercase;
    font-weight: 600;
    margin-top: 80px;
}

.serve_box p {
    color: #666666;
    font-size: 16px;
    letter-spacing: 0.18px;
    opacity: 1;
    font-family: 'Montserrat';
    font-weight: 300;
    line-height: 30px;
}

.serve_box button.btn.btn-primary {
    background-color: transparent;
    border: 2px solid #043E6E;
    border-radius: 25px;
    font-size: 14px;
    padding: 8px 24px;
    font-family: 'Montserrat';
    font-weight: 600;
    margin-bottom: 20px;
    color: #043E6E;
}

.serve_box button.btn.btn-primary:hover {
    color: #fff;
    background-color: #043E6E;
    border: 2px solid #043E6E;
}

.specl_sec h2 {
    text-align: center;
    color: #0D6BA0;
    font-size: 32px;
    font-family: 'Montserrat';
    text-transform: uppercase;
    letter-spacing: 0.42px;
}

.specl_sec hr {
    background-color: #116DA1;
    height: 2px;
    width: 100px;
    margin-top: 20px;
}

.appstype_sec {padding-top: 80px;padding-bottom: 120px;background-color: #043E6E;}
.appstype_sec h2 {color: #fff;text-align: center;font-size: 32px;font-family: 'Montserrat';text-transform: uppercase;letter-spacing: 0.42px;}
.appstype_sec hr {background-color: #fff;height: 2px;width: 100px;margin-top: 20px;}
.typeapp_box {border: 2px solid #fff;border-radius: 5px;background-color: #fff;}
.typeapp_box img {border-radius: 5px;-webkit-transition: .3s ease-in-out;transition: .3s ease-in-out;}
.typeapp_box img:hover {-webkit-transform: scale(1.3);transform: scale(1.3);cursor: pointer;}
.typeap_pic {overflow: hidden;}
.typeapp-txt {padding: 20px 20px 40px 20px;}
.typeapp-txt h6 {font-size: 18px;color: #1D1D1D;font-family: 'Montserrat';font-weight: 600;float: left;}
.typeapp-txt span i.fa-angle-right {background-color: #043E6E;width: 25px;height: 25px;border-radius: 50%;color: #fff;font-size: 21px;
display: flex;float: right;padding-left: 10px;padding-bottom: 0px;padding-top: 1px;}
#appstype_sliderdesk ol.carousel-indicators {bottom: -80px;}
#appstype_sliderdesk .carousel-indicators li {width: 15px;height: 15px;background-color: #fff;position: relative;border-radius: 50%;opacity: 1;margin: 0px 5px;}
#appstype_slider a.carousel-control-prev {width: 32px;height: 32px;background-color: #fff;border-radius: 50%;position: absolute;z-index: 1;top: 318px;left: 37%;}
#appstype_slider a.carousel-control-next {width: 32px;height: 32px;background-color: #fff;border-radius: 50%;position: absolute;z-index: 1;top: 318px;right: 37%;}
#appstype_slider .carousel-control-next-icon,
#appstype_slider .carousel-control-prev-icon {width: auto;height: auto;background-image: none !important;}
#appstype_slider .carousel-control-next-icon i.fa,
#appstype_slider .carousel-control-prev-icon i.fa {color: #043E6E;font-size: 24px;}
@media (max-width:767px) {
    .typeapp_box {
        margin-bottom: 70px;
    }
}
@media (max-width:360px) {
    #appstype_slider a.carousel-control-prev {
        top: 285px;
        left: 38%;
    }
    #appstype_slider a.carousel-control-next {
        top: 285px;
        right: 36%;
    }
}


.choose_sec {margin: 80px 0px;}
.choose_sec h2 {text-align: center;color: #0D6BA0;font-size: 32px;font-family: 'Montserrat';text-transform: uppercase;letter-spacing: 0.42px;}
.choose_sec hr {background-color: #116DA1;height: 2px;width: 100px;margin-top: 20px;}
.choose_icn img {margin: auto;display: table;margin-top: 45px;}
.choose_box h3 {font-size: 20px;margin-top: 30px;margin-bottom: 10px;color: #1D1D1D;letter-spacing: 0.3px;text-transform: uppercase;font-family: 'Montserrat';font-weight: 600;}
.choose_box p {color: #666666;font-size: 16px;letter-spacing: 0.18px;font-family: 'Montserrat';font-weight: 300;line-height: 30px;}
.choose_box button.btn.btn-primary {background: transparent;color: #043E6E;border: 2px solid #043E6E;border-radius: 25px;font-size: 16px;
padding: 8px 27px;font-weight: 600;font-family: 'Montserrat';margin-top: 10px;}
.choose_box button.btn.btn-primary:hover {background-color: #043E6E;color: #fff;}
.choose_dotted img {position: absolute;top: 25%;right: -23%;width: calc(100% - 240px);}
.choose_dotted2 img {position: absolute;top: 25%;right: -23%;width: calc(100% - 240px);}
.choose_box {position: relative;}
.choose_icn img {width: 50%;}
.choose_btnlink {margin: auto;display: table;}
.choose_btnlink button.btn.btn-primary {background-color: #043E6E;border: none;border-radius: 25px;font-size: 14px;text-transform: uppercase;
font-weight: 600;padding: 10px 42px;font-family: 'Montserrat';color: #fff;}
.choose_btnlink::before {background-color: #BEBEBE;content: "";display: inline-block;height: 1px;position: relative;vertical-align: middle;width: 700px;margin-right: 10px;}
.choose_btnlink::after {background-color: #BEBEBE;content: "";display: inline-block;height: 1px;position: relative;vertical-align: middle;width: 700px;margin-left: 10px;}


.apps_slider_sec {background-color: #F2F3F8;padding-bottom: 80px;padding-top: 80px;}
.mapps_sec h2 {text-align: center;color: #0D6BA0;font-size: 32px;font-family: 'Montserrat';text-transform: uppercase;letter-spacing: 0.42px;}
.mapps_sec hr {background-color: #116DA1;height: 2px;width: 100px;margin-top: 20px;}
.apps_slide_txt h4 {font-size: 28px;font-family: 'Montserrat';letter-spacing: 0.42px;color: #1D1D1D;margin-bottom: 20px;}
.apps_slide_txt {padding-right: 20px;}
.apps_slide_txt p {font-size: 16px;font-family: 'Montserrat';letter-spacing: 0.42px;color: #666666;line-height: 30px;}
.apps_slide_txt ul li {list-style: none;font-size: 16px;line-height: 36px;font-family: 'Montserrat';}
.apps_slide_txt ul li {list-style: none;font-size: 16px;line-height: 36px;font-family: 'Montserrat';}
.apps_slide_txt ul {padding-left: 0px;}
.apps_slide_txt h5 {font-size: 20px;line-height: 30px;font-family: 'Montserrat';}
.apps_slide_txt ul li i.fa.fa-chevron-right {font-weight: 300;font-size: 12px;padding-right: 5px;color: #666;}
.apps_iconimg {border-top: 1px solid #BEBEBE;border-bottom: 1px solid #BEBEBE;padding-top: 18px;padding-bottom: 36px;width: 80%;}
.apps_slide_txt button.btn.btn-primary {background: transparent;color: #043E6E;border: 2px solid #043E6E;border-radius: 25px;font-size: 16px;padding: 8px 27px;
font-weight: 600;font-family: 'Montserrat';margin-top: 10px;margin-bottom: 20px;}
.apps_slide_txt button.btn.btn-primary:hover {background-color: #043E6E;color: #fff;z-index: 99999;}
#app_carousel ol li {background-color: #fff;position: relative;bottom: -80px;width: 15px;height: 15px;border-radius: 100%;opacity: 1;}
#app_carousel .carousel-indicators .active {opacity: 1;background-color: #1EB8E2;}
#app_carousel {padding: 0px 15px;}

.cleintsec {margin-top: 80px;margin-bottom: 120px;}
.cleintsec h2 {text-align: center;color: #0D6BA0;font-size: 32px;font-family: 'Montserrat';text-transform: uppercase;letter-spacing: 0.42px;}
.cleintsec .hr_cleint {background-color: #116DA1;height: 2px;width: 100px;margin-top: 20px;}
#cleint-rl::after {height: 120px;width: 1px;background-color: #BEBEBE;position: absolute;content: "";top: 0px;right: 0px;}
.clients_img.partner_ln::before {height: 1px;width: 160px;background-color: #BEBEBE;position: absolute;content: "";bottom: -27px;left: 30px;}
.clients_img img {margin: auto;display: table;}
.cleintsec h4 {text-align: center;font-size: 16px;letter-spacing: 0.32px;color: #1D1D1D;text-transform: uppercase;font-weight: 600 !important;padding-top: 15px;margin-bottom: 0px;}
.clt_sec {margin-bottom: 50px;}



.container-fluid.testmnl_sec {background: #053E6D;padding-top: 80px;padding-bottom: 80px;}
.testmnl_sec h2 {color: #fff;text-align: center;font-size: 32px;font-family: 'Montserrat';text-transform: uppercase;letter-spacing: 0.42px;}
.testmnl_sec hr {background-color: #fff;height: 2px;width: 100px;margin-top: 20px;}
section.testimonials {overflow: hidden;padding-top: 20px;}
.shadow-effect {text-align: center;background: #FFFFFF 0% 0% no-repeat padding-box;box-shadow: 0px 0px 50px #00000029;height: auto;padding: 40px 30px;}
.imgpic_center {justify-content: center;display: flex;}
.img_clnts {width: 100px;height: 100px;margin: auto;background: #fff;border-radius: 50%;position: absolute;top: 0px;overflow: hidden;border: 5px solid #fff;box-shadow: 0px 0px 50px #00000029;}
.shadow-effect h3 {font-size: 16px;color: #000000;font-weight: 600;padding-top: 25px;font-family: 'Montserrat';}
.shadow-effect h3 span {color: #666666;font-family: 'Montserrat';font-weight: 400;}
.shadow-effect h6 {font-size: 16px;color: #666666;font-weight: 400;font-family: 'Montserrat';padding-bottom: 8px;}
.shadow-effect p {line-height: 26px;font-size: 14px;color: #666666;font-weight: 300 !important;font-family: 'Montserrat';}
#testimonials-list .item {text-align: center;padding: 50px 20px;margin-bottom: 40px;opacity: .2;-webkit-transform: scale3d(0.8, 0.8, 1);transform: scale3d(0.8, 0.8, 1);transition: all 0.3s ease-in-out;}
#testimonials-list .owl-item.active.center .item {opacity: 1;-webkit-transform: scale3d(1.0, 1.0, 1);transform: scale3d(1.0, 1.0, 1);}
button.owl-dot:focus {outline: none !important;}
.owl-nav button.owl-next:focus {outline: none;}
.owl-nav button:focus {outline: none;}
#testimonials-list.owl-carousel .owl-dots .owl-dot.active span,
#testimonials-list.owl-carousel .owl-dots .owl-dot:hover span {background: #00B8E8;-webkit-transform: translate3d(0px, -50%, 0px) scale(0.7);transform: translate3d(0px, -50%, 0px) scale(0.7);}
#testimonials-list.owl-carousel .owl-dots {display: inline-block;width: 100%;text-align: center;}
#testimonials-list.owl-carousel .owl-dots .owl-dot {display: inline-block;}
#testimonials-list.owl-carousel .owl-dots .owl-dot span {background: #fff;display: inline-block;height: 25px;margin: 0 2px 5px;-webkit-transform: translate3d(0px, -50%, 0px) scale(0.3);transform: translate3d(0px, -50%, 0px) scale(0.3);
-webkit-transform-origin: 50% 50% 0;transform-origin: 50% 50% 0;transition: all 250ms ease-out 0s;width: 25px;border-radius: 50%;}


.get_sec {background-color: #F2F3F8;padding-top: 80px;padding-bottom: 80px;}
.get_sec h2 {text-align: center;color: #0D6BA0;font-size: 32px;font-family: 'Montserrat';text-transform: uppercase;letter-spacing: 0.42px;}
.get_sec hr {background-color: #116DA1;height: 2px;width: 100px;margin-top: 20px;}
.get_sec p {font-size: 16px;color: #666666;line-height: 24px;font-family: 'Montserrat';font-weight: 300;margin-bottom: 25px;}
.addrs h6 {font-size: 16px;line-height: 24px;font-weight: 500;font-family: 'Montserrat';color: #1D1D1D;}
.getquotes {background-color: #F2F3F8;padding: 20px;border-radius: 20px;}
.getquotes hr {height: 0px;background-color: #ADADAD;width: 100%;}
.getquotes h6 {color: #1D1D1D;font-size: 16px;line-height: 24px;font-family: 'Montserrat';font-weight: 600;text-transform: uppercase;}
.getquotes h6 p.platform_para {font-size: 14px;color: #666666;font-family: 'Montserrat';font-weight: 300;}
.getquotes label {font-size: 14px;color: #666666;font-family: 'Montserrat';font-weight: 300;}
.row.fss {padding-left: 20px;margin-bottom: 40px;}
.addrs-rt {margin-top: 5px;}
.addrs-rt h5 {letter-spacing: 0.34px;color: #333333;text-transform: uppercase;font-weight: 600;font-size: 16px;}
.addrs-rt h5 span {font-size: 13px;color: #707070;font-weight: 300;text-transform: capitalize;}
img.adrs_iconimg {float: left;padding-top: 3px;}
.addrs-rt span p.adrs_line {padding-left: 30px;letter-spacing: 0.32px;color: #666666;font-size: 16px;margin-bottom: 8px;}
.addrs-rt p span {letter-spacing: 0.32px;color: #666666;font-size: 16px;font-weight: normal;padding-left: 12px;}
hr.adrs_brdr {background-color: #CECECE;width: 100%;height: 0px;margin-top: 12px;}
.addrs-rt p {margin-bottom: 8px;}
.addrs-rt a span.see_map_span {letter-spacing: 0.32px;color: #043E6E;text-transform: uppercase;font-size: 16px;font-weight: 600;margin-left: 10px;}
.addrs-rt span.see_map_span {border-bottom: 2px solid #043E6E;}
hr.row_addrs_hr {width: 100%;height: 0px;background-color: #CECECE;margin-left: 25px;margin-top: 30px;}
/* .col-lg-7.coladrs {margin-left: -10px;} */
/* .offce_pic {padding-left: 10px;} */
.offce_pic img {
    border-radius: 20px;
    width: 100%;
}
img.adrs_flag_img {padding-right: 3px;}
.quote_btn button.btn.btn-primary {background: transparent;color: #043E6E;border: 2px solid #043E6E;border-radius: 25px;font-size: 16px;
padding: 8px 27px;font-weight: 600;font-family: 'Montserrat';margin-top: 10px;margin-bottom: 20px;margin-left: 15px;}
.quote_btn button.btn.btn-primary:hover {background-color: #043E6E;color: #fff;}
.banr-txt-border {border-left: 4px solid #fff;}

.check_icon {background-color: #F2F3F8;width: 60px;height: 60px;border-radius: 50%;position: absolute;left: 0px;top: 0px;}
.icon_clr1 {border: 2px solid #003B72;}
.check_icon span {margin: auto;}




form .getquotes input {border: none;border-bottom: 1px solid #ADADAD !important;border-top: 1px solid #ADADAD !important;border-right: 1px solid #ADADAD !important;
border-radius: 0px;background: #fff;border-top-right-radius: 20px !important;border-bottom-right-radius: 20px !important;}
select#job_type {border-bottom: 1px solid #ADADAD !important;border: none;border-radius: 0px;padding-left: 0px;}
.getquotes select#sel1 {border: none;border-bottom: 1px solid #ADADAD;border-top: 1px solid #ADADAD;border-right: 1px solid #ADADAD;
border-top-right-radius: 20px !important;border-bottom-right-radius: 20px !important;border-radius: 0px;background: transparent;font-size: 16px;
color: #666666;font-family: 'Montserrat';font-weight: 300;}
textarea#message {border: 1px solid #ced4da !important;padding-left: 40px !important;background-image: url('img/textarea_icon.png') !important;background-repeat: no-repeat !important;background-size: 13px !important;background-position: 14px 10px !important;}
.getquotes input:focus {border-color: #ADADAD;}
.getquotes input::placeholder {font-size: 14px;font-weight: 300;color: #666666;font-family: 'Montserrat';}
.getquotes textarea::placeholder {font-size: 14px;padding-left: 0px;padding-top: 0px;font-weight: 300;color: #666666;font-family: 'Montserrat';}
.form-control:focus {color: #495057;background-color: #fff;border-color: #ADADAD;outline: 0;box-shadow: none !important;}
.getquotes .form-control {padding: 5px 20px 5px 3px !important;}
.getquotes .custom-control-label::before {position: absolute;top: .25rem;left: -1.5rem;display: block;width: 1rem;height: 1rem;
pointer-events: none;content: "";background-color: #F2F3F8;border: #003B72 solid 2px !important;border-radius: 20px !important;}

.getquotes label.custom-control-label {padding-top: 20px;padding-left: 43px;}
.form-row.select_plt .custom-control-label::before {background-color: #F2F3F8;border: #003B72 solid 2px !important;border-radius: 20px !important;}
.form-row.select_plt .check_icon {background-color: #F2F3F8;width: 50px;height: 50px;border-radius: 50%;}
.form-row.select_plt label.custom-control-label {padding-left: 30px;font-size: 14px;padding-top: 12px;}
.form-row.select_plt h6 {font-size: 14px;color: #383838;font-weight: 500;padding-left: 0px;font-family: 'Montserrat';margin-bottom: 0px;margin-top: 8px;}
.getquotes .custom-checkbox .custom-control-label::before {border-radius: .15rem;}
.form-check-inline {margin-right: 0px !important;}
.addrs-rt span p.adrs_line>br {display: none;}
span#basic_addon_select {margin-top: 0px;height: 38px;}
.form-group.form_select {margin-top: 42px;}
.custom-checkbox .custom-control-input:checked~.custom-control-label::before {background-color: #003B72;}
img.icn_form {width: 15px;height: 15px;}

.getquotes span.input-group-text {display: -ms-flexbox;display: flex;-ms-flex-align: center;align-items: center;padding: 0px 7px 0px 15px;
margin-bottom: 0;font-size: 1rem;font-weight: 400;line-height: 1.5;color: #495057;text-align: center;white-space: nowrap;border: none !important;
border-bottom: 1px solid #ADADAD !important;border-top: 1px solid #ADADAD !important;border-left: 1px solid #ADADAD !important;border-top-left-radius: 20px !important;
border-bottom-left-radius: 20px !important;background-color: #fff !important;}
.getquotes select.form-control {border-left: none;height: 38px;border-bottom: 1px solid #ADADAD !important;border-top: 1px solid #ADADAD !important;
border-right: 1px solid #ADADAD !important;font-size: 14px;font-family: 'Montserrat';font-weight: 300;border-top-right-radius: 20px !important;
border-bottom-right-radius: 20px !important;background-color: #fff;appearance: none;webkit-appearance: none;}
select#country {-webkit-appearance: none;}
.getquotes .input-group-prepend {margin-right: 0px;height: 38px;}

.form-control.is-invalid,
.was-validated .form-control:invalid {background-image: none !important;}
.form-control.is-valid,
.was-validated .form-control:valid {background-image: none !important;}
.custom-control-input.is-valid~.custom-control-label,
.was-validated .custom-control-input:valid~.custom-control-label {color: #666666 !important;}
#CheckboxError {width: 100%;margin-top: .25rem;font-size: 80%;color: #dc3545;}
#alert_home {display: none;}
.fil_err {position: relative;top: -18px;color: #dc3545;font-size: 14px;}
#ExpError {position: relative;top: -18px;color: #dc3545;font-size: 14px;}

.form_select.input-group-field {position: relative;}
.select_err {position: absolute;bottom: 0px;left: 15px;}
#SelectErrorText {color: #dc3545;font-size: 14px;position: relative;top: 5px;}

/**Home Page Css Ends**/


/**Digital Page Css Starts**/
.dig_serve_sec {
    border: 1px solid #043E6E;
    background-color: #fff;
    height: 100%;
    border-bottom-left-radius: 30px;
    border-bottom-right-radius: 30px;
}

.row.dm_title {
    position: absolute;
    top: 30px;
    left: 60px;
}

.digital_spic {
    position: relative;
}

.digital_serv_sec h2 {
    color: #0D6BA0;
    font-size: 32px;
    font-family: 'Montserrat';
    text-transform: uppercase;
    letter-spacing: 0.42px;
    text-align: center;
}

.digital_serv_sec hr {
    background-color: #0D6BA0;
    height: 2px;
    width: 100px;
    margin-top: 20px;
    margin-bottom: 20px;
}

.dm_whatdo_sec {
    background-color: #f2f3f8;
    padding: 80px 0px;
    position: relative;
    padding-left: 8% !important;
}

.dm_whatdo_sec h2 {
    color: #0D6BA0;
    font-size: 28px;
    text-align: left;
    text-transform: uppercase;
    letter-spacing: 0.42px;
    font-family: 'Montserrat';
}

.dm_whatdo_sec hr {
    background-color: #116DA1;
    height: 2px;
    width: 100px;
    margin-top: 15px;
    margin-left: 0px;
}

.dm_what_sec {
    width: 110px;
    height: 110px;
    border: 2px dashed #003D70;
    border-radius: 50%;
}

.dmcol_txt h3 {
    font-size: 20px;
    color: #1D1D1D;
    line-height: 30px;
    font-family: 'Montserrat';
    text-transform: uppercase;
    font-weight: 600;
}

.dmcol_txt p {
    color: #666666;
    font-size: 16px;
    line-height: 30px;
    letter-spacing: 0.18px;
    opacity: 1;
    font-family: 'Montserrat';
    font-weight: 300;
}

.dm_what_sec img {
    margin: auto;
}

.container-fluid.strategy_sec {
    margin: 80px 0px;
}

.strategy_sec h2 {
    color: #0D6BA0;
    font-size: 32px;
    font-family: 'Montserrat';
    text-transform: uppercase;
    letter-spacing: 0.42px;
    text-align: center;
}

.strategy_sec hr {
    background-color: #0D6BA0;
    height: 2px;
    width: 100px;
    margin-top: 20px;
    margin-bottom: 20px;
}

.startgy_img_sec {
    margin: auto;
    width: 170px;
    height: 170px;
    border: 2px dashed #003D70;
    border-radius: 50%;
}

.startgy_img_sec img {
    margin: auto;
    display: table;
}

.startgy_col h3 {
    letter-spacing: 0.3px;
    color: #1E1E1E;
    text-transform: uppercase;
    font-family: 'Montserrat';
    font-size: 20px;
    text-align: center;
    margin-top: 30px;
    margin-bottom: 20px;
    font-weight: 600;
}

img.img-fluid.dm_arrow1 {
    position: absolute;
    top: 40%;
    left: 19%;
    max-width: calc(300px - 100px);
}

img.img-fluid.dm_arrow3 {
    position: absolute;
    right: 19%;
    top: 40%;
    max-width: calc(300px - 100px);
}

.row.stratgy_row {
    position: relative;
}

img.img-fluid.dm_arrow2 {
    position: absolute;
    left: 44%;
    top: 38%;
    max-width: calc(300px - 100px);
}

.dm_title h3 {
    color: #fff;
    padding-left: 20px;
    letter-spacing: 0.2px;
    text-align: center;
    font-size: 20px;
    font-weight: 600;
    font-family: 'Montserrat';
    align-items: center;
    display: flex;
}

.dig_serve_sec p {
    color: #666666;
    font-family: 'Montserrat';
    font-size: 16px;
    letter-spacing: 0.16px;
    text-align: left;
    font-weight: 300;
    line-height: 30px;
    padding: 15px 30px;
}

.digital_serv_sec {
    margin: 80px 0px;
    overflow: hidden;
}

.digital_serv_sec p {
    color: #666666;
    font-family: 'Montserrat';
    font-size: 16px;
    font-weight: 500;
    line-height: 30px;
    padding: 10px 20px;
}

.dm_icon_sec img.digital_icn {
    padding-top: 30px;
    padding-left: 30px;
}

.dm_icon_sec h4 {
    padding-top: 30px;
    font-size: 20px;
    letter-spacing: 0.2px;
    color: #0D0D0D;
    font-family: 'Montserrat';
    font-weight: 600;
    text-transform: uppercase;
    text-align: left;
    padding-left: 15px;
    line-height: 30px;
}


/**Digital Page Css Ends**/


/** About Page Css Start**/
div#about_banr_slider {
    margin-bottom: 100px;
    overflow: unset;
}

.container-fluid.abtbnr_sec {
    padding: 0px;
    margin-top: 90px;
}

.abt_inner {
    position: relative;
    width: 100%;
    overflow: hidden;
}

.abt_item {
    position: relative;
    float: left;
    width: 100%;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    transition: -webkit-transform .6s ease-in-out;
    transition: transform .6s ease-in-out;
    transition: transform .6s ease-in-out, -webkit-transform .6s ease-in-out;
}

.abtbnr_sec .slid_bnr_sec {
    position: absolute;
    right: 0%;
    top: 0%;
    left: 0%;
    z-index: 10;
    padding-top: 20px;
    padding-bottom: 20px;
    color: #fff;
    text-align: left;
}

.abtbnr_sec .slid_bnr_sec h5 {
    font-size: 28px;
    text-transform: uppercase;
    line-height: 42px;
    font-family: 'Montserrat';
    font-weight: normal;
    padding-left: 15px;
    padding-right: 15px;
}

.abtbnr_sec .slid_bnr_sec h2 {
    font-size: 45px;
    text-transform: uppercase;
    font-weight: 800 !important;
    font-family: 'Montserrat';
    background-image: linear-gradient(to right, rgba(255, 255, 255, 0.23), rgba(255, 255, 255, 0));
    padding: 5px 15px;
}

.abtbanr_text {
    margin-top: 65px;
}

.abt_hd h2 {
    color: #0D6BA0;
    font-size: 32px;
    text-align: left;
    text-transform: uppercase;
    letter-spacing: 0.42px;
    font-family: 'Montserrat';
}

.events_slider2 {
    display: none;
}

.about_leftext h2 {
    color: #0D6BA0;
    font-size: 62px;
    text-align: left;
    text-transform: uppercase;
    letter-spacing: 0.42px;
    font-family: 'Montserrat';
    font-weight: 700 !important;
    padding-left: 30px;
}

.about_leftext {
    position: relative;
}

.about_leftext span:before {
    width: 8px;
    height: 50px;
    content: '';
    position: absolute;
    background-color: #0D6BA0;
    top: 136px;
}

.about_leftext hr {
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    margin-top: 30px;
    margin-left: 0px;
    width: 100%;
    margin-bottom: 30px;
}

.about_leftext h3 {
    font-size: 42px;
    line-height: 58px;
    color: #666;
    font-family: 'Montserrat';
    text-transform: uppercase;
}

.about_leftext p {
    letter-spacing: 0.18px;
    color: #666666;
    font-size: 16px;
    line-height: 30px;
    font-family: 'Montserrat';
}

.about_focus h5 {
    color: #1D1D1D;
    font-size: 18px;
    line-height: 30px;
    font-weight: 600;
    font-family: 'Montserrat';
}

.about_focus {
    margin-top: 20px;
}

.about_app_border {
    border: 2px dashed #003D70;
    width: 110px;
    height: 110px;
    border-radius: 50%;
}

.about_app_border img {
    margin: auto;
    display: table;
}

.about_app h6 {
    letter-spacing: 0.27px;
    color: #1D1D1D;
    font-size: 18px;
    margin-top: 20px;
    text-align: left;
    font-family: 'Montserrat';
    font-weight: 500;
}

.container-fluid.recent_event_sec {
    background-color: #F2F3F8;
    padding-top: 80px;
    padding-bottom: 30px;
    padding-left: 8%;
    padding-right: 0px;
    overflow: hidden;
}

.events_coleft p {
    letter-spacing: 0.18px;
    color: #666666;
    font-size: 18px;
    line-height: 30px;
    font-family: 'Montserrat';
}

.events_coleft button.btn.btn-primary {
    background-color: #043e6e;
    border-radius: 25px;
    border-color: #043e6e;
    font-size: 16px;
    text-transform: uppercase;
    padding: 8px 30px;
    font-family: 'Montserrat';
    font-weight: 600 !important;
}

.missn_img_sec img.mvisn_img {
    float: right;
    max-width: 100%;
    height: auto;
}

.events_slider .carousel .carousel-control-prev-icon {
    bottom: 0px;
    position: absolute;
    width: 40px;
    height: 40px;
    background-color: #003D70;
    border-radius: 50%;
    /* background-position: 0px 2px; */
    background-image: none;
}

.events_slider span.carousel-control-next-icon:after {
    font-family: "FontAwesome";
    font-weight: 900;
    content: "\f054";
    font-size: 20px;
    top: 5px;
    position: absolute;
    left: 15px;
}

.events_slider span.carousel-control-prev-icon:after {
    font-family: "FontAwesome";
    font-weight: 900;
    content: "\f053";
    font-size: 20px;
    top: 5px;
    position: absolute;
    left: 12px;
}

.events_slider .carousel .carousel-control-next-icon {
    bottom: 0px;
    position: absolute;
    width: 40px;
    height: 40px;
    background-color: #043E6E;
    border-radius: 50%;
    /* background-position: 2px 2px; */
    background-image: none;
}

.events_slider .carousel-control-prev,
.carousel-control-next {
    opacity: 1 !important;
}

#about_banr_slider .carousel-inner {
    position: relative;
    width: 100%;
    overflow: unset;
}

.events_slider a.carousel-control-prev {
    top: 90%;
    left: 40%;
    height: 100px;
}

.events_slider a.carousel-control-next {
    left: 48%;
    height: 100px;
    top: 90%;
    width: 100px;
}

.events_slider ol.carousel-indicators {
    display: none;
}

.abt_hd hr {
    background-color: #116DA1;
    height: 2px;
    width: 100px;
    margin-top: 15px;
    margin-left: 0px;
}

.abt_whatdo {
    margin: 80px 0px;
}

.abt_aps {
    background-color: #f2f3f8;
    padding: 80px 0px;
    position: relative;
}

.abtapp_rt hr {
    background-color: #116DA1;
    height: 2px;
    width: 100px;
    margin-top: 15px;
    margin-left: 0px;
}

.abtapp_rt h2 {
    color: #0D6BA0;
    font-size: 28px;
    text-align: left;
    text-transform: uppercase;
    letter-spacing: 0.42px;
    font-family: 'Montserrat';
}

.abtapp_rt img.abtapimg2 {
    display: none;
}

.container-fluid.abt_hd.abt_misn {
    padding-left: 8%;
    padding-right: 0px;
    overflow: hidden;
    margin-top: 0px;
}

.misn_sec h3 {
    text-transform: uppercase;
    font-size: 32px;
    color: #0D6BA0;
    font-family: 'Montserrat';
    letter-spacing: 0.42px;
}

.misn_sec p {
    font-size: 18px;
    color: #666666;
    letter-spacing: 0.2px;
    font-family: 'Montserrat';
    line-height: 32px;
    margin-top: 20px;
}

.misn_sec {
    background: #FFF;
    box-shadow: 0px 0px 42px #00000014;
    border: 1px solid #E8E8E8;
    border-radius: 36px;
    padding: 30px 50px;
}

.abt_apppic_sec {
    position: relative;
}

.misn_coleft {
    margin-top: 140px;
}

.event_img img {
    transition: .3s ease-in-out;
}

.event_img img:hover {
    transform: scale(1.1);
}

.event_img {
    overflow: hidden;
}

.appcol_txt h3 {
    font-size: 20px;
    color: #1D1D1D;
    line-height: 30px;
    font-family: 'Montserrat';
    text-transform: uppercase;
    font-weight: 600;
}

.appcol_txt p {
    color: #666666;
    font-size: 16px;
    line-height: 30px;
    letter-spacing: 0.18px;
    opacity: 1;
    font-family: 'Montserrat';
    font-weight: 300;
}

.abt_appicn_sec img {
    margin: auto;
}

.abt_appicn_sec {
    width: 110px;
    height: 110px;
    border: 2px dashed #003D70;
    border-radius: 50%;
}

.abtapp_rt {
    margin-left: 60px;
}

.abt_apppic_sec img.abtapimg {
    position: absolute;
    top: 0px;
}

.row.abtaps_row {
    position: relative;
}


/** About Page Css Ends**/


/** Portfolio Page Css Start**/

.port_miapp h2 {
    margin-bottom: 20px;
    font-size: 36px;
    line-height: 48px;
    color: #1D1D1D;
    letter-spacing: 0.63px;
    font-family: 'Montserrat';
}

.port_miapp p {
    font-size: 16px;
    color: #666666;
    line-height: 28px;
    letter-spacing: 0.18px;
    font-family: 'Montserrat';
}

.port_miapp h3 {
    margin-bottom: 20px;
    margin-top: 30px;
    text-transform: uppercase;
    font-size: 20px;
    line-height: 30px;
    color: #1D1D1D;
    letter-spacing: 0.63px;
    font-family: 'Montserrat';
    font-weight: 600;
}

h6.htech_stk {
    font-weight: 600;
    text-align: center;
    margin-bottom: 20px;
    margin-top: 15px;
    letter-spacing: 0.27px;
    color: #1E1E1E;
    text-transform: uppercase;
    font-size: 14px;
    font-family: 'Montserrat';
}

.webport_btn button.btn.btn-primary {
    margin-left: 20px;
    background: transparent;
    color: #043E6E;
    border: 1px solid #043E6E;
    border-radius: 25px;
    font-size: 16px;
    padding: 10px 36px;
    font-weight: 700;
    font-family: 'Montserrat';
    margin-top: 10px;
    margin-bottom: 10px;
    text-transform: uppercase;
}

.port_miapp {
    padding: 80px 0px;
}

.sec_keenan p {
    margin-bottom: 5px;
}

div#portfolio_sec1_pl .port_miapp {
    padding-left: 12%;
    padding-right: 30px;
}

div#portfolio_sec2_pr .port_miapp {
    padding-right: 12%;
    padding-left: 30px;
}

.txtcol_lft {
    padding: 0px !important;
    display: flex;
    align-items: center;
}

.txtcol_rt {
    display: flex;
    align-items: center;
}

.img_colrt {
    padding: 0px !important;
}

.cyborg_imgsec {
    background-image: url('./img/cyborg_bgimg.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    height: 100%;
}

.port_miapp h2 img.port_logo {
    padding-right: 20px;
}

.technology_logo_img {
    padding: 20px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #EDEDED;
    height: 120px;
}

.technology_logo_img img {
    margin: auto;
    display: table;
}

.technology_logo_img .tech-imgsec {
    height: 60px;
}

.technology_logo_img h6 {
    text-align: center;
    padding-top: 5px;
    font-size: 14px;
    color: #1E1E1E;
    font-family: 'Montserrat';
}

.row.technlgy_row {
    padding-left: 15px;
}

.djsntna_imgsec {
    background-image: url('./img/djsantna_bgimg.jpg');
}

.port_imgauto {
    align-items: center;
    display: flex;
    background-repeat: no-repeat;
    background-size: cover;
    height: 100%;
    padding: 50px 0px;
}

.port_imgauto img {
    margin: auto;
    display: table;
}

.lum_imgsec {
    background-image: url('./img/lum_bgimg.jpg');
}

.tectical_imgsec {
    background-image: url('./img/tacticlnav_bgimg.jpg');
}

.spodid_imgsec {
    background-image: url('./img/spodid_bgimg.jpg');
}

.youchug_imgsec {
    background-image: url('./img/youchg_bgimg.jpg');
}

.safesms_imgsec {
    background-image: url('./img/safesms_bgimg.jpg');
}

.safesms_imgsec img {
    padding-bottom: 50px;
    padding-top: 50px;
}

.carnival_imgsec img {
    padding: 50px 0px;
}

.carnival_imgsec {
    background-image: url('./img/carnival_bgimg.png');
}

.keenan_imgsec {
    background-image: url('./img/keenan_bgimg.jpg');
}

.socoro_imgsec {
    background-image: url('./img/socoro_bgimg.jpg');
}

.evolution_imgsec {
    background-image: url('./img/evolution_bgimg.jpg');
}

.angler_imgsec {
    background-image: url('./img/angler_bgimg.jpg');
}

.bookyond_imgsec {
    background-image: url('./img/bookyond_bgimg.jpg');
}

.boomerng_imgsec {
    background-image: url('./img/boomerng_bgimg.jpg');
}

.hannah_imgsec {
    background-image: url('./img/hannah_bgimg.jpg');
}

.clips_imgsec {
    background-image: url('./img/clips_bgimg.jpg');
}

.laundris_imgsec {
    background-image: url('./img/laundris_bgimg.jpg');
}

.mialunapp_imgsec {
    background-image: url('./img/mialunapp_bgimg.jpg');
}

.cowspot_imgsec {
    background-image: url('./img/cowspot_bgimg.jpg');
}

.bigtimelct_imgsec {
    background-image: url('./img/bigtimelct_bgimg.png');
}

.recordart_imgsec {
    background-image: url('./img/recordart_bgimg.jpg');
}

.wigwag_imgsec img {
    padding-top: 40px;
}

.wigwag_imgsec {
    background-image: url('./img/wigwag_bgimg.jpg');
}

.sqmeals_imgsec img {
    padding-top: 60px;
    max-width: 70%;
}

.sqmeals_imgsec {
    background-image: url('./img/sqmeals_bgimg.jpg');
}

.hola_imgsec {
    background-image: url('./img/hola_bgimg.jpg');
}

.maple_imgsec {
    background-image: url('./img/maple_bgimg.jpg');
}

.avera_imgsec {
    background-image: url('./img/avera_bgimg.jpg');
}

.srvicevdo_imgsec {
    background-image: url('./img/srvicevdo_bgimg.jpg');
}

.ideal_imgsec img {
    padding-top: 60px;
    padding-bottom: 60px;
}

.ideal_imgsec {
    background-image: url('./img/ideal_bgimg.jpg');
}

.sweetish_imgsec {
    background-image: url('./img/sweetish_bgimg.jpg');
}

.lookhu_imgsec {
    background-image: url('./img/lookhu_bgimg.jpg');
}

.htts_imgsec {
    background-image: url('./img/htts_bgimg.jpg');
}

#portfolio_sec1_pl h5,
#portfolio_sec2_pr h5 {
    font-size: 20px;
    font-weight: 600;
    line-height: 30px;
    padding-top: 10px;
    text-transform: uppercase;
    background: #333;
    color: #fff;
    padding: 10px 30px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.portbnr_txt .slid_bnr_sec h5 {
    font-size: 28px;
    text-transform: uppercase;
    line-height: 42px;
    font-family: 'Montserrat';
    font-weight: normal;
    padding-left: 15px;
    padding-right: 15px;
}

.portbnr_txt .slid_bnr_sec h2 {
    font-size: 45px;
    text-transform: uppercase;
    font-weight: 800;
    font-family: 'Montserrat';
    background-image: linear-gradient(to right, rgba(255, 255, 255, 0.23), rgba(255, 255, 255, 0));
    padding: 5px 15px;
}

#porttabs_heading .nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
    color: #fff !important;
    background-color: #3EC5E9 !important;
    border-color: transparent !important;
    border-radius: 0px !important;
    border-top-left-radius: 10px !important;
    border-bottom-right-radius: 10px !important;
    font-weight: 600;
}

.nav-tabs {
    border-bottom: none !important;
}

.nav-tabs .nav-link {
    border: none !important;
}

.nav-tabs .nav-link:focus,
.nav-tabs .nav-link:hover {
    border-color: transparent !important;
}

.nav-tabs a.nav-item {
    color: #1D1D1D;
    font-size: 24px;
    font-family: 'Montserrat';
    font-weight: 500;
    margin: 0px 20px;
}

nav.portfolio_nav {
    margin: auto;
    display: flex;
    margin-bottom: 20px;
}

.colmrt {
    padding: 0px;
}

.all_pagination .pagination .page-item.active .page-link {
    background-color: #043E6E;
    border-radius: 50%;
    font-size: 16px;
    width: 34px;
    height: 34px;
    font-weight: 600;
    color: #fff;
}

.all_pagination .pagination .page-item.active .page-link:hover {
    background-color: #043E6E !important;
}

.all_pagination .pagination .page-item .page-link {
    transition: none !important;
    margin: 0px 8px;
    font-size: 16px;
    font-family: 'Montserrat';
    font-weight: 400;
    color: #1D1D1D;
    background-color: #fff;
}

.pagination_sec ul.pagination {
    margin: auto;
    display: flex;
}

.pagination_sec ul.pagination li a {
    cursor: pointer;
}

.pagination_sec .pag_link {
    position: relative;
    display: block;
    padding: 0px;
    margin-left: -1px;
    line-height: 1.25;
    color: #043E6E !important;
    background-color: #fff;
    border: 1px solid #dee2e6;
    border-radius: 25px;
    margin: 0px 10px;
    width: 40px;
    height: 40px;
    padding-top: 10px;
    padding-left: 16px;
}

.page-item.active .pag_link {
    z-index: 3;
    color: #fff !important;
    background-color: #043E6E !important;
    border-color: #043E6E !important;
    border-radius: 20px !important;
}

.pagination_sec .page-link {
    background-color: #fff;
    color: #043E6E;
    border: none;
}

#pagination .nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link2.active {
    color: #fff !important;
    background-color: #043E6E !important;
    border-color: transparent !important;
    border-radius: 0px !important;
    border-top-left-radius: 0px;
    border-bottom-right-radius: 0px;
    font-weight: 600;
    width: 40px;
    height: 40px;
    border-radius: 50% !important;
}

#pagination .nav-tabs a.nav-item {
    font-size: 20px;
    padding-top: 5px;
}

.nav-tabs .nav-link:focus,
.nav-tabs .nav-link:hover {
    background-color: transparent;
}

a#all_port_tab:focus {
    outline: none;
    box-shadow: none;
}

#port_list2 {
    display: none;
}

#port_list3 {
    display: none;
}

#mob_list2 {
    display: none;
}

.pagination .pag_link.active {
    z-index: 3;
    color: #fff !important;
    background-color: #043E6E !important;
    border-color: #043E6E !important;
    border-radius: 20px !important;
}

.page-link:hover {
    background-color: transparent !important;
}

.page-link:focus {
    z-index: 3;
    outline: 0;
    box-shadow: none !important;
}

#pagination .nav-tabs a.nav-item:hover {
    text-decoration: none;
}

.portfolio_links.text-center a:hover {
    text-decoration: none;
}

div#porttabs_heading {
    border-bottom: 1px solid #E2E2E2;
    padding-left: 8%;
    padding-right: 8%;
}

nav.portfolio_nav {
    margin: auto;
    display: flex;
    margin-bottom: 20px;
    margin-top: 80px;
}

.nav-tabs {
    border-bottom: none !important;
}

.nav-tabs a.nav-item {
    color: #1D1D1D;
    font-size: 18px;
    font-family: 'Montserrat';
    font-weight: 600;
    margin: 0px 20px;
    text-transform: uppercase;
}

.nav-tabs .nav-link {
    border: none !important;
}

#porttabs_heading .nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
    color: #fff !important;
    background-color: #3EC5E9 !important;
    border-color: transparent !important;
    border-radius: 0px !important;
    border-top-left-radius: 10px !important;
    border-bottom-right-radius: 10px !important;
    font-weight: 600;
}

.portfolio_links {
    margin: auto;
}

.portfolio_links.text-center a {
    margin: 0px 10px;
    font-size: 18px;
    padding: 10px 15px;
    font-family: 'Montserrat';
    color: #1D1D1D;
    font-weight: 600;
    text-transform: uppercase;
}

.port-active-link {
    color: #fff;
    background-color: #3EC5E9;
    border-color: transparent;
    border-radius: 0px;
    border-top-left-radius: 10px;
    border-bottom-right-radius: 10px;
    font-weight: 600;
}

.portfolio_links a.port-active-link {
    color: #fff;
}


/*New Portfolio Css*/

div#all_port1 {
    overflow: hidden;
}

div#all_port2 {
    overflow: hidden;
}

.page-item.active .page-link:hover {
    background-color: #fff;
    color: #007bff;
}


/*New Portfolio Css Ends*/


/*Web Portfolio Css starts*/

#nav_mobile1 {
    overflow: hidden;
}

#nav_mobile2 {
    overflow: hidden;
}


/*Web Portfolio Css ends*/


/** Portfolio Page Css Ends**/


/** Service Page Css Starts**/

.smbapp_sec {
    margin: 80px 0px;
}

.serve_hd hr {
    background-color: #116DA1;
    height: 2px;
    width: 100px;
    margin-top: 20px;
    margin-bottom: 20px;
}

.serve_hd h2 {
    text-align: center;
    color: #0D6BA0;
    font-size: 48px;
    font-family: 'Montserrat';
    text-transform: uppercase;
    letter-spacing: 0.42px;
}

.mobile_serv_sec p {
    font-size: 18px;
    color: #666666;
    line-height: 32px;
    font-family: 'Montserrat';
    letter-spacing: 0.22px;
}

.servc_coll h4 {
    font-size: 20px;
    color: #1D1D1D;
    font-weight: 600;
    line-height: 27px;
    font-family: 'Montserrat';
    margin-top: 30px;
    margin-bottom: 15px;
}

.servc_coll p {
    font-size: 16px;
    color: #666666;
    line-height: 30px;
    font-family: 'Montserrat';
}

img.srvc_icn1 {
    border: 1px solid #7AB539;
    padding: 80px;
    border-radius: 50%;
}

img.srvc_icn2 {
    border: 1px solid #999999;
    padding: 80px;
    border-radius: 50%;
}

img.srvc_icn3 {
    border: 1px solid #80DEEA;
    padding: 80px;
    border-radius: 50%;
}

.servc_coll {
    padding: 15px;
}

.mobile_serv_sec {
    padding: 0px 15px;
}

.servc_quote h5 {
    width: 100%;
    border-bottom: 1px solid #BEBEBE;
    line-height: 0.1em;
    margin: 20px 20px;
    position: relative;
}

.servc_quote h5 span {
    background: #fff;
    padding: 0 20px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.servc_quote h5 span button {
    font-family: 'Montserrat';
    font-weight: 600;
    background-color: #043E6E;
    color: #fff;
    border: none;
    border-radius: 30px;
    padding: 16px 50px;
    font-size: 16px;
    text-transform: uppercase;
}

.servc_quote h5 span button:active {
    background-color: #043E6E !important;
}

.servc_quote h5 span button:hover {
    background-color: #fff;
    color: #043E6E;
    border-color: #043E6E;
    border: 1px solid #043E6E;
}

.servc_quote {
    margin-bottom: 100px;
}

.container-fluid.srvcap_sec {
    background-color: #F2F3F8;
    padding-right: 8%;
    padding-left: 0px;
    overflow: hidden;
}

.srvc_rtcoll {
    padding: 80px 0px;
}

.srvc_rtcoll h2 {
    line-height: 48px;
    text-align: left;
    color: #0D6BA0;
    font-size: 32px;
    font-family: 'Montserrat';
    text-transform: uppercase;
    letter-spacing: 0.42px;
    margin-bottom: 20px;
}

.srvc_rtcoll hr {
    background-color: #116DA1;
    height: 2px;
    width: 100px;
    margin-left: 0px;
    margin-top: 20px;
    margin-bottom: 20px;
}

.srvc_rtcoll h5 {
    font-size: 20px;
    color: #1E1E1E;
    font-weight: 600;
    line-height: 27px;
    font-family: 'Montserrat';
    margin-bottom: 15px;
    letter-spacing: 0.3px;
}

.srvc_rtcoll p {
    font-size: 16px;
    color: #666666;
    line-height: 30px;
    font-family: 'Montserrat';
    margin-bottom: 0px;
}

.srvc_imgicon img {
    margin: auto;
    display: table;
}

.srvc_imgicon {
    width: 120px;
    height: 120px;
    border: 2px dashed #003D70;
    border-radius: 50%;
    align-items: center;
    display: flex;
}

.srvc_imgicon:hover {
    background-color: #fff;
    cursor: pointer;
    -webkit-transition: background-color 0.5s ease-out;
    -moz-transition: background-color 0.5s ease-out;
    -o-transition: background-color 0.5s ease-out;
    transition: background-color 0.5s ease-out;
}

.container-fluid.srvchoose_sec {
    margin: 80px 0px;
    margin-bottom: 160px;
}

.srvchoose_sec h2 {
    text-align: center;
    color: #0D6BA0;
    font-size: 32px;
    font-family: 'Montserrat';
    text-transform: uppercase;
    letter-spacing: 0.42px;
    margin-bottom: 20px;
}

.srvchoose_sec hr {
    background-color: #116DA1;
    height: 2px;
    width: 100px;
    margin-top: 20px;
    margin-bottom: 20px;
}

.chs_srvc1 h4 {
    text-align: right;
    margin-right: 70px;
}

.chs_srvc1 p {
    text-align: right;
    margin-right: 70px;
}

.srvchoose_sec h4 {
    font-size: 20px;
    color: #1E1E1E;
    font-weight: 600;
    line-height: 27px;
    font-family: 'Montserrat';
    margin-bottom: 10px;
    letter-spacing: 0.3px;
}

.srvchoose_sec p {
    font-size: 16px;
    color: #666666;
    line-height: 30px;
    font-family: 'Montserrat'
}

.choose_srve_icn img {
    margin: auto;
    display: flex;
    width: 80px;
}

.choose_srve_icn {
    border-radius: 50%;
    border: 2px solid #1EB8E2;
    width: 200px;
    height: 200px;
    display: flex;
    background: white;
}

.chs_srvc1 h4 {
    margin-top: 60px;
}

.chs_srvc2 h4 {
    margin-top: 60px;
}

.chs_srvc2 {
    position: absolute;
    top: 70px;
    left: -100px;
    z-index: -1;
}

.chs_srvc1 {
    position: relative;
}

.chs_srvc1 span {
    background-color: #043E6E;
    width: 50px;
    height: 50px;
    color: #fff;
    border-radius: 50%;
    position: absolute;
    font-weight: 600;
    display: flex;
    margin: auto;
    padding-left: 15px;
    padding-top: 12px;
    font-size: 18px;
    font-family: 'Montserrat';
}

.chs_srvc2 span {
    background-color: #043E6E;
    width: 50px;
    height: 50px;
    color: #fff;
    border-radius: 50%;
    position: absolute;
    font-weight: 600;
    display: flex;
    margin: auto;
    padding-left: 15px;
    padding-top: 12px;
    font-size: 18px;
    font-family: 'Montserrat';
    right: 110px;
    top: -15px;
}

.chs_srvc1 p.chs_lineicn img {
    position: absolute;
    top: 110px;
    transform: rotate(180deg);
    right: 0px;
}

.chs_srvc2 p.chs_lineicn img {
    position: absolute;
    top: 100px;
    left: -70px;
}

.srvlast_sec {
    background-image: url('img/srvc_lets_discuss.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    padding: 80px 0px;
    height: auto;
}

.srvlast_sec h5 {
    color: #fff;
    font-family: 'Montserrat';
    font-size: 32px;
    font-weight: 600;
    text-transform: uppercase;
    line-height: 48px;
}

.srvlast_sec h5 span {
    text-transform: none;
    font-weight: normal;
}

.srvlast_sec button.btn.btn-primary {
    background: #fff;
    color: #003B72;
    border-color: #fff;
    border-radius: 25px;
    padding: 10px 36px;
    font-size: 16px;
    text-transform: uppercase;
    font-weight: 700;
    font-family: 'Montserrat';
}

.srvlast_sec button.btn.btn-primary:hover {
    background-color: #003B72;
    color: #fff;
    border-color: #003B72;
}


/** Service Page Css Ends**/


/** UI/UX Page Css Starts**/

.uiux_serv_sec {
    margin-top: 80px;
    overflow: hidden;
}

.uiux_serv_sec p {
    color: #666666;
    font-size: 16px;
    letter-spacing: 0.18px;
    font-family: 'Montserrat';
    font-weight: 500;
    line-height: 30px;
    text-align: center;
    margin-bottom: 15px;
}

.uix_box {
    position: relative;
}

.uix_box h3 {
    font-size: 20px;
    margin-top: 30px;
    margin-bottom: 10px;
    color: #1D1D1D;
    letter-spacing: 0.3px;
    text-transform: uppercase;
    font-family: 'Montserrat';
    font-weight: 600;
}

.uix_box p {
    color: #666666;
    font-size: 16px;
    letter-spacing: 0.18px;
    font-family: 'Montserrat';
    font-weight: 300;
    line-height: 30px;
}

.uix_icn {
    width: 240px;
    height: 240px;
    border-radius: 50%;
    border: 1px solid #00B8E8;
    margin: auto;
}

.uix_icn img {
    margin: auto;
}

.container-fluid.benefit_sec {
    margin-top: 80px;
    background-color: #043E6E;
    padding-right: 0px;
    padding-left: 0px;
    overflow: hidden;
    padding-bottom: 40px;
}

.col-lg-6.buix_coleft {
    padding-top: 50px;
    padding-left: 8%;
}

.buix_coleft h2 {
    color: #fff;
    text-align: left;
    font-size: 32px;
    font-family: 'Montserrat';
    text-transform: uppercase;
    letter-spacing: 0.42px;
}

.buix_coleft hr {
    background-color: #fff;
    height: 2px;
    width: 100px;
    margin-top: 20px;
    margin-left: 0px;
}

.benefit_textcol h5 {
    font-family: 'Montserrat';
    letter-spacing: 0.3px;
    color: #fff;
    text-transform: uppercase;
    font-size: 20px;
    font-weight: 600;
}

.benefit_textcol p {
    letter-spacing: 0.18px;
    font-family: 'Montserrat';
    color: #FFFFFF;
    font-size: 16px;
    line-height: 30px;
}

.uix_iconn {
    width: 150px;
    height: 150px;
    background-color: #00B8E8;
    border-radius: 50%;
    position: relative;
    top: 35px;
    left: 35px;
}

.uix_iconn img {
    margin: auto;
    display: table;
}

.colleft_icon {
    position: relative !important;
}

.uixsemi_bordr {
    border-radius: 50%;
    width: 220px;
    height: 220px;
    background: linear-gradient( to right, #FFF 0%, #fff 50%, #043E6E 50%, #043E6E 100%);
}

.uix_iconn:before {
    content: '';
    position: absolute;
    border: 2px dashed #00B8E8;
    width: 170px;
    height: 170px;
    border-radius: 50%;
    left: -10px;
}

img.buix_img_bg.img-fluid {
    position: absolute;
    right: 0px;
}

img.buix_pic.img-fluid {
    position: relative;
    top: 50px;
    float: right;
    right: 0px;
}

.buix_colrt {
    position: relative;
}

.container-fluid.uix_appsec {
    position: relative;
    padding-left: 0px;
    padding-right: 0px;
    overflow: hidden;
}

.uix_app_img {
    position: relative;
}

img.uxapp_bg.img-fluid {
    position: relative;
}

img.uxapp_pic.img-fluid {
    position: absolute;
    left: 0px;
}

.uix_apptext h2 {
    color: #0D6BA0;
    font-size: 32px;
    text-align: left;
    text-transform: uppercase;
    letter-spacing: 0.42px;
    font-family: 'Montserrat';
}

.uix_apptext hr {
    background-color: #116DA1;
    height: 2px;
    width: 100px;
    margin-left: 0px;
    margin-top: 20px;
}

.uix_apptext {
    padding-right: 8%;
    margin-top: 120px;
}

.uix_apptext h3 {
    letter-spacing: 0.63px;
    color: #1D1D1D;
    font-size: 32px;
    font-weight: 500;
    font-family: 'Montserrat';
}

.uix_apptext p {
    letter-spacing: 0.18px;
    color: #666666;
    font-size: 16px;
    line-height: 30px;
    font-family: 'Montserrat';
}

.uix_apptext button.btn.btn-primary {
    font-family: 'Montserrat';
    background-color: #043e6e;
    border-radius: 25px;
    border-color: #043e6e;
    padding: 8px 36px;
    text-transform: uppercase;
    font-weight: 600;
    font-size: 16px;
}

img.benfits_dot {
    position: absolute;
    left: -30px;
}

.colrt_btxt {
    padding-left: 40px !important;
}


/** UI/UX Page Css Ends**/


/**WEB DEVELOPMENT Page Css Starts**/

.webdevt_sec {
    margin: 80px 0px;
}

.webdevt_sec h2 {
    color: #0D6BA0;
    font-size: 32px;
    text-align: center;
    text-transform: uppercase;
    letter-spacing: 0.42px;
    font-family: 'Montserrat';
}

.webdevt_sec p {
    text-align: center;
    font-size: 16px;
    line-height: 30px;
    letter-spacing: 0.18px;
    color: #666666;
    margin-bottom: 0px;
    margin-top: 30px;
}

.webdevt_sec hr {
    background-color: #116DA1;
    height: 2px;
    width: 100px;
    margin-top: 15px;
}

.webd_para p {
    letter-spacing: 0.18px;
    color: #666666;
    font-size: 18px;
    text-align: center;
    font-family: 'Montserrat';
    line-height: 30px;
}

.webdev-tech h3 {
    letter-spacing: 0.42px;
    color: #FFFFFF;
    text-transform: uppercase;
    font-family: 'Montserrat';
    font-size: 32px;
}

.coll-webtech-bg {
    background-color: #043974;
}

.webdev-tech {
    padding: 20px 80px;
}

.webdev-tech hr {
    margin-left: 0px;
    background-color: #fff;
}

.webdev-tech p {
    text-align: left;
    letter-spacing: 0.18px;
    color: #FFFFFF;
    font-family: 'Montserrat';
    font-size: 16px;
    line-height: 36px;
    font-weight: 300;
}

.hybrid-msec {
    border: 1px solid #70707038;
    border-radius: 30px;
    background-color: #fff;
    height: 100%;
    padding: 40px 40px;
}

.row.webd-technology {
    box-shadow: 5px 5px 11px #00000010;
    background-color: #fff;
    margin-top: 50px;
}

.hybrid-msec h4 {
    font-size: 20px;
    font-weight: 600;
    letter-spacing: 0.2px;
    color: #003D70;
    text-align: center;
    margin: 20px 0px;
    font-family: 'Montserrat';
}

.hybrid-msec p {
    letter-spacing: 0.18px;
    color: #666666;
    font-size: 16px;
    line-height: 30px;
    font-family: 'Montserrat';
}

.webd_framework_sec {
    background-color: #F2F3F8;
    padding: 80px 0px;
}

.framework-logo {
    background-color: #fff;
    box-shadow: 0px 2px 78px #1B289B1F;
    border: 1px solid #70707038;
    border-radius: 25px;
    padding: 15px 30px;
}

.framework-logo h5 {
    letter-spacing: 0.32px;
    color: #003974;
    text-transform: uppercase;
    font-size: 32px;
    padding-left: 30px;
    font-family: 'Montserrat';
    font-weight: 500;
}

.framework-rtsec h2 {
    color: #0D6BA0;
    font-size: 32px;
    text-align: center;
    text-transform: uppercase;
    letter-spacing: 0.42px;
    font-family: 'Montserrat';
}

.framework-rtsec hr {
    background-color: #116DA1;
    height: 2px;
    width: 100px;
    margin-top: 15px;
    margin-bottom: 30px;
}

.framework-rtsec p {
    text-align: left;
    letter-spacing: 0.18px;
    color: #666666;
    font-size: 16px;
    line-height: 30px;
    font-family: 'Montserrat';
}

.frame_txt h6 {
    letter-spacing: 0.18px;
    color: #333333;
    text-transform: uppercase;
    font-weight: 600;
    font-size: 18px;
}

.container-fluid.webd-frontendtech {
    margin: 80px 0px;
}

.frontend_ltcoll h2 {
    text-align: center;
    color: #0D6BA0;
    font-size: 32px;
    font-family: 'Montserrat';
    text-transform: uppercase;
    letter-spacing: 0.42px;
    margin-bottom: 20px;
}

.frontend_ltcoll p {
    margin-top: 30px;
    text-align: center;
    color: #666666;
    font-size: 16px;
    font-family: 'Montserrat';
    letter-spacing: 0.18px;
    margin-bottom: 20px;
    line-height: 30px;
}

.frontend_ltcoll hr {
    background-color: #116DA1;
    height: 2px;
    width: 100px;
    margin-top: 20px;
    margin-bottom: 20px;
}

.frontt_tech {
    background: #fff;
    border: 1px solid #DEDEDE;
    border-radius: 25px;
    height: 100%;
    padding: 30px 20px;
}

.front_techimg {
    width: 110px;
    height: 110px;
    border: 2px dashed #003D70;
    border-radius: 50%;
    margin: auto;
    align-items: center;
    display: flex;
}

.front_techimg img {
    margin: auto;
    display: table;
}

.frontt_tech h6 {
    letter-spacing: 0.3px;
    color: #1E1E1E;
    text-transform: uppercase;
    font-weight: 600;
    font-size: 20px;
    text-align: center;
    padding-top: 20px;
}

.webd-nodejs {
    background-color: #F2F3F8;
    padding: 80px 0px;
}

.para-webd-node p {
    margin-top: 30px;
    letter-spacing: 0.18px;
    color: #666666;
    text-align: center;
    font-size: 16px;
    line-height: 30px;
    font-family: 'Montserrat';
}

.node-framework {
    background-color: transparent;
    height: 100%;
    width: 200px;
    height: 200px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    margin: auto;
}

.node_clr1 {
    border: 2px solid #F4DA1E;
}

.node_clr2 {
    border: 2px solid #E0234E;
}

.node_clr3 {
    border: 2px solid #000000;
}

.htxt h5 {
    letter-spacing: 0.3px;
    color: #1D1D1D;
    text-transform: uppercase;
    font-weight: 600;
    text-align: center;
    padding-top: 20px;
}

.node-framework img {
    margin: auto;
    display: table;
}

.webd-nodejs h2 {
    color: #0D6BA0;
    font-size: 32px;
    text-align: center;
    text-transform: uppercase;
    letter-spacing: 0.42px;
    font-family: 'Montserrat';
}

.webd-nodejs hr {
    background-color: #116DA1;
    height: 2px;
    width: 100px;
    margin-top: 15px;
}


/**WEB DEVELOPMENT Page Css Ends**/


/** QA Page Css starts**/

.container-fluid.qa_serv_sec {
    margin: 80px 0px;
}

.qa_head_text p {
    font-size: 16px;
    color: #666666;
    text-align: center;
    line-height: 30px;
    letter-spacing: 0.22px;
    margin-bottom: 0px;
}

.test_coll {
    background: #FFF;
    border: 1px solid #DEDEDE;
    border-radius: 4px;
    position: relative;
    height: 100%;
}

.testbg {
    background-color: #F2F3F8;
    width: 100%;
    height: 120px;
    position: absolute;
}

.test_icon {
    background-color: #043E6E;
    width: 140px;
    height: 140px;
    margin: auto;
    border-radius: 50%;
    position: relative;
    top: 40px;
    border: 10px solid #fff;
}

.test_icon img {
    margin: auto;
}

.text_txt {
    padding: 50px 20px 30px 20px;
}

.text_txt h3 {
    letter-spacing: 0.2px;
    color: #003D70;
    text-transform: uppercase;
    font-size: 20px;
    text-align: center;
    font-weight: 600;
}

.text_txt p {
    font-size: 16px;
    text-align: center;
    letter-spacing: 0;
    color: #666666;
    line-height: 30px;
}

.container-fluid.software_sec {
    padding-top: 80px;
    padding-bottom: 80px;
    background-color: #F2F3F8;
}

.software_sec h2 {
    text-align: center;
    text-transform: uppercase;
    letter-spacing: 0.42px;
    color: #0D6BA0;
    font-size: 32px;
    font-weight: 500;
}

.software_sec hr {
    background-color: #116DA1;
    height: 2px;
    width: 100px;
    margin-top: 20px;
}

.qa_test_sec {
    width: 100px;
    height: 100px;
    border: 2px dashed #003D70;
    border-radius: 50%;
}

.qa_test_sec img {
    margin: auto;
}

.qacol_text h3 {
    font-size: 20px;
    color: #1D1D1D;
    line-height: 30px;
    font-family: 'Montserrat';
    text-transform: uppercase;
    font-weight: 600;
}

.qacol_text p {
    color: #666666;
    font-size: 16px;
    line-height: 30px;
    letter-spacing: 0.18px;
    opacity: 1;
    font-family: 'Montserrat';
    font-weight: 300;
}

.qacol_text {
    padding-left: 30px;
}


/** QA Page Css ends**/


/** Why Choose US Page Css Starts**/

.chose_pointsec {
    padding-right: 8% !important;
    background-color: #f2f8fe;
    overflow: hidden;
}

.choseapp_rt {
    padding-top: 80px;
    padding-bottom: 80px;
}

.chose_coleft img.chs_leftbg {
    position: absolute;
    left: 0px;
    max-width: 95%;
    height: auto;
}

.chose_coleft img.img-fluid {
    position: relative;
    padding-left: 50px;
    padding-top: 50px;
}

.container-fluid.choose_offsec {
    padding-left: 8%;
    padding-right: 0px;
    overflow: hidden;
}

.off_coleft h4 {
    letter-spacing: 0.42px;
    color: #666666;
    text-transform: uppercase;
    font-size: 32px;
}

.off_coleft h2 {
    letter-spacing: 0.62px;
    color: #0D6BA0;
    text-transform: uppercase;
    font-size: 50px;
    font-weight: 700 !important;
    border-left: 9px solid #0D6BA0;
    padding-left: 15px;
    margin-top: 20px;
}

.off_coleft p {
    letter-spacing: 0.18px;
    color: #666666;
    font-size: 16px;
    line-height: 30px;
    margin-top: 20px;
    margin-bottom: 20px;
}

.off_coleft button.btn.btn-primary {
    background-color: #043E6E;
    border-color: #043E6E;
    border-radius: 25px;
    padding: 8px 26px;
    font-size: 16px;
    text-transform: uppercase;
    font-weight: 600 !important;
}

.choseapp_rt hr {
    background-color: #116DA1;
    height: 2px;
    width: 100px;
    margin-top: 15px;
    margin-left: 0px;
}

.choseapp_rt h2 {
    color: #0D6BA0;
    font-size: 28px;
    text-align: left;
    text-transform: uppercase;
    letter-spacing: 0.42px;
    font-family: 'Montserrat';
}

.chs_appicn_sec {
    width: 110px;
    height: 110px;
    border: 2px dashed #003D70;
    border-radius: 50%;
}

.chs_appicn_sec img {
    margin: auto;
}

.chscol_txt h3 {
    font-size: 20px;
    color: #1D1D1D;
    line-height: 30px;
    font-family: 'Montserrat';
    text-transform: uppercase;
    font-weight: 600;
}

.chscol_txt p {
    color: #666666;
    font-size: 16px;
    line-height: 30px;
    letter-spacing: 0.18px;
    opacity: 1;
    font-family: 'Montserrat';
    font-weight: 300;
}

.chose_graphic_sec {
    background-image: url('img/chose_graphicsbg.png');
    background-repeat: no-repeat;
    background-size: auto;
    padding-top: 80px;
    position: relative;
    padding-left: 50px !important;
    padding-right: 90px !important;
}

.cloud_sun_img {
    position: relative;
}

.cloud_sun_img img.clouds_cimg {
    position: relative;
    z-index: 1;
    max-width: 100%;
    height: auto;
    animation: fadeIn 3.5s;
    animation-duration: 3s;
    animation-direction: alternate;
    animation-iteration-count: infinite;
}

@keyframes fadeIn {
    0% {
        transform: translateY(0);
    }
    100% {
        transform: translateY(-50px);
    }
}

@keyframes fadeInLeft {
    0% {
        transform: translateY(0);
    }
    100% {
        transform: translateY(-50px);
    }
}

.cloud_sun_img img.sun_cimg {
    position: absolute;
    left: 230px;
    max-width: 100%;
    height: auto;
    animation: fadeInLeft 4s;
    animation-direction: alternate;
    animation-iteration-count: infinite;
}

.chose_graphics_pic img {
    max-width: 100%;
    height: auto;
}

.success_sec img.success_graph_img {
    max-width: 100%;
    height: auto;
    position: relative;
    left: -40px;
    top: 70px;
}

.cloud_rain_img {
    justify-content: center;
    display: flex;
}

.cloud_rain_img img.rain_cloud_img {
    max-width: 100%;
    height: auto;
    animation: fadeIn 4s;
    animation-direction: alternate;
    animation-iteration-count: infinite;
}

.succs_txt p {
    letter-spacing: 0.14px;
    color: #FFFFFF;
    font-size: 14px;
    font-weight: 300 !important;
    text-align: left;
    line-height: 24px;
}

.succs_txt p span {
    font-weight: 600 !important;
}

.success_col18 {
    position: absolute;
    width: 320px;
}

.sucs_bg_circle {
    width: 100px;
    height: 100px;
    background-color: #8EE0C7;
    border: 1px solid #FFFFFF;
    border-radius: 50%;
    float: right;
    display: flex;
    align-items: center;
    margin: auto;
}

.sucs_bg_circle h6 {
    margin: auto;
    letter-spacing: 0.44px;
    color: #053E6E;
    text-transform: uppercase;
    font-size: 20px;
    font-weight: 700 !important;
}

.sucs_circle_dotted {
    float: right;
    border-radius: 50%;
    width: 120px;
    height: 120px;
    align-items: center;
    display: flex;
    border: 2px dashed #FFFFFF;
    animation: bounceIn 2s;
    animation-direction: alternate;
    animation-iteration-count: infinite;
    z-index: 1111;
    position: relative;
}

.success_col19 .sucs_circle_dotted:hover {
    cursor: pointer;
    -moz-animation: neon2 1.5s ease-in-out infinite alternate;
    animation: neon2 1.5s ease-in-out infinite alternate;
    box-shadow: 0px 10px 40px 3px rgb(245, 107, 107);
}

.success_col18 .sucs_circle_dotted:hover {
    cursor: pointer;
    -moz-animation: neon2 1.5s ease-in-out infinite alternate;
    animation: neon2 1.5s ease-in-out infinite alternate;
    box-shadow: 0px 10px 40px 3px rgb(124, 203, 188);
}

.success_col20 .sucs_circle_dotted:hover {
    cursor: pointer;
    -moz-animation: neon2 1.5s ease-in-out infinite alternate;
    animation: neon2 1.5s ease-in-out infinite alternate;
    box-shadow: 0px 10px 40px 3px rgb(0, 182, 230);
}

.success_col18 {
    position: absolute;
    width: 320px;
    bottom: 360px;
    left: 0px;
}

.success_col19 {
    position: absolute;
    width: 350px;
    right: 50px;
    bottom: 180px;
}

.success_col19 .sucs_bg_circle {
    background-color: #F56B6B;
}

.success_col19 .sucs_bg_circle h6 {
    color: #fff;
}

.success_col19 .sucs_circle_dotted {
    float: none;
}

.success_col19 .succs_txt p {
    position: relative;
    left: 100px;
    padding: 0px 20px;
}

img.chs_down_arrow3 {
    position: absolute;
    left: 60px;
    padding-top: 10px;
}

.success_col20 {
    position: absolute;
    width: 320px;
    left: 190px;
    top: 250px;
}

.success_col20 .sucs_bg_circle {
    background-color: #00B8E8;
}

.success_col20 .sucs_bg_circle h6 {
    color: #fff;
}

img.chs_down_arrow {
    position: absolute;
    top: 35px;
    right: -30px;
}

img.chs_down_arrow2 {
    position: absolute;
    top: 35px;
    right: -30px;
}


/** Why Choose US Page Css Ends**/


/** Career Page Css starts**/

.career_hd_sec h2 {
    color: #0D6BA0;
    font-size: 32px;
    text-align: center;
    text-transform: uppercase;
    letter-spacing: 0.42px;
    font-family: 'Montserrat';
}

.career_hd_sec p {
    color: #666666;
    font-size: 20px;
    line-height: 36px;
    font-family: 'Montserrat';
    font-weight: 300;
    text-align: left;
    margin-top: 30px;
    letter-spacing: 0.22px;
}

.career_hd_sec {
    margin: 80px 0px;
}

.career_hd_sec hr {
    background-color: #116DA1;
    height: 2px;
    width: 100px;
    margin-top: 15px;
}

.career_section h2 {
    text-align: center;
    color: #0D6BA0;
    font-size: 42px;
    line-height: 60px;
    font-family: 'Montserrat';
}

.career_section h3 {
    text-align: center;
    color: #666666;
    font-size: 20px;
    line-height: 30px;
    font-family: 'Montserrat';
    font-weight: 500 !important;
}

.career_coll h4 {
    color: #666666;
    font-size: 20px;
    font-weight: 600;
    text-align: left;
    font-family: 'Montserrat';
    margin-bottom: 0px;
}

.career_coll span {
    color: #003B72;
    font-size: 16px;
    font-weight: 600;
    text-align: left;
    font-family: 'Montserrat';
}

.carr_descript ul {
    padding: 10px 20px;
}

.carr_descript {
    margin-top: 20px;
    margin-bottom: 60px;
}

.carr_descript ul li {
    font-size: 16px;
    line-height: 24px;
    color: #666666;
    margin: 10px 0px;
    font-family: 'Montserrat';
}

.career_coll button.btn.btn-primary {
    background: transparent;
    color: #043E6E;
    border: 2px solid #043E6E;
    border-radius: 25px;
    font-size: 16px;
    padding: 8px 22px;
    font-weight: 600;
    font-family: 'Montserrat';
    margin-top: 10px;
    position: absolute;
    bottom: 30px;
}

.career_coll button.btn.btn-primary:active {
    background-color: #043E6E;
    border-color: #043E6E;
}

.career_section {
    background-color: #F2F3F8;
    padding: 80px 0px;
}

.prof_head h2 {
    text-align: center;
    color: #0D6BA0;
    font-size: 32px;
    line-height: 48px;
    font-family: 'Montserrat';
    margin-top: 50px;
}

.prof_head hr {
    background-color: #116DA1;
    height: 2px;
    width: 100px;
    margin-top: 8px;
}

.coll-leftbg {
    background-color: #003776;
    padding: 30px 0px;
}

.coll-rtbg {
    background-color: #fff;
}

.coll-rtbg:hover {
    background-color: #00B6F1;
    cursor: pointer;
}

.coll-rtbg:hover>h4 {
    color: #fff;
}

.carr-icon.row {
    padding: 0px 20px;
}

.career_coll a:hover {
    text-decoration: none;
}


/** Career Page Css Ends**/


/** JOB PROFILE Page Css Starts**/

.jobs_reqd {
    margin: 80px 0px;
}

.jobs_reqd h2 {
    color: #0D6BA0;
    font-size: 32px;
    text-align: left;
    text-transform: uppercase;
    letter-spacing: 0.42px;
    font-family: 'Montserrat';
}

.jobs_reqd hr {
    background-color: #116DA1;
    height: 2px;
    width: 100px;
    margin-top: 15px;
    margin-left: 0px;
}

.description-job button.btn.btn-primary {
    background: transparent;
    color: #043E6E;
    border: 2px solid #043E6E;
    border-radius: 25px;
    font-size: 16px;
    padding: 8px 27px;
    font-weight: 600;
    font-family: 'Montserrat';
    margin-top: 10px;
}

.description-job p {
    color: #666666;
    font-size: 20px;
    line-height: 34px;
    font-family: 'Montserrat';
    font-weight: 400;
    text-align: left;
    margin-top: 30px;
    letter-spacing: 0.22px;
}

.description-job p.qualify_para {
    color: #666666;
    font-size: 18px;
    line-height: 30px;
    font-family: 'Montserrat';
    font-weight: 400;
}

.description-job ul li {
    font-size: 18px;
    line-height: 27px;
    color: #666666;
    margin: 10px 0px;
    letter-spacing: 0.22px;
    font-family: 'Montserrat';
}

.description-job h5 {
    font-weight: 500;
}

.description-job h5 span {
    color: #666666;
    font-weight: 400;
}


/** JOB PROFILE Page Css Ends**/


/** MODAL FOR PROFILE Css Starts**/

.modal-content {
    width: 1140px !important;
    height: auto !important;
    margin: auto !important;
    background-color: #fff !important;
    padding: 10px 50px;
    position: relative;
    border: none;
    top: 100px;
}

.modal-header {
    border-bottom: none !important;
}
button.close:focus {outline: none;}
button.close {
    position: absolute;
    top: 0px;
    right: 10px;
}

form.form_modal input {
    padding-left: 3px;
    background: transparent;
    border: none;
    color: #666666;
    font-weight: 500;
    border-bottom: 1px solid #ADADAD !important;
    border-radius: 0px;
}

form.form_modal textarea#car_message {
    background: transparent;
    color: #666666;
    padding-left: 0px;
    border-bottom: 1px solid #ADADAD !important;
    border-radius: 0px;
    border: none;
}

.input-textarea-group textarea#message {
    border-color: #ADADAD !important;
}

form.form_modal .input-group-prepend span.input-group-text {
    padding: 0px 0px;
    width: 25px;
    background-color: transparent;
    border: none;
    color: #fff;
    border-bottom: 1px solid #ADADAD;
    height: 38px;
    border-radius: 0px;
}

.input-group.custom-file.mb-4 {
    border-bottom: 1px solid #ADADAD;
    border-radius: 0px;
}

.md-col-rt h2 {
    font-weight: 600 !important;
    color: #0D6BA0;
    font-size: 24px;
    text-align: center;
    text-transform: uppercase;
    letter-spacing: 0.42px;
    font-family: 'Montserrat';
}

.md-col-rt hr.modal_hr {
    margin-bottom: 30px;
    background-color: #116DA1;
    height: 2px;
    width: 100px;
    margin-top: 15px;
}

form.form_modal select#sel1 {
    padding-left: 0px;
    background: transparent;
    border: none;
    color: #666666;
    background-color: transparent;
    border-bottom: 1px solid #ADADAD;
    border-radius: 0px;
}

form.form_modal input:focus {
    background: transparent;
    color: #666666;
}

form.form_modal button.btn.btn-primary {
    background-color: #1EB8E2 !important;
    border: 2px solid #fff;
    border-radius: 25px;
    font-size: 16px;
    padding: 8px 36px;
    font-family: 'Montserrat';
    font-weight: 600;
    margin-bottom: 15px;
    color: #fff;
    text-transform: uppercase;
    margin-top: 30px;
}

form.form_modal button.btn.btn-primary:active {
    background-color: #1EB8E2 !important;
    border-color: none !important;
}

.alert.alert-primary.mt-3.apply_alrt {
    position: absolute;
    bottom: 0px;
    right: 0px;
    width: 80%;
    border-radius: 30px;
    padding: 8px 36px;
    background-color: #e8ffe8;
    border-color: #56c382;
}

.invalid_text_modal {
    color: #dc3545;
    font-size: 13px;
}

.alert.alert-success.mt-3.contct_alert {
    padding: 8px 36px;
    background-color: #e8ffe8;
    border-color: #56c382;
}

.alert.alert-success.mt-3.contct_alert p {
    margin-top: 0px;
}

.alert.alert-success.mt-3.contct_aler p {
    margin-top: 0px;
    text-align: center;
}

.alert.alert-primary.mt-3.apply_alrt p {
    margin-top: 0px;
    text-align: center;
    margin-bottom: 0px;
}

.alert.alert-success.mt-3.alrt_getquote {
    border-radius: 30px;
    padding: 9px 25px;
    background-color: #e8ffe8;
    border-color: #56c382;
    position: absolute;
    bottom: 24px;
    right: 35px;
}

.alert.alert-success.mt-3.alrt_getquote p {
    margin-bottom: 0px;
    font-size: 16px;
}

form.form_modal.ng-dirty.ng-touched.ng-valid {
    position: relative;
}

.col-lg-6.col-md-12.col-12.input-col.mb-4.colapply_select {
    padding-left: 30px !important;
}

.col.col-lg-6.col-md-12.col-12.colapply_exp {
    padding-left: 30px !important;
}

@media (min-width: 576px) {
    .modal-dialog {
        max-width: none !important;
        margin: 1.75rem auto;
    }
}


/** MODAL FOR PROFILE Css Ends**/


/** ----------------------- MEDIA QUERIES CSS STARTS HERE ------------------------**/

@media (min-width:1360px) and (max-width: 1366px) {
    .slob_imgsec img.img-fluid.slob_bgraph {
        padding-top: 70px !important;
    }
    span.java_img {
        right: 150px !important;
    }
    span.php_img {
        top: 385px !important;
        right: 152px !important;
    }
    span.djsnt_ap.mys_img.wow.bounceInRight {
        right: 68px !important;
    }
    span.kotlin_ap.cde_img.wow.bounceInRight {
        right: 55px !important;
        top: 205px !important;
    }
    span.exp_ap.cde_img.wow.bounceInRight {
        right: 42px !important;
        top: 200px !important;
    }
    span.sf_ap.sft_img.wow.bounceInRight {
        right: 90px !important;
    }
    .eventslide.eventleftsld a.carousel-control-prev {
        left: 65% !important;
    }
    span#cbrgimg2.sft_img {
        top: 104px;
        right: 85px !important;
    }
    span#cbrgimg5.php_img {
        top: 405px !important;
        right: 155px !important;
    }
    span#cbrgimg3.exp_ap.cde_img.wow.bounceInRight {
        right: 45px !important;
        top: 215px !important;
    }
    span#cbrgimg4.mys_img {
        top: 325px !important;
        right: 85px !important;
    }
    span#safe_img1 {
        right: 125px;
        top: 30px;
    }
    span#safe_img2.sf_ap.sft_img {
        right: 95px !important;
        top: 115px;
    }
    span#compete_img1.java_img {
        top: 25px;
        right: 170px !important;
    }
    span#compete_img2.sft_img {
        top: 106px;
        right: 87px !important;
    }
    span#compete_img3.cde_img {
        top: 210px !important;
        right: 20px;
    }
    span#compete_img4.mys_img {
        top: 319px !important;
        right: 80px !important;
    }
    span#compete_img5.php_img {
        top: 407px !important;
        right: 175px !important;
    }
    span.tact_ap1#tact_im1 {
        top: 106px;
        right: 85px !important;
    }
    span#tact_im2 {
        top: 204px !important;
        right: 22px !important;
    }
    span.tact_ap3#tact_im3 {
        top: 305px !important;
        right: 86px !important;
    }
    span.sft_img#djsant_img1 {
        right: 86px !important;
    }
    span.ang_ap#djsant_img2 {
        right: 44px !IMPORTANT;
    }
    span.djsnt_ap#djsant_img3 {
        right: 65px !important;
        top: 290px;
    }
    span.swft_ap.mys_img#avera_ap3 {
        right: 80px;
        top: 312px;
    }
    span.kotlin_ap#avera_ap2 {
        right: 59px !important;
        top: 210px !important;
    }
    span.rcta_ap#avera_ap1 {
        right: 83px !important;
        top: 105px;
    }
    .uix_apptext {
        margin-top: 80px;
    }
    .slid_bnr_sec {
        padding-left: 7%;
    }
    .captcha {
        margin-top: 10px !important;
        margin-bottom: 0px;
    }
    #space_sec {
        padding-right: 7%;
        padding-left: 7%;
    }
    /* .port_miapp {margin-top: 40px !important;margin-bottom: 120px;} */
    .holla_imgsec img {
        margin-top: 12px;
    }
    .events_slider a.carousel-control-prev {
        left: 37% !important;
    }
    .eventslide span.carousel-control-next-icon {
        padding-top: 8px !important;
    }
    .eventslide span.carousel-control-prev-icon {
        padding-top: 8px !important;
    }
    img.uxapp_pic.img-fluid {
        position: absolute;
        top: 50px;
    }
}

@media (min-width: 1200px) and (max-width: 1601px) {
    .header_menu .navbar a.navbar-brand img {
        width: 150px;
    }
}

@media (min-width: 1366px) and (max-width: 1480px) {
    .header_menu .navbar ul.navbar-nav li a {
        font-size: 13px;
        margin: 0px 10px;
    }
    .chscol_txt h3 {
        font-size: 16px;
        line-height: 24px;
        margin-bottom: 0px;
    }
    .chscol_txt p {
        font-size: 14px;
        line-height: 24px;
    }
    .chs_appicn_sec {
        width: 70px;
        height: 70px;
    }
    .chs_appicn_sec img {
        margin: auto;
        width: 35px;
    }
    .choseapp_rt {
        padding-top: 70px;
        padding-bottom: 70px;
    }
    .chose_coleft img.img-fluid {
        position: relative;
        padding-left: 50px;
        padding-top: 30px;
        max-width: 80%;
    }
    .row.fss {
        padding-left: 20px;
        margin-bottom: 20px;
    }
    .check_icon span img {
        width: 14px !important;
    }
    .cow_imgsec {
        margin: 0px 0px;
    }
    .cow_imgsec img {
        margin-top: 80px;
    }
    .bigtime_imgsec img.img-fluid.big_time_pic {
        position: relative;
        max-width: 92%;
    }
    .bigtime_imgsec img.img-fluid.bigt_graphics {
        position: absolute;
        top: -5px;
        left: 0px;
        padding-left: 52px;
    }
    .typeapp-txt span i.fa-angle-right {
        padding-left: 10px;
    }
    .addrs-rt span p.adrs_line {
        font-size: 15px;
    }
    hr.adrs_brdr {
        margin-top: 8px;
    }
    .events_slider a.carousel-control-prev {
        left: 38%;
    }
    .slob_imgsec img.img-fluid.slob_bgraph {
        padding-top: 45px;
    }
    button.btn.btn-primary.btn-block {
        border-radius: 10px;
        margin-top: 15px;
    }
    .row.adres_sec.mt-4 {
        margin-top: 45px !important;
    }
    .form-sec p {
        margin-top: 10px;
    }
    .captcha {
        margin-top: 5px;
        margin-bottom: 0px;
    }
    .slider_sec .carousel-indicators li {
        right: 59%;
    }
    img.img-fluid.record_bgraphics {
        max-width: 64%;
    }
    .square_imgsec img.img-fluid.square_fpic {
        padding-top: 45px;
    }
    .you_ltpic img.img-fluid.you_bg {
        height: 570px;
    }
    span#cbrgimg1 {
        top: 35px;
        right: 143px;
    }
    span#cbrgimg2 {
        top: 122px;
        right: 86px !important;
    }
    span#cbrgimg3 {
        top: 225px;
        right: 45px !important;
    }
    span#cbrgimg4 {
        top: 333px;
        right: 85px;
    }
    span#cbrgimg5 {
        top: 416px;
        right: 150px;
    }
    span#safe_img1 {
        right: 148px;
        top: 40px;
    }
    span#compete_img1 {
        top: 30px;
        right: 170px;
    }
    span#compete_img2 {
        top: 114px;
        right: 90px !important;
    }
    span#compete_img3 {
        top: 221px;
        right: 17px;
    }
    span#compete_img4 {
        top: 329px;
        right: 85px;
    }
    span#compete_img5 {
        top: 418px;
        right: 175px;
    }
    span#safe_img2 {
        right: 98px !important;
        top: 125px;
    }
    span#tact_im1 {
        top: 125px;
        right: 85px !important;
    }
    span#tact_im2 {
        top: 228px;
        right: 21px;
    }
    span#tact_im3 {
        top: 330px;
        right: 95px !important;
    }
    span#djsant_img1 {
        right: 80px !important;
    }
    span#djsant_img2 {
        right: 42px !IMPORTANT;
    }
    span#djsant_img3 {
        right: 75px !important;
    }
    span#avera_ap3 {
        right: 85px;
    }
    span#avera_ap2 {
        right: 58px !important;
    }
    span#avera_ap1 {
        right: 78px !important;
    }
    .ideal_imgsec img.img-fluid.ideal_fpic {
        top: 15px;
    }
    .footer_sec ul li a {
        font-size: 12px;
    }
    .slider_sec ol.carousel-indicators {
        left: 40px;
    }
    .phn_apppic img {
        margin-left: 50px;
    }
    span.rcta_ap {
        right: 80px;
    }
    span.sft_img {
        right: 80px !important;
    }
    span.sf_ap {
        right: 90px !important;
    }
    .alert.alert-success.mt-3.alrt_getquote p {
        margin-bottom: 0px;
        font-size: 14px;
    }
    .col-lg-2.col-md-6.col-12.social_col {
        padding-left: 0px;
    }
    .container-fluid.get_sec.career_getsec {
        padding-top: 70px;
    }
    .events_slider a.carousel-control-next {
        left: 48%;
        bottom: -70px;
        width: 70px;
    }
    .uix_icn {
        width: 190px;
        height: 190px;
    }
    img.img-fluid.dm_arrow2 {
        position: absolute;
        left: 46%;
        top: 38%;
        max-width: calc(285px - 180px);
    }
    img.img-fluid.dm_arrow3 {
        position: absolute;
        right: 21%;
        top: 40%;
        max-width: calc(285px - 190px);
    }
    img.img-fluid.dm_arrow1 {
        position: absolute;
        top: 40%;
        left: 21%;
        max-width: calc(285px - 190px);
    }
    .choose_btnlink::before {
        width: 500px;
    }
    .choose_btnlink::after {
        width: 500px;
    }
    /* .offce_pic {
        padding-left: 0px;
    } */
    /* .col-lg-7.coladrs {
        margin-left: 0px;
        padding-left: 0px;
    } */
    .offce_pic img {
        max-width: 100%;
        border-radius: 20px;
    }
    img.hm_pic1_web {
        width: 990px;
        position: relative;
        top: 70px;
        left: -70px;
        z-index: 9999;
    }
    div#sweet_sec .port_sweet {
        padding-left: 16%;
    }
    div#laundris_sec .port_laundris {
        padding-left: 16%;
    }
    #httssec .htts_port {
        padding-right: 0%;
        padding-left: 16%;
    }
    .svdo_ltpic img.img-fluid.svdo_bg {
        position: relative;
        height: 616px;
    }
    .test_coll {
        min-height: 450px;
        max-height: 480px;
    }
    .slide_ryt .hm_appbg {
        margin-top: 70px;
        max-width: 100%;
        height: auto;
        margin-left: 40px;
    }
    .hm_apc2 {
        position: absolute;
        top: 40px;
        left: 40px;
        width: 345px;
    }
    .hm_apc1 {
        position: absolute;
        right: 10px;
        top: 60px;
        z-index: 1;
        width: 345px;
    }
    .row.mia_rtrow {
        margin-top: 20px;
    }
    .row.mia_rtrow img {
        max-width: 80%;
        position: absolute;
        right: 0px;
        padding-top: 15px;
    }
    .holla_imgsec img {
        max-width: 90%;
    }
    .row.avera_imgsec img {
        max-width: 87%;
    }
    .what_partnershp img {
        max-width: 90%;
        padding-top: 20px;
    }
    .side_techicn img {
        width: 50px;
    }
    span.php_img {
        position: absolute;
        top: 405px;
        right: 145px;
    }
    span.mys_img {
        position: absolute;
        top: 324px;
        right: 80px;
    }

    .getquotes label {
        font-size: 13px;
    }
    .choose_dotted img {
        right: -21%;
        width: calc(100% - 230px);
    }
    .choose_dotted2 img {
        right: -21%;
        width: calc(100% - 230px);
    }
    .serve_box p {
        font-size: 14px;
        line-height: 26px;
    }
    /**DM media css starts**/
    .dm_icon_sec img.digital_icn {
        padding-top: 25px;
        padding-left: 10px;
    }
    .dm_icon_sec h4 {
        padding-top: 25px;
        font-size: 16px;
        padding-left: 10px;
        line-height: 24px;
        padding-right: 15px;
    }
    .row.dm_title {
        top: 15px;
        left: 60px;
    }
    .dm_title h3 {
        font-size: 15px;
        padding-left: 15px;
    }
    .dm_what_sec {
        width: 90px;
        height: 90px;
    }
    .dmcol_txt h3 {
        font-size: 18px;
    }
    .dmcol_txt p {
        font-size: 15px;
        line-height: 26px;
    }
    .dmcol_txt {
        padding-left: 10px;
    }
    .dig_serve_sec {
        min-height: 320px;
        max-height: 350px;
    }
    /**DM media css ends**/
    /**Portfolio media css starts**/
    /* .mapple_imgsec img.img-fluid {z-index: 111;position: absolute;top: 110px;max-width: 100%;left: 0px;}
img.mapple_crcl {position: absolute;top: 10px;width: 630px;left: -15px;} */
    /* .port_miapp {margin-top: 20px !important ;margin-bottom: 80px !important;} */
    /* .evolution_imgsec img.evo_circle {position: absolute;top: 0px;left: 0px;width: 600px;}
.evolution_imgsec img.evo_img {z-index: 1;position: relative;top: 50px;max-width: 90%;} */
    /* .port_miapp.port_evou {margin-top: 60px;margin-bottom: 120px;}
.port_miapp.bigt_cow {margin-top: 50px;margin-bottom: 100px;}
.port_miapp.port_cow {margin-top: 50px;margin-bottom: 120px;} */
    /* .wig_imgsec img.img-fluid.wig_bgraphic {position: absolute;left: 0px;bottom: 0px;z-index: -1;} */
    /* .port_miapp.wig_sec_port {margin-top: 20px;margin-bottom: 100px;}
.port_miapp.port_square{margin-top: 30px;margin-bottom: 100px;}
.port_miapp.you_port {margin-top: 10px;margin-bottom: 80px;}
.port_miapp.port_slob {margin-top: 50px;margin-bottom: 60px;}
.port_miapp.svdo_port {margin-top: 30px;margin-bottom: 80px;} */
    /* .sweet_imgsec img {position: absolute;bottom: 0px;}
.laundris_imgsec img {position: absolute;bottom: 5px;}
.lookh_imgsec img {max-width: 95%;}
.htts_imgsec img {position: absolute;bottom: 0px;}
.port_miapp.port_laundris h2 img {width: 60px;}
.port_miapp.htts_port h2 img {width: 50px;}
.port_miapp.port_sweet h2 img {width: 55px;margin-top: -15px;} */
    .port_imgauto {
        padding: 50px 30px;
    }
    .port_miapp {
        padding: 60px 0px;
    }
    div#portfolio_sec2_pr .port_miapp {
        padding-right: 13%;
    }
    div#portfolio_sec1_pl .port_miapp {
        padding-left: 16%;
    }
    .technology_logo_img {
        padding: 10px;
        height: 110px;
    }
    .technology_logo_img .tech-imgsec {
        height: 55px;
    }
    .technology_logo_img h6 {
        font-size: 12px;
    }
    .port_miapp h2 {
        margin-bottom: 12px;
    }
    .port_miapp h3 {
        margin-bottom: 20px;
        margin-top: 15px;
        font-size: 18px;
        line-height: 27px;
    }
    .appstore_coll img {
        position: relative;
        left: -18px;
    }
    /**Portfolio media css ends**/
    /**Service media css starts**/
    img.srvc_icn1,
    img.srvc_icn2,
    img.srvc_icn3 {
        padding: 70px;
    }
    .chs_srvc2 {
        position: absolute;
        top: 70px;
        left: -50px;
        z-index: -1;
    }
    .chs_srvc2 span {
        right: 30px;
    }
    .chs_srvc2 p.chs_lineicn img {
        position: absolute;
        top: 100px;
        left: 0px;
    }
    .chs_srvc2 h4 {
        margin-left: 70px;
    }
    .chs_srvc2 p {
        margin-left: 70px;
    }
    .srvc_imgicon img {
        margin: auto;
        display: table;
        width: 35px;
    }
    .srvc_imgicon {
        width: 80px;
        height: 80px;
        border: 2px dotted #003D70;
        border-radius: 50%;
    }
    .srvc_rtcoll h5 {
        font-size: 18px;
        line-height: 27px;
        margin-bottom: 5px;
    }
    .srvc_ltcol img {
        margin-top: 80px;
    }
    .srvc_rtcoll p {
        font-size: 14px;
        line-height: 21px;
    }
    /**Service media css ends**/
    /**WEB DEVELOPMENT Media Css Starts**/
    .abtbnr_sec .slid_bnr_sec h2 {
        font-size: 36px;
    }
    .framework-rtsec hr {
        margin-bottom: 20px;
    }
    .hybrid-app-icon img {
        width: 120px;
    }
    .hybrid-msec p {
        font-size: 16px;
        line-height: 28px;
    }
    .hybrid-msec {
        padding: 30px 30px;
        height: auto;
    }
    .frontt_tech {
        height: auto;
    }
    .framework-rtsec p {
        font-size: 16px;
        line-height: 28px;
    }
    .frontt_tech h6 {
        font-size: 16px;
    }
    /**WEB DEVELOPMENT Media Css ends**/
    /**Why Choose Us Media Css Starts**/
    .sucs_circle_dotted {
        width: 85px;
        height: 85px;
    }
    .sucs_bg_circle {
        width: 65px;
        height: 65px;
    }
    .success_col18 {
        position: absolute;
        width: 270px;
        bottom: 205px;
        left: 0px;
    }
    .success_sec img.success_graph_img {
        left: 0px;
        top: 125px;
    }
    .success_col19 {
        position: absolute;
        width: 260px;
        right: 30px;
        bottom: 20px;
    }
    .success_col20 {
        position: absolute;
        width: 270px;
        left: 140px;
        top: 260px;
    }
    .chose_coleft img.chs_leftbg {
        position: absolute;
        left: 0px;
        max-width: 100%;
        height: auto;
    }
    .off_coleft {
        margin: 60px 0px;
    }
    /**Why Choose Us Media Css Ends**/
    /**Events Page Media Css Starts**/
    .annual_colrt h2 {
        font-size: 50px;
    }
    .annual_colrt h2 span {
        font-size: 50px;
    }
    .annual_colrt h2:before {
        height: 190px;
    }
    .annual_colrt p {
        font-size: 14px;
        line-height: 26px;
    }
    .annual_colrt hr {
        margin-top: 20px;
        margin-bottom: 30px;
    }
    .eventleftsld a.carousel-control-prev {
        left: 67% !important;
    }
    /**Events Page Media Css Ends**/
    /**About Page Media Css Starts**/
    .about_focus h5 {
        font-size: 16px;
        line-height: 28px;
    }
    .about_focus {
        margin-top: 10px;
    }
    .about_leftext p {
        font-size: 14px;
        line-height: 24px;
    }
    .about_leftext h3 {
        font-size: 32px;
        line-height: 48px;
    }
    .about_leftext h2 {
        font-size: 48px;
    }
    .about_leftext span:before {
        top: 108px;
    }
    .about_leftext hr {
        margin-top: 15px;
        margin-bottom: 15px;
    }
    .col-lg-2.col-md-3.col-12.mt-5.abtcolpad {
        margin-top: 20px !important;
    }
    .col-lg-10.col-md-9.col-12.mt-5.d-flex.align-items-center.abtcolpadrt {
        margin-top: 20px !important;
        padding-left: 30px;
    }
    .abt_appicn_sec {
        width: 100px;
        height: 100px;
    }
    .misn_coleft {
        margin-top: 60px;
        margin-bottom: 80px;
    }
    /**About Page Media Css Ends**/
}

@media (min-width: 1201px) and (max-width: 1300px) {
    button.btn.btn-primary.btn-block {
        border-radius: 10px;
        margin-top: 15px;
    }
    .row.adres_sec.mt-4 {
        margin-top: 20px !important;
    }
    .form-sec p {
        margin-top: 10px;
    }
    .captcha {
        margin-top: 6px;
        margin-bottom: 0px;
    }
    /* .port_miapp {margin-top: 15px !important;margin-bottom: 70px !important;} */
    /* .slob_imgsec img.img-fluid.slob_bgraph {padding-top: 95px;}
  .events_slider a.carousel-control-next {left: 49% !important;width: 70px;}
  .square_imgsec img.img-fluid.square_fpic{padding-top: 60px;}
  .holla_imgsec img {margin-top: 12px;}
  .cow_imgsec {margin: 0px 0px;} */
    /* .evolution_imgsec img.evo_circle {top: 40px;left: -40px !important;width: 585px !important;}
  .evolution_imgsec img.evo_img {max-width: 94% !important;} */
    /* .bigtime_imgsec img.img-fluid.big_time_pic {max-width: 93% !important;padding-top: 10px;}
  .bigtime_imgsec img.img-fluid.bigt_graphics {padding-left: 36px !important;}
  img.img-fluid.record_bgraphics {width: 70% !important;}
  img.img-fluid.record_fpic {top: 22px;}
  .square_imgsec img.img-fluid.square_fpic {padding-top: 65px !important;}
  .you_ltpic img.img-fluid.youpicf {top: 50px;}
  .row.mia_rtrow img {max-width: 88%;position: absolute;right: 0px;}
  img.mapple_crcl {top: 50px;width: 600px;left: -30px;}
  .mapple_imgsec img.img-fluid {top: 150px;}
  .row.avera_imgsec img {max-width: 95% !important;}
  .svdo_ltpic img.img-fluid.svdo_bg {position: relative;height: 552px !important;}
  .htts_imgsec img {position: absolute;bottom: 80px;}
  .laundris_imgsec img {position: absolute;bottom: 45px;}
  .hannah_imgsec img.img-fluid.hannah_bg {position: relative;top: 12px;}
  .hannah_imgsec img.img-fluid.hannah_fpic {top: 50px;}
  .sweet_imgsec img {position: absolute;bottom: 60px;} */
    .choseapp_rt {
        padding-top: 56px !important;
        padding-bottom: 56px !important;
    }
    .chose_coleft img.img-fluid.wow.fadeInLeft {
        max-width: 80%;
        padding-top: 30px;
    }
    .chscol_txt h3 {
        font-size: 14px !important;
        line-height: 21px !important;
    }
    .chscol_txt p {
        font-size: 12px !important;
        line-height: 20px !important;
    }
}

@media (min-width: 1202px) and (max-width: 1366px) {
    .header_menu {
        padding: 10px 90px;
    }
    .header_menu .navbar ul.navbar-nav li a {
        font-size: 12px;
        margin: 0px 6px;
    }
    .header_menu .navbar ul.navbar-nav li a.active {
        font-size: 12px;
    }
    .chscol_txt h3 {
        font-size: 15px;
        line-height: 22px;
        margin-bottom: 0px;
    }
    .chscol_txt p {
        font-size: 13px;
        line-height: 21px;
    }
    .chs_appicn_sec {
        width: 70px;
        height: 70px;
    }
    .chs_appicn_sec img {
        margin: auto;
        width: 35px;
    }
    .choseapp_rt {
        padding-top: 70px;
        padding-bottom: 70px;
    }
    /* .port_miapp.port_laundris h2 img {width: 50px;}
  .port_miapp.htts_port h2 img {width: 40px;}
  .port_miapp.port_sweet h2 img {width: 50px;margin-top: -10px;}
  .port_miapp h3{font-size: 18px;}
  .svdo_ltpic img.img-fluid.svdopicf {top: 40px;width: 87%;}
  .svdo_ltpic img.img-fluid.svdo_bg {position: relative;height: 591px;}
  .row.avera_imgsec img {max-width: 88%;} */
    .off_coleft h2 {
        font-size: 42px;
        padding-left: 12px
    }
    .srvc_rtcoll p {
        font-size: 14px;
        line-height: 26px;
    }
    .srvc_rtcoll h5 {
        font-size: 18px;
    }
    .events_slider a.carousel-control-prev {
        left: 39%;
    }
    .typeapp-txt span i.fa-angle-right {
        padding-left: 10px;
        font-size: 21px;
    }
    .evol_coll {
        margin-top: 10px;
        margin-bottom: 15px;
    }
    /* .evolution_imgsec img.evo_img{top: 90px;max-width: 92%;} */
    /* .cow_imgsec img {margin-top: 80px;}
  .bigtime_imgsec img.img-fluid.big_time_pic {position: relative;max-width: 90%;}
.bigtime_imgsec img.img-fluid.bigt_graphics {padding-left: 55px;}
  h6.htech_stk {margin-bottom: 10px;margin-top: 10px;} */
    #space_sec {
        padding-right: 7%;
        padding-left: 7%;
    }
    .map_text {
        padding-left: 120px;
        padding-right: 120px;
    }
    span#djsant_img1 {
        right: 70px !important;
    }
    span#djsant_img2 {
        right: 33px !IMPORTANT;
        top: 193px;
    }
    span#djsant_img3 {
        right: 58px !important;
        top: 290px;
    }
    span#avera_ap3 {
        right: 72px;
        top: 295px;
    }
    span#avera_ap2 {
        right: 47px !important;
    }
    /* .evolution_imgsec img.evo_circle {position: absolute;top: 40px;left: -30px;width: 615px;} */
    span#avera_ap1 {
        right: 72px !important;
    }
    .slider_sec .carousel-indicators li {
        right: 60%;
    }
    /* .square_imgsec img.img-fluid.square_fpic {padding-top: 50px;} */
    img.img-fluid.record_bgraphics {
        width: 73%;
    }
    span#cbrgimg1 {
        top: 23px;
        right: 132px;
    }
    span#cbrgimg2 {
        top: 102px;
        right: 70px !important;
    }
    span#cbrgimg3 {
        top: 205px;
        right: 35px !important;
    }
    span#cbrgimg4 {
        top: 302px;
        right: 72px;
    }
    span#cbrgimg5 {
        top: 378px;
        right: 140px;
    }
    span#safe_img1 {
        right: 130px;
        top: 28px;
    }
    span#safe_img2 {
        right: 79px !important;
        top: 112px;
    }
    span#compete_img1 {
        top: 20px;
        right: 140px;
    }
    span#compete_img2 {
        top: 100px;
        right: 65px !important;
    }
    span#compete_img3 {
        top: 200px;
        right: 10px;
    }
    span#compete_img4 {
        top: 295px;
        right: 70px;
    }
    span#compete_img5 {
        top: 375px;
        right: 145px;
    }
    /* .ideal_imgsec img.img-fluid.ideal_fpic {top: 22px;}
.ideal_imgsec img.img-fluid.ideal_bg {top: 10px;} */
    /* .frontt_tech {height: auto;}
div#sweet_sec .port_sweet {padding-left: 15%;} */
    .slider_sec ol.carousel-indicators {
        left: 5px;
    }
    .alert.alert-success.mt-3.alrt_getquote p {
        margin-bottom: 0px;
        font-size: 12px;
    }
    /* div#laundris_sec .port_laundris {padding-left: 15%;}
#httssec .htts_port {padding-right: 0%;padding-left: 15%;} */
    .container-fluid.get_sec.career_getsec {
        padding-top: 70px;
    }
    .apps_iconimg img.img-fluid.mt-3.pl-3 {
        padding-left: 4px !important;
    }
    /* nav.navbar .navbar-brand {
        margin-left: 70px;
    } */
    .serve_box {
        min-height: 365px;
        max-height: 380px;
    }
    .footer_sec ul li a {
        font-size: 11px;
    }
    .social_col {
        padding-left: 0px !important;
    }
    button.btn.btnbacktotop {
        right: 60px;
    }
    .footer_sec span {
        font-size: 12px;
    }
    .all_right_foot h6 {
        font-size: 12px;
    }
    hr.adrs_brdr {
        margin-top: 5px;
        margin-bottom: 10px;
    }
    .get_sec p {
        margin-bottom: 10px;
    }
    .get_sec p.text-center {
        margin-bottom: 13px;
    }
    .form-check-inline:nth-child(3) {
        margin-top: 20px;
    }
    .row.fss {
        padding-left: 20px;
        margin-bottom: 20px;
        margin-top: -15px;
    }
    .get_sec hr {
        margin-top: 15px;
        margin-bottom: 7px;
    }
    .row.mt-3.rt_addrs_row {
        margin-top: 40px !important;
    }
    hr.row_addrs_hr {
        margin-top: 35px !important;
    }
    .getquotes textarea#comment {
        height: 100px;
    }
    .addrs-rt span p.adrs_line {
        padding-left: 30px;
        line-height: 20px;
        font-size: 13px;
        margin-bottom: 5px;
    }
    .addrs-rt span p.adrs_line br {
        display: none;
    }
    .addrs-rt p span {
        font-size: 13px;
    }
    img.hm_pic1_web {
        width: 860px;
        position: relative;
        top: 70px;
        left: -70px;
        z-index: 9999;
    }
    .test_icon {
        width: 120px;
        height: 120px;
    }
    .test_icon img {
        width: 45px;
    }
    .events_slider a.carousel-control-next {
        left: 47%;
        bottom: -70px;
    }
    .uix_icn {
        width: 170px;
        height: 170px;
    }
    .choose_btnlink::before {
        width: 400px;
    }
    .choose_btnlink::after {
        width: 400px;
    }
    /* .offce_pic {
        padding-left: 0px;
    } */
    /* .col-lg-7.coladrs {
        margin-left: 0px;
        padding-left: 0px;
    } */
    .offce_pic img {
        max-width: 100%;
    }
    .addrs-rt p {
        font-size: 13px;
    }
    .addrs-rt span {
        font-size: 13px;
    }
    .addrs-rt a span.see_map_span {
        font-size: 14px;
    }
    .addrs-rt h5 {
        font-size: 14px;
    }
    .about_focus h5 {
        font-size: 16px;
        line-height: 28px;
    }
    .about_focus {
        margin-top: 10px;
    }
    .about_leftext p {
        font-size: 14px;
        line-height: 24px;
    }
    .about_leftext h3 {
        font-size: 32px;
        line-height: 48px;
    }
    .about_leftext h2 {
        font-size: 48px;
    }
    .about_leftext span:before {
        top: 108px;
    }
    .about_leftext hr {
        margin-top: 15px;
        margin-bottom: 15px;
    }
    .col-lg-2.col-md-3.col-12.mt-5.abtcolpad {
        margin-top: 20px !important;
    }
    .col-lg-10.col-md-9.col-12.mt-5.d-flex.align-items-center.abtcolpadrt {
        margin-top: 20px !important;
        padding-left: 30px;
    }
    .abt_appicn_sec {
        width: 100px;
        height: 100px;
    }
    .misn_coleft {
        margin-top: 60px;
        margin-bottom: 80px;
    }
    .slide_ryt .hm_appbg {
        margin-top: 50px;
        max-width: 100%;
        height: auto;
        margin-left: 40px;
    }
    .hm_apc2 {
        position: absolute;
        top: 40px;
        left: 50px;
        width: 290px;
    }
    .hm_apc1 {
        position: absolute;
        right: 15px;
        top: 60px;
        z-index: 1;
        width: 290px;
    }
    .serve_box p {
        font-size: 14px;
        line-height: 26px;
    }
    /**Why Choose Us Media Css Starts**/
    .sucs_circle_dotted {
        width: 75px;
        height: 75px;
    }
    .sucs_bg_circle {
        width: 55px;
        height: 55px;
    }
    .sucs_bg_circle h6 {
        font-size: 16px;
    }
    .success_col18 {
        position: absolute;
        width: 270px;
        bottom: 160px;
        left: -25px;
    }
    .succs_txt p {
        font-size: 12px;
        line-height: 22px;
    }
    .success_sec img.success_graph_img {
        left: 0px;
        top: 125px;
    }
    .success_col19 {
        position: absolute;
        width: 250px;
        right: 5px;
        bottom: 5px;
    }
    .success_col20 {
        position: absolute;
        width: 270px;
        left: 90px;
        top: 280px;
    }
    .success_col19 .succs_txt p {
        left: 70px;
    }
    img.chs_down_arrow3 {
        position: absolute;
        left: 30px;
    }
    .chose_coleft img.chs_leftbg {
        position: absolute;
        left: 0px;
        max-width: 100%;
        height: auto;
    }
    .off_coleft {
        margin: 60px 0px;
    }
    /**Why Choose Us Media Css Ends**/
    /**DM media css starts**/
    .dm_icon_sec img.digital_icn {
        padding-top: 20px;
        padding-left: 15px;
        width: 70px;
    }
    .dm_icon_sec h4 {
        padding-top: 20px;
        font-size: 14px;
        padding-left: 5px;
        line-height: 21px;
        padding-right: 15px;
    }
    .dm_serve_text {
        padding: 0px 20px;
    }
    .dm_title h3 {
        font-size: 14px;
        padding-left: 10px;
    }
    .row.dm_title {
        top: 15px;
        left: 40px;
    }
    .dm_what_sec {
        width: 80px;
        height: 80px;
    }
    .dmcol_txt h3 {
        font-size: 15px;
        line-height: 26px;
    }
    .dmcol_txt p {
        font-size: 15px;
        line-height: 26px;
    }
    .dmcol_txt {
        padding-left: 20px;
    }
    .startgy_img_sec {
        width: 140px;
        height: 140px;
    }
    img.img-fluid.dm_arrow1 {
        top: 40%;
        left: 20%;
        max-width: calc(200px - 100px);
    }
    img.img-fluid.dm_arrow3 {
        right: 20%;
        top: 40%;
        max-width: calc(200px - 100px);
    }
    img.img-fluid.dm_arrow2 {
        left: 45%;
        top: 38%;
        max-width: calc(200px - 100px);
    }
    /**DM media css ends**/
    /**Portfolio media css starts**/
    /* img.pink_crcle {position: absolute;right: 0px;padding: 0px;width: 56%;}
.row.mia_rtrow {margin-top: 30px;} */
    .port_imgauto {
        padding: 50px 30px;
    }
    .port_miapp p {
        font-size: 14px;
        line-height: 24px;
    }
    .port_miapp {
        padding: 60px 0px;
    }
    div#portfolio_sec2_pr .port_miapp {
        padding-right: 13%;
    }
    div#portfolio_sec1_pl .port_miapp {
        padding-left: 16%;
    }
    .technology_logo_img {
        padding: 10px;
        height: 98px;
    }
    .technology_logo_img .tech-imgsec {
        height: 55px;
    }
    .technology_logo_img h6 {
        font-size: 10px;
    }
    .port_miapp h2 {
        margin-bottom: 12px;
        font-size: 32px;
        line-height: 45px;
    }
    .port_miapp h3 {
        margin-bottom: 20px;
        margin-top: 15px;
        font-size: 18px;
        line-height: 27px;
    }
    .appstore_coll img {
        position: relative;
        left: -18px;
    }
    /**Portfolio media css ends**/
    .side_techicn img {
        width: 50px;
    }
    .phn_apppic img {
        margin-left: 30px;
        margin-top: -10px;
    }
    span.java_img {
        position: absolute;
        top: 40px;
        right: 125px;
    }
    span.sf_ap.sft_img {
        right: 80px !important;
    }
    span.sft_img {
        position: absolute;
        top: 100px;
        right: 73px;
    }
    span.cde_img {
        position: absolute;
        top: 195px;
        right: 15px;
        font-size: 11px;
    }
    span.kotlin_ap {
        right: 45px !important;
    }
    span.ang_ap {
        right: 35px !important;
    }
    span.exp_ap {
        right: 35px !important;
    }
    span.tact_ap3 {
        right: 80px !important;
        top: 290px !important;
    }
    span.djsnt_ap {
        right: 58px !important;
    }
    span.mys_img {
        position: absolute;
        top: 280px;
        right: 65px;
    }
    span.php_img {
        position: absolute;
        top: 360px;
        right: 130px;
    }
    .navbar ul li a.active {
        font-size: 12px;
    }
    .abtbnr_sec .slid_bnr_sec h2 {
        font-size: 32px;
        line-height: 41px;
    }
    .abtbnr_sec .slid_bnr_sec h5 {
        font-size: 24px;
        line-height: 36px;
    }
    .slider_sec .carousel-caption h2 {
        font-size: 36px;
        line-height: 48px;
    }
    .slider_sec .carousel-indicators {
        margin-left: 18%;
    }
    .slider_sec .carousel-caption h5 {
        font-size: 24px;
        line-height: 36px;
    }
    .choose_dotted2 img {
        position: absolute;
        top: 22%;
        right: -25%;
        width: calc(100% - 200px);
    }
    .choose_dotted img {
        position: absolute;
        top: 24%;
        right: -25%;
        width: calc(100% - 200px);
    }
    .form-group.form_select {
        margin-top: 60px;
    }

    .what_partnershp img {
        max-width: 100%;
        padding-top: 30px;
    }
    .whatwe_do button.btn.btn-primary {
        margin-bottom: 50px;
    }
    #vdo_slide a span i.fa-arrow-right {
        position: absolute;
        left: 170px;
    }
    #vdo_slide a span i.fa-arrow-left {
        position: absolute;
        left: -25px;
    }
    #app_carousel a span i.fa-arrow-right {
        position: absolute;
        left: 170px;
    }
    .apps_slider_sec a.carousel-control-prev span {
        width: 50px;
        height: 50px;
        border-radius: 50%;
    }
    .apps_slider_sec a.carousel-control-next span {
        width: 50px;
        height: 50px;
        border-radius: 50%;
    }
    .apps_slider_sec a.carousel-control-prev {
        position: absolute;
        left: -70px;
    }
    .apps_slider_sec a.carousel-control-next {
        position: absolute;
        right: -70px;
    }
    /**Service media css starts**/
    img.srvc_icn1,
    img.srvc_icn2,
    img.srvc_icn3 {
        padding: 60px;
    }
    .serve_hd h2 {
        font-size: 36px;
    }
    .srvc_imgicon img {
        margin: auto;
        display: table;
        width: 35px;
    }
    .srvc_ltcol img {
        margin-top: 90px;
    }
    .srvc_imgicon {
        width: 80px;
        height: 80px;
        border: 2px dotted #003D70;
        border-radius: 50%;
    }
    .srvc_rtcoll h5 {
        margin-bottom: 5px;
    }
    .chs_srvc2 {
        position: absolute;
        top: 70px;
        left: -40px;
        z-index: -1;
    }
    .chs_srvc2 span {
        right: 30px;
    }
    .chs_srvc2 p.chs_lineicn img {
        position: absolute;
        top: 100px;
        left: 5px;
    }
    .chs_srvc2 h4 {
        margin-left: 70px;
    }
    .chs_srvc2 p {
        margin-left: 70px;
    }
    .choose_srve_icn {
        border-radius: 50%;
        border: 2px solid #1EB8E2;
        width: 180px;
        height: 180px;
        display: flex;
        background: white;
    }
    /**Service media css ends**/
    /**Career Page media css starts**/
    .career_coll h4 {
        font-size: 18px;
        line-height: 27px;
    }
    .coll-leftbg {
        padding: 25px 0px;
    }
    /**Career Page media css ends**/
    /**WEB DEVELOPMENT Media Css Starts**/
    .framework-rtsec hr {
        margin-bottom: 20px;
    }
    .hybrid-app-icon img {
        width: 100px;
    }
    .hybrid-msec p {
        font-size: 16px;
        line-height: 28px;
    }
    .hybrid-msec {
        padding: 20px 25px;
        height: auto;
    }
    .text_txt h3 {
        font-size: 18px;
    }
    .test_coll {
        min-height: 450px;
        max-height: 460px;
    }
    .dig_serve_sec {
        min-height: 340px;
        max-height: 370px;
    }
    .framework-rtsec p {
        font-size: 16px;
        line-height: 28px;
    }
    .hybrid-msec h4 {
        margin: 12px 0px;
        font-weight: 500;
    }
    .frontend_ltcoll h2 {
        font-size: 30px;
    }
    .front_colltxt {
        padding-left: 40px !important;
    }
    .frontend_ltcoll p {
        font-size: 14px;
        line-height: 24px;
    }
    .frontt_tech h6 {
        font-size: 14px;
    }
    /**WEB DEVELOPMENT Media Css ends**/
    /**Events Page Media Css Ends**/
    .annual_colrt h2 {
        font-size: 42px;
    }
    .annual_colrt h2 span {
        font-size: 45px;
    }
    .annual_colrt h2:before {
        height: 170px;
        width: 100px;
    }
    .annual_colrt p {
        font-size: 12px;
        line-height: 22px;
        font-weight: 500 !important;
    }
    .annual_colrt hr {
        margin-top: 20px;
        margin-bottom: 30px;
    }
    .eventleftsld a.carousel-control-prev {
        left: 63% !important;
    }
    /**Events Page Media Css Ends**/
}

@media (min-width: 992px) and (max-width: 1199.98px) {
    .header_menu .navbar a.navbar-brand img {
        width: 120px;
    }
    .header_menu {
        padding: 10px 65px;
    }
    .header_menu .navbar ul.navbar-nav li a {
        font-size: 10px;
        margin: 0px 2px;
    }
    .header_menu .navbar ul.navbar-nav li a.active {
        font-size: 10px;
    }
    .get_sec p.platform_para {
        margin-bottom: 0px;
    }
    button.btn.btn-primary.btn-block {
        border-radius: 10px;
        margin-top: 15px;
    }
    .row.adres_sec.mt-4 {
        margin-top: 40px !important;
    }
    .form-sec p {
        margin-top: 10px;
        font-size: 14px;
    }
    .captcha {
        margin-top: 6px;
        margin-bottom: 0px;
    }
    .slider_sec .carousel-indicators li {
        right: 59%;
    }
    span#cbrgimg1 {
        top: 20px;
        right: 90px;
    }
    span#cbrgimg2 {
        top: 78px;
        right: 51px !important;
    }
    span#cbrgimg3 {
        top: 160px;
        right: 15px !important;
    }
    span#cbrgimg4 {
        top: 240px;
        right: 46px;
    }
    span#cbrgimg5 {
        top: 300px;
        right: 95px;
    }
    span#safe_img1 {
        right: 95px;
        top: 25px;
    }
    span#safe_img2 {
        right: 60px !important;
        top: 90px;
    }
    span#compete_img1 {
        top: 20px;
        right: 110px;
    }
    span#compete_img2 {
        top: 83px;
        right: 50px !important;
    }
    span#compete_img3 {
        top: 160px;
        right: -15px;
    }
    span#compete_img4 {
        top: 237px;
        right: 47px;
    }
    span#compete_img5 {
        top: 296px;
        right: 115px;
    }
    .you_ltpic img.img-fluid.you_bg {
        height: 460px;
    }
    .you_ltpic img.img-fluid.youpicf {
        position: absolute;
        top: 55px;
    }
    .alert.alert-primary.mt-3.apply_alrt p {
        font-size: 13px;
    }
    .container-fluid.get_sec.career_getsec {
        padding-top: 60px;
    }
    .apps_iconimg img.img-fluid.mt-3.pl-3 {
        padding-left: 0px !important;
    }
    .map_text {
        left: -35px;
    }
    .slider_sec ol.carousel-indicators {
        left: 35px;
    }
    .banr_ctxt button.btn.btn-primary {
        margin-top: 20px;
    }
    .check_icon {
        width: 40px;
        height: 40px;
        border-radius: 50%;
    }
    .getquotes label {
        font-size: 12px;
    }
    .getquotes label.custom-control-label {
        padding-top: 10px;
        padding-left: 25px;
    }
    .check_icon span i.fa.fa-android {
        font-size: 20px;
    }
    .check_icon span i.fa.fa-apple {
        font-size: 20px;
    }
    .check_icon span i.fab.fa-react {
        font-size: 20px;
    }
    .check_icon span i.fas.fa-globe {
        font-size: 20px;
    }
    .getquotes .custom-control-label::before {
        top: .25rem;
        left: -27px;
        display: block;
        width: 13px;
        height: 13px;
        pointer-events: none;
        content: "";
        background-color: #F2F3F8;
        border: #003B72 solid 2px;
        border-radius: 20px !important;
    }
    .partnership_col img.agora_pimg {
        width: 240px;
        height: auto;
    }
    img.hm_pic1_web {
        width: 680px;
        position: relative;
        top: 70px;
        left: -70px;
        z-index: 9999;
    }
    .test_icon {
        width: 100px;
        height: 100px;
    }
    .test_icon img {
        width: 35px;
    }
    .testbg {
        height: 100px;
    }
    .text_txt h3 {
        font-size: 16px;
        line-height: 24px;
    }
    .text_txt p {
        font-size: 15px;
        line-height: 24px;
    }
    .qa_test_sec {
        width: 80px;
        height: 80px;
    }
    .qa_test_sec img {
        width: 40px;
    }
    .qacol_text h3 {
        font-size: 16px;
        line-height: 24px;
    }
    .qacol_text p {
        font-size: 15px;
        line-height: 24px;
    }
    .events_slider a.carousel-control-next {
        left: 49%;
        bottom: -70px;
    }
    .eventslide span.carousel-control-next-icon {
        padding-left: 3px !important;
    }
    .uix_icn {
        width: 150px;
        height: 150px;
    }
    .uix_icn img {
        width: 60px;
    }
    .uix_box h3 {
        font-size: 16px;
        margin-top: 20px;
    }
    .uiux_serv_sec p {
        font-size: 14px;
        line-height: 24px;
    }
    .uix_box p {
        font-size: 14px;
        line-height: 24px;
    }
    .benefit_textcol p {
        font-size: 14px;
        line-height: 24px;
    }
    .uix_iconn:before {
        content: '';
        position: absolute;
        border: 2px dashed #00B8E8;
        width: 110px;
        height: 110px;
        border-radius: 50%;
        left: -10px;
    }
    .uix_iconn img {
        width: 35px;
    }
    .uix_iconn {
        width: 90px;
        height: 90px;
        top: 25px;
        left: 28px;
    }
    .uixsemi_bordr {
        border-radius: 50%;
        width: 140px;
        height: 140px;
    }
    .col-lg-4.colleft_icon {
        padding-left: 0px;
    }
    img.benfits_dot {
        position: absolute;
        left: -12px;
        top: 75px;
        width: 38px;
    }
    .footer_sec ul li a {
        font-size: 10px;
    }
    .col-lg-2.col-md-6.col-12.social_col {
        padding-left: 2px;
    }
    .contc_us ul li i.fa {
        font-size: 10px;
    }
    .contc_us ul li i.fa-mobile {
        font-size: 15px;
    }
    .contc_us ul li i.fa {
        width: 8px;
    }
    .footer_sec span {
        font-size: 12px;
        line-height: 21px;
    }
    .col-lg-10.col-md-9.col-12.mt-5.d-flex.align-items-center.abtcolpadrt {
        margin-top: 10px !important;
    }
    .col-lg-2.col-md-3.col-12.mt-5.abtcolpad {
        margin-top: 10px !important;
    }
    .uix_apptext {
        margin-top: 60px;
    }
    .uix_apptext h2 {
        font-size: 24px;
    }
    .uix_apptext h3 {
        font-size: 28px;
    }
    .uix_apptext p {
        font-size: 14px;
        line-height: 24px;
    }
    .uix_apptext button.btn.btn-primary {
        margin-top: 10px !important;
    }
    .container-fluid.uix_appsec {
        margin-bottom: 50px;
    }
    .benefit_textcol h5 {
        font-size: 16px;
        font-weight: 500;
    }
    .choose_btnlink::before {
        width: 300px;
    }
    .choose_btnlink::after {
        width: 300px;
    }
    /* .offce_pic {
        padding-left: 0px;
    } */
    /* .col-lg-7.coladrs {
        margin-left: 0px;
        padding-left: 0px;
    } */
    .offce_pic img {
        max-width: 100%;
    }
    .addrs-rt p {
        font-size: 10px !important;
        line-height: 16px;
        margin-bottom: 0px;
    }
    hr.adrs_brdr {
        margin-top: 15px;
        margin-bottom: 14px;
    }
    hr.row_addrs_hr {
        margin-left: 15px;
        margin-top: 40px;
        margin-bottom: 20px;
    }
    img.uxapp_pic.img-fluid {
        top: 80px;
    }
    .addrs-rt span {
        font-size: 10px !important;
        line-height: 16px;
    }
    img.adrs_iconimg {
        padding-top: 0px;
        width: 15px;
    }
    .form-check-inline:nth-child(3) {
        margin-top: 20px;
    }
    p.adrs_line br {
        display: none;
    }
    .addrs-rt a span.see_map_span {
        font-size: 12px;
    }
    .addrs-rt h5 {
        font-size: 12px;
    }
    /**Events Page Media Css Ends**/
    .annual_colrt h2 {
        font-size: 34px;
        padding-top: 15px;
        padding-bottom: 15px;
    }
    .annual_colrt h2 span {
        font-size: 36px;
    }
    .annual_colrt h2:before {
        height: 120px;
        width: 70px;
    }
    .annual_colrt p {
        font-size: 11px;
        line-height: 18px;
        font-weight: 500 !important;
        margin-top: 15px;
    }
    .eventleftsld a.carousel-control-prev {
        left: 56% !important;
    }
    .annual_colrt hr {
        display: none;
    }
    /**Events Page Media Css Ends**/
    /**Why Choose Us Media Css Starts**/
    .sucs_circle_dotted {
        width: 55px;
        height: 55px;
    }
    .sucs_bg_circle {
        width: 45px;
        height: 45px;
    }
    .sucs_bg_circle h6 {
        font-size: 14px;
    }
    .success_col18 {
        position: absolute;
        width: 240px;
        bottom: 200px;
        left: -45px;
    }
    .succs_txt p {
        font-size: 11px;
        line-height: 18px;
    }
    .success_sec img.success_graph_img {
        left: 0px;
        top: 10px;
    }
    .success_col19 {
        position: absolute;
        width: 240px;
        right: -45px;
        bottom: 90px;
    }
    .success_col20 {
        position: absolute;
        width: 250px;
        left: 35px;
        top: 165px;
    }
    .success_col19 .succs_txt p {
        left: 40px;
    }
    img.chs_down_arrow3 {
        position: absolute;
        left: 18px;
        width: 35px;
        padding-top: 5px;
    }
    img.chs_down_arrow2 {
        position: absolute;
        top: 30px;
        right: -25px;
        width: 35px;
    }
    img.chs_down_arrow {
        position: absolute;
        top: 30px;
        right: -25px;
        width: 35px;
    }
    .chose_coleft img.chs_leftbg {
        position: absolute;
        left: 0px;
        max-width: 100%;
        height: auto;
    }
    .off_coleft {
        margin: 40px 0px;
    }
    .chs_appicn_sec {
        width: 80px;
        height: 80px;
    }
    .chs_appicn_sec img {
        margin: auto;
        width: 35px;
    }
    .cleintsec h4 {
        display: none;
    }
    /**Why Choose Us Media Css Ends**/
    /**Career Modal Page media css starts**/
    .career_coll h4 {
        font-size: 15px;
        line-height: 22px;
    }
    .modal_left_col h2 {
        font-size: 16px;
    }
    .coll-leftbg {
        padding: 20px 0px;
    }
    .career_icnbg img {
        width: 30px;
    }
    .modal-content {
        width: 930px !important;
        height: auto;
        margin: auto;
        padding: 45px 70px !important;
        position: relative;
    }
    /**Career Modal Page media css starts**/
    /**Service media css starts**/
    .serve_hd h2 {
        font-size: 36px;
    }
    .srvc_imgicon img {
        margin: auto;
        display: table;
        width: 35px;
    }
    .srvc_imgicon {
        width: 80px;
        height: 80px;
        border: 2px dotted #003D70;
        border-radius: 50%;
    }
    .srvc_rtcoll h5 {
        margin-bottom: 5px;
    }
    .chs_srvc2 {
        position: absolute;
        top: 70px;
        left: -40px;
        z-index: -1;
    }
    .chs_srvc2 span {
        right: 0px;
    }
    .choose_srve_icn img {
        margin: auto;
        display: flex;
        width: 60px;
    }
    .chs_srvc2 p.chs_lineicn img {
        position: absolute;
        top: 100px;
        left: 5px;
    }
    .chs_srvc2 h4 {
        margin-left: 70px;
    }
    .chs_srvc2 p {
        margin-left: 70px;
    }
    .choose_srve_icn {
        border-radius: 50%;
        border: 2px solid #1EB8E2;
        width: 150px;
        height: 150px;
        display: flex;
        background: white;
    }
    .srvc_ltcol img {
        margin-top: 60px;
    }
    .srt_colltxt {
        padding-left: 30px !important;
    }
    .srvc_rtcoll p {
        font-size: 14px;
        line-height: 24px;
    }
    .srvc_rtcoll h5 {
        font-size: 18px;
        line-height: 27px;
    }
    .srvc_rtcoll h2 {
        font-size: 28px;
    }
    .srvchoose_sec h2 {
        font-size: 28px;
    }
    .srvc_rtcoll {
        padding: 60px 0px;
    }
    .srvchoose_sec h4 {
        font-size: 18px;
        line-height: 27px;
    }
    .servc_coll h4 {
        font-size: 18px;
        line-height: 27px;
    }
    /**Service media css ends**/
    /**DM media css starts**/
    .dig_serve_sec h3 {
        font-size: 18px;
        line-height: 27px;
    }
    .dm_icon_sec img.digital_icn {
        padding-top: 10px;
        padding-left: 15px;
        width: 50px;
    }
    .dm_icon_sec h4 {
        padding-top: 10px;
        font-size: 13px;
        padding-left: 0px;
        line-height: 18px;
        padding-right: 15px;
    }
    .dm_title h3 {
        font-size: 12px;
        padding-left: 8px;
    }
    .dig_serve_sec {
        min-height: 300px;
        max-height: 330px;
    }
    .dig_serve_sec p {
        font-size: 14px;
        line-height: 24px;
    }
    .row.dm_title {
        top: 15px;
        left: 40px;
    }
    .dm_what_sec {
        width: 80px;
        height: 80px;
    }
    .dmcol_txt h3 {
        font-size: 15px;
        line-height: 26px;
    }
    .dmcol_txt p {
        font-size: 15px;
        line-height: 26px;
    }
    .dmcol_txt {
        padding-left: 20px;
    }
    img.img-fluid.icon_dm {
        max-width: 30px;
        height: 30px;
    }
    .startgy_img_sec {
        width: 120px;
        height: 120px;
    }
    img.img-fluid.dm_arrow1 {
        top: 40%;
        left: 20%;
        max-width: calc(185px - 100px);
    }
    img.img-fluid.dm_arrow3 {
        right: 20%;
        top: 40%;
        max-width: calc(185px - 100px);
    }
    img.img-fluid.dm_arrow2 {
        left: 45%;
        top: 38%;
        max-width: calc(185px - 100px);
    }
    .startgy_img_sec img {
        width: 50px;
    }
    .startgy_col h3 {
        font-size: 16px;
        line-height: 24px;
    }
    /**DM media css ends**/
    .slide_ryt .hm_appbg {
        margin-top: 40px;
        max-width: 100%;
        height: auto;
        margin-left: 30px;
    }
    .slider_sec .carousel-caption p {
        font-size: 14px;
        line-height: 21px;
        padding-left: 15px;
        margin-top: 15px;
    }
    .hm_apc2 {
        position: absolute;
        top: 40px;
        left: 50px;
        width: 240px;
    }
    .hm_apc1 {
        position: absolute;
        right: 15px;
        top: 60px;
        z-index: 1;
        width: 240px;
    }
    .serve_icn {
        position: relative;
        width: 120px;
        height: 120px;
        border-radius: 50%;
        margin: auto;
        top: 65px;
        border: 7px solid #F2F3F8;
        margin-top: -30px;
    }
    .serve_icn img {
        margin: auto;
        display: table;
        margin-top: 30px;
        width: 45px;
    }
    .phn_apppic img {
        margin-left: 35px;
        margin-top: 0px;
    }
    .side_techicn img {
        width: 45px;
    }
    span.java_img {
        position: absolute;
        top: 30px;
        right: 90px;
    }
    span.sft_img {
        position: absolute;
        top: 90px;
        right: 47px;
    }
    span.sf_ap {
        right: 50px;
    }
    span.exp_ap {
        right: 15px !important;
    }
    span.cde_img {
        position: absolute;
        top: 165px;
        right: -10px;
    }
    span.kotlin_ap {
        right: 25px !important;
    }
    span.ang_ap {
        right: 10px !important;
        top: 161px;
    }
    span.mys_img {
        position: absolute;
        top: 233px;
        right: 40px;
    }
    span#avera_ap3 {
        top: 242px;
        right: 52px;
    }
    span#avera_ap1 {
        top: 85px;
        right: 50px;
    }
    span.djsnt_ap {
        right: 36px;
    }
    span.tact_ap3 {
        right: 58px;
        top: 240px;
    }
    span.php_img {
        position: absolute;
        top: 295px;
        right: 95px;
    }
    .slider_sec .carousel-caption h2 {
        font-size: 30px;
        line-height: 45px;
    }
    .slider_sec .carousel-caption h5 {
        font-size: 22px;
        line-height: 33px;
    }
    .abtbnr_sec .slid_bnr_sec h5 {
        font-size: 22px;
        line-height: 33px;
    }
    .abtbnr_sec .slid_bnr_sec h2 {
        font-size: 28px;
        line-height: 42px;
    }
    .abtbanr_text {
        margin-top: 40px;
    }
    .about_leftext span:before {
        top: 52px;
    }
    .about_leftext h2 {
        font-size: 48px;
    }
    .about_leftext h3 {
        font-size: 24px;
        line-height: 36px;
    }
    .about_leftext h3 br {
        display: none;
    }
    .about_leftext hr {
        margin-top: 15px;
        margin-bottom: 15px;
    }
    .about_leftext p {
        font-size: 14px;
        line-height: 26px;
    }
    .about_app_border {
        width: 80px;
        height: 80px;
    }
    .about_app_border img {
        width: 36px;
    }
    .about_focus {
        margin-top: 10px;
    }
    .abt_appicn_sec {
        width: 75px;
        height: 75px;
    }
    .abt_appicn_sec img {
        width: 32px;
    }
    .misn_coleft {
        margin-top: 50px;
        margin-bottom: 70px;
    }
    .misn_sec p {
        font-size: 14px;
        line-height: 24px;
    }
    .choose_dotted2 img {
        position: absolute;
        top: 19%;
        right: -23%;
        width: calc(100% - 160px);
    }
    .choose_dotted img {
        position: absolute;
        top: 19%;
        right: -23%;
        width: calc(100% - 160px);
    }
    /* nav.navbar .navbar-brand {
        margin-left: 45px;
    } */
    nav.navbar .navbar-brand img {
        width: 140px;
        padding: 0px;
    }
    ul li.nav-item a img {
        width: 40px;
    }
    ul li a button.btn.btn-primary {
        font-size: 12px;
        padding: 9px 18px;
    }
    .form-group.form_select {
        margin-top: 60px;
    }

    .what_partnershp img {
        max-width: 100%;
        padding-top: 30px;
    }
    .whatwe_do button.btn.btn-primary {
        margin-bottom: 50px;
    }
    .whatwe_do p {
        color: #666666;
        font-size: 16px;
        line-height: 24px;
        font-family: 'Montserrat';
        font-weight: 300;
    }
    #vdo_slide a span i.fa-arrow-right {
        position: absolute;
        left: 110px;
    }
    #vdo_slide a span i.fa-arrow-left {
        position: absolute;
        left: -5px;
    }

    .cleint_vdo.row {
        padding-right: 15px;
        padding-left: 10px;
    }
    #app_carousel a span i.fa-arrow-right {
        position: absolute;
        left: 100px;
        font-size: 18px;
        width: 30px;
        height: 30px;
        background-color: #043E6E;
        border-radius: 50%;
        padding-top: 5px;
    }
    #app_carousel a span i.fa-arrow-left {
        position: absolute;
        left: -10px;
        font-size: 18px;
        width: 30px;
        height: 30px;
        background-color: #043E6E;
        border-radius: 50%;
        padding-top: 5px;
    }
    .apps_slide_txt {
        padding-left: 15px;
    }
    .serve_box {
        background-color: #fff;
        padding: 20px 15px;
        min-height: 410px;
        max-height: 430px;
    }
    .serve_box button.btn.btn-primary {
        position: absolute;
        bottom: 30px;
        margin: auto;
        display: table;
        text-align: center;
        margin-left: 50px;
        margin-bottom: 0px;
    }
    .serve_box h4 {
        font-size: 18px;
        line-height: 30px;
        margin-top: 60px;
    }
    .typeapp-txt h6 {
        font-size: 16px;
    }
    .typeapp-txt span i.fa-angle-right {
        width: 20px;
        height: 20px;
        border-radius: 50%;
        color: #fff;
        font-size: 17px;
        display: flex;
        float: right;
        padding-left: 7px;
    }
    /* .blog_icn span i.fa {color: #1EB8E2;margin: 0px 1px;}
.blog_icn span {color: #BEBEBE;margin-left: 0px;}
.blog_txt_time h5 {color: #1D1D1D;font-size: 10px;line-height: 18px;}
.blog_txt_time p {font-size: 12px;line-height: 18px;}
.blog_icn i.fa.fa-star {color: #FFCF03;font-size: 6px;} */
    .off_coleft h2 {
        font-size: 30px;
        padding-left: 12px;
        margin-top: 10px;
    }
    .off_coleft p {
        font-size: 14px;
        line-height: 26px;
        margin-top: 10px;
        margin-bottom: 20px;
    }
    .apps_slider_sec a.carousel-control-prev span {
        width: 50px;
        height: 50px;
        border-radius: 50%;
    }
    .apps_slider_sec a.carousel-control-next span {
        width: 50px;
        height: 50px;
        border-radius: 50%;
    }
    .apps_slider_sec a.carousel-control-prev {
        position: absolute;
        left: -50px;
    }
    .apps_slider_sec a.carousel-control-next {
        position: absolute;
        right: -50px;
    }
    /**Portfolio media css starts**/
    /* div#mialsec .port_miapp {margin-top: 0px;margin-bottom: 50px;}
div#mapplesec .port_miapp {margin-top: 0px;margin-bottom: 50px;}
div#hollasec .port_miapp {margin-top: 0px;margin-bottom: 50px;}
div#averasec .port_miapp {margin-top: 0px;margin-bottom: 50px;}
.port_miapp.port_evou {margin-top: 0px;margin-bottom: 50px;} */
    /* .evolution_imgsec img.evo_img {z-index: 1;position: relative;top: 45px;}
.evolution_imgsec img.evo_circle {position: absolute;top: 0px;left: -25px;width: 480px;} */
    /* .port_miapp.port_cow {margin-top: 0px;margin-bottom: 60px;}
.port_miapp.bigt_cow {margin-top: 0px;margin-bottom: 60px;}
.port_miapp.wig_sec_port {margin-top: 0px;margin-bottom: 60px;} */
    /* .wig_imgsec img.img-fluid.wig_bgraphic {position: absolute;left: 0px;bottom: 32px;z-index: -1;top: unset;}
.wig_imgsec img.img-fluid.wigfpic {position: relative;top: 0px;} */
    /* .port_miapp.record_cow {margin-top: 0px;margin-bottom: 50px;}
.port_miapp.port_square {margin-top: 0px;margin-bottom: 50px;}
.port_miapp.you_port {margin-top: 0px;margin-bottom: 50px;}
.port_miapp.port_slob {margin-top: 0px;margin-bottom: 50px;}
.port_miapp.svdo_port {margin-top: 0px;margin-bottom: 50px;}
.port_miapp {margin-top: 10px;margin-bottom: 60px;} */
    /* .mapple_imgsec img.img-fluid {position: absolute;top: 120px;max-width: 100%;left: 0px;}
.mapple_imgsec img.mapple_crcl {position: absolute;top: 35px;left: -25px;width: 490px;}
.row.avera_imgsec img {max-width: 92%;}
.svdo_ltpic img.img-fluid.svdo_bg {position: relative;max-width: 100%;height: 443px;} */
    .port_imgauto {
        padding: 50px 30px;
    }
    .port_miapp p {
        font-size: 12px;
        line-height: 22px;
    }
    .port_miapp {
        padding: 60px 0px;
    }
    div#portfolio_sec2_pr .port_miapp {
        padding-right: 13%;
    }
    div#portfolio_sec1_pl .port_miapp {
        padding-left: 16%;
    }
    .technology_logo_img img {
        width: 25px;
    }
    .technology_logo_img {
        padding: 8px;
        height: 80px;
    }
    .technology_logo_img .tech-imgsec {
        height: 38px;
    }
    .technology_logo_img h6 {
        font-size: 8px;
    }
    .port_miapp h2 {
        margin-bottom: 12px;
        font-size: 28px;
        line-height: 40px;
    }
    .port_miapp h3 {
        margin-bottom: 10px;
        margin-top: 15px;
        font-size: 14px;
        line-height: 24px;
    }
    .appstore_coll img {
        position: relative;
        left: -23px;
    }
    /**Portfolio media css ends**/
    /**WEB DEVELOPMENT Media Css Starts**/
    .framework-rtsec hr {
        margin-bottom: 20px;
    }
    .hybrid-app-icon img {
        width: 70px;
    }
    .hybrid-msec p {
        font-size: 14px;
        line-height: 24px;
    }
    .hybrid-msec {
        padding: 20px 25px;
        height: auto;
    }
    .framework-rtsec p {
        font-size: 16px;
        line-height: 28px;
    }
    .hybrid-msec h4 {
        margin: 10px 0px;
        font-weight: 500;
        font-size: 18px;
    }
    .framework-logo {
        padding: 10px 25px;
    }
    .frontend_ltcoll h2 {
        font-size: 24px;
    }
    .front_colltxt {
        padding-left: 30px !important;
    }
    .frontend_ltcoll p {
        font-size: 14px;
        line-height: 24px;
    }
    .webdev-tech {
        padding: 20px 20px;
    }
    .framework-logo h5 {
        font-size: 24px;
        padding-left: 20px;
    }
    .front-end-colleft img {
        bottom: 70px;
    }
    .frontt_tech {
        height: auto;
        padding: 20px 10px;
    }
    .frontt_tech h6 {
        font-size: 12px;
    }
    .front_techimg {
        width: 70px;
        height: 70px;
    }
    .front_techimg img {
        width: 30px;
    }
    /**WEB DEVELOPMENT Media Css ends**/
    .test_coll {
        min-height: 470px;
        max-height: 500px;
    }
    .alert.alert-success.mt-3.alrt_getquote {
        padding: 9px 20px;
        bottom: 20px;
        right: 35px;
        width: 60%;
    }
    .alert.alert-success.mt-3.alrt_getquote p {
        font-size: 12px;
        line-height: 18px;
    }
}

@media (max-width: 991.98px) {
    .navbar ul li a.active_navbar::after {
        display: none;
    }
    div#collapsibleNavbar {
        position: unset;
        right: 0;
    }
    .header_menu .navbar a.navbar-brand img {
        width: 120px;
    }
    .header_menu .navbar ul.navbar-nav {
        align-items: flex-start;
    }
}

@media (min-width: 768px) and (max-width: 991.98px) {
    .header_menu .navbar ul.navbar-nav li a {
        margin: 8px 2px;
    }
    .header_menu {
        padding: 10px 15px;
    }
    .appstore_coll img {
        position: relative;
        left: -25px;
    }
    #space_sec {
        padding-right: 5%;
        padding-left: 5%;
    }
    .col-lg-7.pl-5.form-col {
        padding-left: 0px !important;
    }

    .slider_sec .carousel-indicators li {
        right: 60%;
    }
    span#cbrgimg1 {
        top: 25px;
        right: 160px;
    }
    span#cbrgimg2 {
        top: 125px;
        right: 82px !important;
    }
    span#cbrgimg3 {
        top: 250px;
        right: 35px !important;
    }
    span#cbrgimg4 {
        top: 381px;
        right: 77px;
    }
    span#cbrgimg5 {
        top: 484px;
        right: 168px;
    }
    span#safe_img1 {
        right: 155px;
        top: 37px;
    }
    span#safe_img2 {
        right: 93px !important;
        top: 130px;
    }
    span#compete_img1 {
        top: 30px;
        right: 175px;
    }
    span#compete_img2 {
        top: 125px;
        right: 85px !important;
    }
    span#compete_img3 {
        top: 250px;
        right: 6px;
    }
    span#compete_img4 {
        top: 375px;
        right: 76px;
    }
    span#compete_img5 {
        top: 480px;
        right: 180px;
    }
    span#tact_im1 {
        top: 135px;
        right: 80px;
    }
    span#tact_im2 {
        top: 256px;
        right: 12px;
    }
    span#tact_im3 {
        top: 380px;
        right: 90px;
    }
    span#djsant_img1 {
        top: 135px;
        right: 78px;
    }
    span#djsant_img3 {
        right: 70px;
        top: 380px;
    }
    span#djsant_img2 {
        right: 32px;
        top: 260px;
    }
    span#avera_ap1 {
        top: 135px;
        right: 75px;
    }
    span#avera_ap3 {
        top: 365px;
        right: 75px;
    }
    span#avera_ap2 {
        top: 252px;
        right: 49px !important;
    }
    .phn_apppic img {
        margin-left: 70px;
    }
    .you_ltpic img.img-fluid.you_bg {
        height: 410px;
        top: 50px;
    }
    .you_ltpic img.img-fluid.youpicf {
        position: absolute;
        top: 140px;
        max-width: 100%;
    }
    .col-lg-6.col-md-12.col-12.input-col.mb-4.colapply_select {
        padding-left: 0px !important;
    }
    .alert.alert-primary.mt-3.apply_alrt {
        width: 75%;
        padding: 10px 20px;
    }
    .alert.alert-primary.mt-3.apply_alrt p {
        font-size: 11px;
    }
    .alert.alert-success.mt-3.alrt_getquote p {
        margin-bottom: 0px;
        font-size: 12px;
    }
    .container-fluid.get_sec.career_getsec {
        padding-top: 60px;
    }
    .map_text {
        padding-left: 52px;
        padding-right: 52px;
    }
    .srt_colltxt {
        padding-left: 40px !important;
    }
    .col-lg-3.col-md-6.form-check-inline {
        margin-top: 30px;
    }

    .partnership_col img.agora_pimg {
        width: 245px;
        height: auto;
    }
    img.hm_pic1_web {
        width: 480px;
        position: relative;
        top: 70px;
        left: -50px;
        z-index: 9999;
    }
    .qacol_text {
        padding-left: 20px;
    }
    .container-fluid.abt_hd.abt_misn {
        padding-left: 50px;
        padding-right: 50px;
    }
    .misn_coleft {
        margin-top: 50px;
    }
    .events_coleft button.btn.btn-primary {
        margin-bottom: 40px;
    }
    .events_slider a.carousel-control-next {
        left: 46%;
    }
    .events_slider a.carousel-control-prev {
        left: 38%;
    }
    .about_leftext h2 {
        font-size: 56px;
    }
    .about_leftext h3 {
        font-size: 36px;
        line-height: 52px;
    }
    .about_leftext span:before {
        height: 42px;
        top: 126px;
    }
    .about_rtcol {
        margin-top: 30px;
    }
    .about_rtcol img {
        margin: auto;
        display: table;
    }
    .uix_icn {
        width: 100px;
        height: 100px;
    }
    .uix_icn img {
        width: 40px;
    }
    img.benfits_dot {
        display: none;
    }
    .col-lg-6.buix_coleft {
        padding-top: 50px;
        padding-left: 50px;
        padding-right: 50px;
    }
    .buix_coleft h2 {
        text-align: center;
    }
    .buix_coleft hr {
        margin-left: auto;
    }
    .uixsemi_bordr {
        margin: auto;
    }
    .benefit_textcol h5 {
        text-align: center;
    }
    .benefit_textcol p {
        text-align: center;
    }
    .row.rvrse_col {
        flex-direction: column-reverse;
    }
    .uix_apptext {
        padding-right: 50px;
        margin-top: 70px;
        padding-left: 50px;
        padding-bottom: 50px;
    }
    .choose_btnlink::before {
        width: 200px;
    }
    .choose_btnlink::after {
        width: 200px;
    }
    /* .offce_pic {
        padding-left: 0px;
    } */
    /* .col-lg-7.coladrs {
        margin-left: -20px;
        margin-top: 5px;
    } */
    .form-check-inline.mt_tab {
        margin-top: 40px;
    }
    /**Why Choose Us Media Css Starts**/
    .chose_graphic_sec {
        background-image: none;
        padding-top: 50px;
        padding-bottom: 100px;
        padding-left: 30px !important;
        padding-right: 50px !important;
        background-color: #043e6e;
        margin-top: 80px;
    }
    .grphic_colrt {
        padding-top: 50px;
    }
    .success_sec img.success_graph_img {
        position: relative;
        left: 0px;
        top: 50px;
    }
    .sucs_circle_dotted {
        width: 55px;
        height: 55px;
    }
    .sucs_bg_circle {
        width: 45px;
        height: 45px;
    }
    .sucs_bg_circle h6 {
        font-size: 14px;
    }
    .succs_txt p {
        font-size: 12px;
        line-height: 20px;
    }
    .success_col18 {
        position: absolute;
        width: 200px;
        bottom: 110px;
        left: 20px;
    }
    img.chs_down_arrow2 {
        position: absolute;
        top: 55px;
        right: -20px;
        width: 35px;
    }
    img.chs_down_arrow {
        position: absolute;
        top: 55px;
        right: -20px;
        width: 35px;
    }
    .success_col19 {
        position: absolute;
        width: 200px;
        right: 30px;
        bottom: -25px;
    }
    .success_col20 {
        position: absolute;
        width: 200px;
        left: 140px;
        top: 250px;
    }
    .success_col19 .succs_txt p {
        position: relative;
        left: 50px;
        padding: 0px 10px;
    }
    img.chs_down_arrow3 {
        position: absolute;
        left: 20px;
        padding-top: 5px;
        width: 35px;
    }
    .off_coleft {
        margin-top: 60px;
    }
    .off_coleft p {
        margin-top: 30px;
        margin-bottom: 30px;
    }
    .off_coleft button.btn.btn-primary {
        margin-bottom: 50px;
    }
    .abtcolpad {
        padding-left: 30px !important;
    }
    .cleintsec h4 {
        display: none;
    }
    /**Why Choose Us Media Css Ends**/
    /**WEB DEVELOPMENT Media Css Starts**/
    .webdev-tech {
        padding: 40px 50px;
    }
    .hybrid-app-icon img {
        width: 100px;
    }
    .hybrid-msec p {
        font-size: 16px;
        line-height: 28px;
    }
    .hybrid-msec {
        padding: 20px 20px;
        height: auto;
    }
    .frontt_tech {
        height: auto;
    }
    .frontt_tech h6 {
        font-size: 18px;
    }
    .front-end-colleft img {
        position: unset;
    }
    .row.php-row {
        flex-direction: column-reverse;
    }
    .framework-logo h5 {
        font-size: 20px;
        padding-left: 10px;
    }
    .framework-logo {
        padding: 5px 15px;
    }
    /**WEB DEVELOPMENT Media Css ends**/
    /**Career Modal Page media css starts**/
    .career_coll h4 {
        font-size: 16px;
        padding-left: 10px;
        line-height: 24px;
    }
    .carr_descript {
        margin-top: 0px;
    }
    .carr_descript ul li {
        font-size: 16px;
        line-height: 28px;
        margin: 7px 0px;
    }
    .coll-leftbg {
        padding: 20px 0px;
    }
    .career_icnbg img {
        width: 30px;
    }
    .modal_left_col h2 {
        text-align: center;
        font-size: 15px;
        margin-top: 50px;
    }
    .modal_left_col img.modall_img {
        width: 70%;
        position: absolute;
        bottom: 140px;
    }
    .modal-content {
        width: 740px !important;
        height: auto;
        margin: auto;
        padding: 40px 40px !important;
        position: relative;
    }
    .description-job p {
        font-size: 16px;
        line-height: 28px;
    }
    label#fileName {
        padding-top: 7px;
    }
    .col.col-lg-6.col-md-12.col-12.colapply_exp {
        padding-left: 5px !important;
    }
    /**Career Modal Page media css starts**/
    /**Digital media css starts**/
    .row.dm_title {
        position: absolute;
        top: 15px;
        left: 40px;
    }
    .dm_title h3 {
        font-size: 14px;
        padding-left: 10px;
    }
    img.img-fluid.icon_dm {
        width: 30px;
        height: 30px;
    }
    .dig_serve_sec p {
        font-size: 14px;
        line-height: 24px;
    }
    img.img-fluid.dm_arrow3 {
        display: none;
    }
    img.img-fluid.dm_arrow2 {
        display: none;
    }
    img.img-fluid.dm_arrow1 {
        display: none;
    }
    .dig_serve_sec {
        min-height: 300px;
        max-height: 330px;
    }
    /**Digital media css ends**/
    .test_coll {
        height: auto;
    }
    /**Service media css starts**/
    .serve_hd h2 {
        font-size: 32px;
    }
    .srvc_rtcoll h5 {
        margin-bottom: 5px;
    }
    .srvc_rtcoll p {
        font-size: 14px;
        line-height: 24px;
    }
    .srvc_rtcoll h5 {
        font-size: 18px;
        line-height: 27px;
    }
    .srvc_rtcoll h2 {
        font-size: 28px;
    }
    .srvchoose_sec h2 {
        font-size: 28px;
    }
    .srvchoose_sec h4 {
        font-size: 16px;
        line-height: 27px;
    }
    .servc_coll h4 {
        font-size: 18px;
        line-height: 27px;
    }
    .srvc_rtcoll {
        padding: 50px 30px;
    }
    .choose_srve_icn {
        border-radius: 50%;
        border: 2px solid #1EB8E2;
        width: 100px;
        height: 100px;
        display: flex;
        background: white;
    }
    .choose_srve_icn img {
        margin: auto;
        display: flex;
        width: 40px;
    }
    .chs_srvc2 {
        position: absolute;
        top: 40px;
        left: -35px;
        z-index: -1;
    }
    .chs_srvc2 span {
        width: 30px;
        height: 30px;
        border-radius: 50%;
        position: absolute;
        display: flex;
        margin: auto;
        padding-left: 7px;
        padding-top: 5px;
        font-size: 14px;
        right: 30px;
    }
    .chs_srvc1 span {
        width: 30px;
        height: 30px;
        border-radius: 50%;
        position: absolute;
        display: flex;
        margin: auto;
        padding-left: 7px;
        padding-top: 5px;
        font-size: 14px;
        left: 15px;
    }
    .srvchoose_sec p {
        font-size: 14px;
        line-height: 21px;
    }
    .chs_srvc2 h4 {
        margin-top: 30px;
        margin-left: 25px;
    }
    .chs_srvc2 p {
        margin-left: 25px;
    }
    .chs_srvc2 p.chs_lineicn img {
        position: absolute;
        top: 50px;
        left: 0px;
        width: 32px;
    }
    .chs_srvc1 p.chs_lineicn img {
        position: absolute;
        top: 60px;
        transform: rotate(180deg);
        right: 0px;
        width: 32px;
    }
    .srvchoose_sec h4 {
        margin-bottom: 0px;
    }
    .chs_srvc1 h4 {
        margin-top: 20px;
        margin-right: 30px;
    }
    .chs_srvc1 p {
        margin-right: 30px;
    }
    img.srvc_icn1 {
        border: 1px solid #7AB539;
        padding: 50px;
        border-radius: 50%;
    }
    img.srvc_icn2 {
        border: 1px solid #999999;
        padding: 50px;
        border-radius: 50%;
    }
    img.srvc_icn3 {
        border: 1px solid #80DEEA;
        padding: 50px;
        border-radius: 50%;
    }
    .container-fluid.srvchoose_sec {
        margin: 60px 0px;
        margin-bottom: 60px;
    }
    /**Service media css ends**/
    /**Portfolio media css starts**/
    .port_miapp h2 {
        margin-bottom: 10px;
        font-size: 24px;
        line-height: 36px;
    }
    .port_miapp p {
        font-size: 13px;
        line-height: 21px;
    }
    .port_miapp h3 {
        font-size: 14px;
        line-height: 22px;
        margin-top: 15px;
    }
    .bigtime_imgsec img.img-fluid.bigt_graphics {
        padding-left: 0px;
    }
    .bigtime_imgsec img.img-fluid.big_time_pic {
        max-width: 100%;
    }
    .row.mia_rtrow {
        margin-top: 60px;
    }
    .row.mia_rtrow img {
        max-width: 100% !important;
        height: auto;
    }
    .svdo_ltpic img.img-fluid.svdo_bg {
        height: 390px;
        top: 30px;
    }
    .svdo_ltpic img.img-fluid.svdopicf {
        position: absolute;
        top: 60px;
        width: 95%;
    }
    .technology_logo_img img {
        width: 25px;
    }
    .technology_logo_img {
        padding: 8px;
        height: 75px;
    }
    .technology_logo_img .tech-imgsec {
        height: 35px;
    }
    .technology_logo_img h6 {
        font-size: 7px;
    }
    .port_miapp h2 img.port_logo {
        padding-right: 15px;
        width: 50px;
    }
    .sec_evolution img.port_logo,
    .sec_bigtimelct img.port_logo,
    .sec_boomerng img.port_logo,
    .sec_wigwag img.port_logo {
        width: 100px !important;
    }
    .port_miapp h2 {
        font-size: 22px;
        line-height: 33px;
    }
    .mapple_imgsec img.img-fluid {
        position: absolute;
        top: 140px;
        max-width: 370px;
        left: 0px;
    }
    img.mapple_crcl {
        position: absolute;
        top: 105px;
        width: 370px;
        left: 0px;
    }
    .holla_imgsec {
        margin-top: 50px;
    }
    .avera_imgsec img {
        margin-top: 55px;
        max-width: 100% !important;
    }
    .port_miapp img.mia_logo {
        width: 50px;
    }
    .nav-tabs a.nav-item {
        font-size: 18px;
    }
    img.pink_crcle {
        width: 70%;
    }
    div#sweet_sec .port_sweet {
        padding-left: 8%;
    }
    div#laundris_sec .port_laundris {
        padding-left: 8%;
    }
    #httssec .htts_port {
        padding-right: 0%;
        padding-left: 8%;
    }
    .htts_imgsec img {
        position: absolute;
        top: 60px;
    }
    .cow_collimg {
        align-items: center;
        display: flex;
    }
    .cow_imgsec img {
        margin-top: 0px;
        max-width: 100%;
    }
    .wig_imgsec img.img-fluid.wig_bgraphic {
        position: absolute;
        left: 0px;
        z-index: -1;
        bottom: 75px;
    }
    .wig_imgsec img.img-fluid.wigfpic {
        position: relative;
        top: 40px;
    }
    .square_imgsec img.img-fluid.sqr_bgraph {
        display: none;
    }
    .square_imgsec img.img-fluid.square_fpic {
        position: relative;
        width: 95%;
        padding-top: 0px;
    }
    .sq_leftcol {
        align-items: center;
        display: flex;
    }
    .webport_btn button.btn.btn-primary {
        font-size: 12px;
        padding: 6px 20px;
    }
    /**Portfolio media css ends**/
    /**About media css starts**/
    .abt_apppic_sec img.abtapimg {
        display: none;
    }
    .abtapp_rt img.abtapimg2 {
        display: block;
        padding-top: 50px;
        margin: auto;
        max-width: 60%;
    }
    .abt_aps {
        padding: 50px 0px;
    }
    .abtapp_rt {
        margin-left: 0px;
    }
    .abtapp_rt h2 {
        margin-top: 30px;
        text-align: center;
    }
    .abtapp_rt hr {
        margin-left: auto;
    }
    /**About media css ends**/
    .slide_ryt .hm_appbg {
        margin-top: 40px;
        max-width: 100%;
        height: auto;
    }
    .hm_apc2 {
        position: absolute;
        top: 40px;
        left: 10px;
        width: 170px;
    }
    .hm_apc1 {
        position: absolute;
        right: 32px;
        top: 60px;
        z-index: 1;
        width: 170px;
    }
    .side_techicn img {
        width: 60px;
    }
    img.circleapp {
        position: absolute;
        width: 80%;
        top: 10px;
        left: 15px;
    }
    .abtbnr_sec .slid_bnr_sec h2 {
        font-size: 22px;
        line-height: 33px;
    }
    .web-dev-bnr .slid_bnr_sec h2 {
        font-size: 20px;
        line-height: 30px;
    }
    .abtbnr_sec .slid_bnr_sec h5 {
        font-size: 14px;
        line-height: 21px;
    }
    .abtbanr_text {
        margin-top: 25px;
    }
    .what_partnershp img {
        max-width: 100%;
        padding-top: 30px;
    }
    .whatwe_do button.btn.btn-primary {
        margin-bottom: 50px;
    }
    .whatwe_do {
        margin-top: 60px;
    }
    .whatwe_do p {
        color: #666666;
        font-size: 16px;
        line-height: 24px;
        font-family: 'Montserrat';
        font-weight: 300;
    }
    .whatdo_sec h2 {
        font-size: 22px;
    }
    #app_carousel a span i.fa-arrow-right {
        position: absolute;
        left: 90px;
        color: #fff;
        font-size: 20px;
        width: 30px;
        height: 30px;
        background-color: #043E6E;
        border-radius: 50%;
        padding-top: 3px;
    }
    #app_carousel a span i.fa-arrow-left {
        position: absolute;
        left: -40px;
        color: #fff;
        font-size: 20px;
        width: 30px;
        height: 30px;
        background-color: #043E6E;
        border-radius: 50%;
        padding-top: 4px;
    }
    .colhr hr {
        display: none;
    }
    .partner_ln {
        border-right: none;
    }
    .typeapp-txt h6 {
        font-size: 12px;
    }
    .typeapp-txt {
        padding-top: 15px;
        padding-bottom: 30px;
        padding-left: 7px;
        padding-right: 7px;
    }
    .typeapp-txt span i.fa-angle-right {
        width: 18px;
        height: 18px;
        font-size: 16px;
        display: -webkit-box;
        display: flex;
        float: right;
        padding-left: 7px;
        padding-bottom: 0px;
        padding-top: 1px;
    }
    .choose_dotted {
        display: block;
    }
    .choose_dotted2 {
        display: block;
    }
    .choose_dotted2 img {
        position: absolute;
        top: 15%;
        right: -24%;
        width: calc(100% - 115px);
    }
    .choose_dotted img {
        position: absolute;
        top: 16%;
        right: -24%;
        width: calc(100% - 115px);
    }
    .choose_box h3 {
        font-size: 16px;
        margin-top: 20px;
    }
    .banr_ctxt {
        margin-top: 20%;
    }
    .slider_sec ol.carousel-indicators {
        left: 20px;
    }
    .slid_bnr_sec {
        padding-left: 5%;
        padding-right: 5%;
    }
    .slider_sec .carousel-caption h5 {
        font-size: 14px;
        line-height: 21px;
    }
    .slider_sec .carousel-caption h2 {
        font-size: 22px;
    }
    .slider_sec .carousel-caption p {
        font-size: 10px;
        line-height: 15px;
    }
    /* .blog_colsec img {width: 100%;} */
    #cleint-rl::after {
        display: none;
    }
    .clients_img.partner_ln::before {
        display: none;
    }
    .apps_slider_sec a.carousel-control-prev span {
        width: 40px;
        height: 40px;
        border-radius: 50%;
    }
    .apps_slider_sec a.carousel-control-next span {
        width: 40px;
        height: 40px;
        border-radius: 50%;
    }
    .apps_slider_sec a.carousel-control-next span:after {
        font-size: 18px;
        color: #fff;
        position: absolute;
        top: 6px;
        left: 0px;
        right: 0px;
    }
    .apps_slider_sec a.carousel-control-prev span:after {
        font-size: 18px;
        color: #043E6E;
        position: absolute;
        top: 4px;
        left: 0px;
        right: 0px;
    }
    .apps_slider_sec a.carousel-control-prev {
        position: absolute;
        left: -32px;
    }
    .apps_slider_sec a.carousel-control-next {
        position: absolute;
        right: -32px;
    }
    .events_slider span.carousel-control-prev-icon:after {
        font-size: 19px;
    }
    .events_slider span.carousel-control-next-icon:after {
        font-size: 19px;
    }
    .eventslide span.carousel-control-prev-icon {
        right: -8px !important;
    }
    /**EVENTS PAGE MOBILE VIEW MEDIA CSS STARTS HERE**/
    .annual_collft_img {
        margin-top: 0px;
        margin-bottom: 20px;
    }
    .annual_colrt h2 span {
        font-size: 36px;
        font-weight: 300;
    }
    .row.colrvrs {
        flex-direction: column-reverse;
    }
    .annual_colrt h2:before {
        background-color: #D3EDF4;
        content: '';
        position: absolute;
        height: 125px;
        width: 60px;
        z-index: -1;
        top: 0px;
        left: 0px;
    }
    .mobile_slide {
        display: block !important;
        position: relative;
        bottom: 0px;
        left: 0px;
    }
    #eventimg_slidr {
        position: relative;
        bottom: 0px;
        left: 0px;
    }
    #eventimg18_slidr {
        position: relative;
        bottom: 0px;
        left: 0px;
    }
    #annualimg_slidr {
        position: relative;
        bottom: 0px;
        left: 0px;
    }
    #teambrdyimg_slidr {
        position: relative;
        bottom: 0px;
        left: 0px;
    }
    #ceobdyimg_slidr {
        position: relative;
        bottom: 0px;
        left: 0px;
    }
    #diwaliimg_slidr {
        position: relative;
        bottom: 0px;
        left: 0px;
    }
    #partyimg_slidr {
        position: relative;
        bottom: 0px;
        left: 0px;
    }
    .desk_view {
        display: none !important;
    }
    /**EVENTS PAGE MOBILE VIEW MEDIA CSS ENDS HERE**/
}

@media (max-width: 767.98px) {
    .header_menu .navbar ul.navbar-nav li a {
        margin: 8px 2px;
    }
    .header_menu {
        padding: 5px 5px;
    }
    .evol_coll {
        margin-top: 40px;
        margin-bottom: 0px;
    }
    #mob_platforms {
        display: block !important;
    }
    #desk_platforms {
        display: none !important;
    }
    hr.slct_hr {
        display: none;
    }
    .col-lg-7.pl-5.form-col {
        padding-left: 0px !important;
    }
    .captcha {
        margin-top: 5px;
        margin-bottom: 18px;
    }
    nav.navbar.navbar-expand-lg.navbar-dark {
        padding-left: 12px;
    }
    .select_err {
        position: absolute;
        bottom: 0px;
        left: 15px;
        top: 40px;
    }
    .col-md-6.mt-3.input-group.input-group-field.phn_field {
        margin-top: 30px !important;
    }
    .getquotes .form-control {
        padding: 5px 30px 5px 3px !important;
    }
    .container-fluid.qa_serv_sec {
        margin-top: 50px;
    }
    .test_coll {
        height: auto;
    }
    .alert.alert-success.mt-3.alrt_getquote {
        border-radius: 30px;
        padding: 5px 25px;
        position: absolute;
        bottom: 2px;
        right: 35px;
        left: 35px;
    }
    .getquotes {
        padding-bottom: 50px;
    }
    .alert.alert-success.mt-3.alrt_getquote p {
        margin-bottom: 0px;
        font-size: 10px;
        line-height: 15px;
    }
    .col-lg-6.col-md-12.col-12.input-col.mb-4.colapply_select {
        padding-left: 0px !important;
    }
    .col.col-lg-6.col-md-12.col-12.colapply_exp {
        padding-left: 0px !important;
    }
    .port_miapp img.img-fluid {
        padding-left: 12px;
        padding-right: 12px;
    }
    .md-col-rt h2 {
        font-size: 18px;
        line-height: 27px;
    }
    .alert.alert-primary.mt-3.apply_alrt {
        position: relative;
        width: 100%;
        padding: 10px 20px;
    }
    .container-fluid.get_sec.career_getsec {
        padding-top: 0px;
        margin-top: 50px;
    }
    /* .col-lg-5.col-md-5.coloffce_pic {
        padding-left: 0px;
    } */
    .banr_ctxt button.btn.btn-primary {
        display: none;
    }
    .partnership_col img.agora_pimg {
        max-width: 110px;
        height: auto;
    }
    .about_leftext h3 {
        font-size: 20px;
        line-height: 32px;
    }
    .about_leftext h2 {
        font-size: 26px !important;
        line-height: 36px !important;
        padding-left: 15px;
    }
    .about_leftext span:before {
        width: 5px;
        height: 22px;
        top: 80px;
    }
    .abt_whatdo {
        margin: 50px 0px;
    }
    .col-lg-6.buix_coleft {
        padding-top: 50px;
        padding-left: 20px;
        padding-right: 20px;
    }
    .colrt_btxt {
        padding-left: 20px !important;
    }
    .uix_apptext {
        padding-right: 20px;
        margin-top: 50px;
        padding-left: 20px;
        padding-bottom: 50px;
    }
    img.benfits_dot {
        display: none;
    }
    .buix_coleft h2 {
        text-align: center;
    }
    .buix_coleft hr {
        margin-left: auto;
    }
    .uixsemi_bordr {
        margin: auto;
    }
    .benefit_textcol h5 {
        text-align: center;
    }
    .benefit_textcol p {
        text-align: center;
    }
    .row.rvrse_col {
        flex-direction: column-reverse;
    }
    .buix_coleft h2 {
        font-size: 20px;
        line-height: 30px;
    }
    .uix_apptext h2 {
        font-size: 20px;
        line-height: 30px;
    }
    .choose_btnlink::before {
        width: 50px;
    }
    .choose_btnlink::after {
        width: 50px;
    }
    .choose_btnlink button.btn.btn-primary {
        font-size: 12px;
        padding: 9px 28px;
    }
    button.btn.btnbacktotop {
        position: fixed;
        right: 30px;
        z-index: 111111;
        bottom: 45px;
        background-color: #4BD8F9;
        border-color: #4BD8F9;
        color: #fff;
    }
    .slider_sec .carousel-indicators li {
        width: 10px !important;
        height: 10px !important;
        right: 60%;
    }
    #app_carousel ol li {
        bottom: -70px;
    }
    .apps_iconimg img.img-fluid.mt-3.pl-3 {
        padding-left: 0px !important;
    }
    .apps_slider_sec a.carousel-control-prev span {
        width: 20px;
        height: 20px;
        border-radius: 50%;
        background-position: 4px 5px;
    }
    .apps_slider_sec a.carousel-control-next span {
        width: 20px;
        height: 20px;
        border-radius: 50%;
        background-position: 4px 5px;
    }
    .apps_slider_sec a.carousel-control-next span:after {
        font-size: 9px;
        color: #fff;
        position: absolute;
        top: 3px;
        left: 0px;
        right: 0px;
    }
    .apps_slider_sec a.carousel-control-prev span:after {
        font-size: 9px;
        color: #043E6E;
        position: absolute;
        top: 1px;
        left: 0px;
        right: 0px;
    }
    .apps_slider_sec a.carousel-control-prev {
        position: absolute;
        left: -20px;
    }
    .apps_slider_sec a.carousel-control-next {
        position: absolute;
        right: -20px;
    }
    .phn_imgblock {
        display: block;
    }
    .apps_colimg {
        display: none !important;
    }
    .all_right_foot h6 {
        font-weight: 400;
        font-family: 'Montserrat';
        font-size: 12px;
        color: #fff;
        margin-top: 10px;
        line-height: 18px;
        padding-right: 30px;
    }
    .foot_icns span i.fa {
        margin: 0px 3px;
        color: #fff;
        margin-top: 10px;
        font-size: 14px;
    }
    .choose_icn img {
        margin: auto;
        display: table;
        margin-top: 40px;
        width: 50%;
    }
    .banr_ctxt {
        margin-top: 20px;
    }
    .slider_sec .carousel-caption h5 {
        font-size: 12px;
        line-height: 20px;
    }
    .slider_sec .carousel-caption h2 {
        font-size: 12px;
        line-height: 20px;
    }
    .banr_ctxt button.btn.btn-primary {
        margin-top: 5px;
        margin-left: 20px;
        z-index: 111;
        position: relative;
        padding: 4px 20px;
        font-size: 10px;
    }
    .slider_sec ol.carousel-indicators {
        bottom: -15px;
        left: 25px;
    }
    .serve_box {
        min-height: auto;
        max-height: none;
    }
    .slider_sec .carousel-caption p {
        display: none;
    }
    .what_partnershp img {
        margin-top: 0px;
        margin: auto;
        display: table;
        max-width: 100%;
        height: auto;
        padding-top: 0px;
    }
    .whatwe_do {
        margin-top: 30px;
        padding-left: 30px;
    }
    .whatwe_do p {
        font-size: 16px;
        line-height: 24px;
    }
    .whatdo_sec {
        margin-top: 40px;
        margin-bottom: 40px;
    }
    #space_sec h2 {
        font-size: 20px;
        line-height: 30px;
    }
    .choose_box h3 {
        font-size: 18px;
    }
    .apps_iconimg img {
        width: 110px;
        margin: 0px 3px;
    }
    .apps_slide_txt h4 {
        font-size: 20px;
        margin-bottom: 5px;
    }
    .whatdo_sec h2 {
        font-size: 20px;
    }
    .specl_sec h2 {
        font-size: 20px;
    }
    #space_sec hr {
        margin-top: 10px;
    }
    .serve_box h4 {
        font-size: 18px;
        line-height: 27px;
    }
    .specl_sec {
        padding-bottom: 60px;
        padding-top: 40px;
    }
    .appstype_sec {
        padding-top: 40px;
        padding-bottom: 40px;
    }
    .choose_sec {
        margin: 40px 0px;
    }
    .apps_slider_sec {
        padding-bottom: 40px;
        padding-top: 40px;
    }
    .partner_ln {
        border-right: 0px;
    }
    .colhr hr {
        display: none;
    }

    .getquotes hr {
        display: none;
    }
    .cleintsec {
        margin: 40px 0px;
    }
    #cleint-rl::after {
        display: none;
    }
    .clients_img.partner_ln::before {
        display: none;
    }
    .container-fluid.slider_sec {
        padding: 0px;
        margin-top: 135px;
    }
    .get_sec {
        padding-top: 40px;
        padding-bottom: 40px;
        margin-top: 30px;
    }
    #banr_slider ol.carousel-indicators li {
        background-color: #fff;
        position: relative;
        width: 15px;
        border-radius: 100%;
        opacity: 1;
        right: 60%;
        bottom: 0px;
    }
    #app_carousel a span i.fa-arrow-left {
        position: absolute;
        left: -25px;
        color: #fff;
        font-size: 14px;
        width: 25px;
        height: 25px;
        background-color: #043E6E;
        border-radius: 50%;
        padding-top: 5px;
    }
    #app_carousel a span i.fa-arrow-right {
        position: absolute;
        left: 25px;
        color: #fff;
        font-size: 14px;
        width: 25px;
        height: 25px;
        background-color: #043E6E;
        border-radius: 50%;
        padding-top: 5px;
    }
    #app_carousel {
        padding: 0px 12px;
    }
    .choose_dotted2 {
        display: none;
    }
    .choose_dotted {
        display: none;
    }
    .owl-carousel-blog .owl-item.active {
        margin-right: 0px !important;
    }
    .blog_icn span {
        color: #BEBEBE;
        margin-left: 5px;
    }
    .blgcol {
        margin-left: 15px;
    }
    .get_sec p {
        font-size: 14px;
        color: #666666;
        line-height: 24px;
        font-family: 'Montserrat';
        font-weight: 300;
        margin-bottom: 10px;
    }
    .addrs-rt {
        margin-top: 40px;
        padding-left: 35px;
    }
    .form_home {
        padding: 0px 10px;
    }
    .footer_sec h6 {
        margin-bottom: 10px;
    }
    .form-group.form_select {
        margin-top: 10px;
    }
    .career_hd_sec {
        margin: 50px 0px;
    }
    .abtapp_rt {
        margin-left: 0px;
        margin-top: 0px;
    }
    .appcol_txt h3 {
        text-align: center;
    }
    .appcol_txt p {
        text-align: center;
    }
    .abtapp_rt h2 {
        text-align: center;
    }
    .abtapp_rt hr {
        margin-left: auto;
    }
    .abt_aps {
        padding-top: 20px;
        padding-bottom: 50px;
    }
    .abtbanr_text {
        margin-top: -7px;
    }
    .career_section {
        padding: 50px 0px;
    }
    .abtbnr_sec .slid_bnr_sec h5 {
        font-size: 12px;
        line-height: 18px;
    }
    .abtbnr_sec .slid_bnr_sec h2 {
        font-size: 18px;
        line-height: 27px;
        margin-top: -5px;
        font-weight: 600;
        padding: 2px 15px;
    }
    .col-lg-3.col-md-6.form-check-inline {
        margin-top: 40px;
    }
    /**UI/UX media css starts**/
    .uiux_serv_sec {
        margin-top: 40px;
        overflow: hidden;
    }
    /**UI/UX media css ends**/
    /**Why Choose Us Media Css Starts**/
    .chose_graphic_sec {
        background-image: none;
        padding-top: 50px;
        padding-bottom: 130px;
        padding-left: 30px !important;
        padding-right: 50px !important;
        background-color: #043e6e;
        margin-top: 80px;
    }
    .grphic_colrt {
        padding-top: 50px;
    }
    .success_sec img.success_graph_img {
        position: relative;
        left: 0px;
        top: 80px;
    }
    .sucs_circle_dotted {
        width: 30px;
        height: 30px;
    }
    .sucs_bg_circle {
        width: 25px;
        height: 25px;
    }
    .sucs_bg_circle h6 {
        font-size: 7px;
    }
    .succs_txt p {
        font-size: 8px;
        line-height: 14px;
    }
    .success_col18 {
        position: absolute;
        width: 100px;
        bottom: -10px;
        left: 0px;
    }
    img.chs_down_arrow2 {
        position: absolute;
        top: 60px;
        right: -5px;
        width: 20px;
    }
    img.chs_down_arrow {
        position: absolute;
        top: 60px;
        right: -20px;
        width: 20px;
    }
    .success_col19 {
        position: absolute;
        width: 110px;
        right: 0px;
        bottom: -115px;
    }
    .success_col20 {
        position: absolute;
        width: 100px;
        left: 120px;
        top: 240px;
    }
    .success_col19 .succs_txt p {
        position: relative;
        left: 20px;
        padding: 0px 5px;
    }
    img.chs_down_arrow3 {
        position: absolute;
        left: 4px;
        padding-top: 5px;
        width: 15px;
    }
    .off_coleft {
        margin-top: 60px;
    }
    .off_coleft p {
        margin-top: 30px;
        margin-bottom: 30px;
    }
    .off_coleft button.btn.btn-primary {
        margin-bottom: 50px;
    }
    .chose_coleft img.chs_leftbg {
        position: absolute;
        left: 0px;
        max-width: 100%;
        height: auto;
    }
    .cleintsec h4 {
        display: none;
    }
    .choseapp_rt {
        padding-top: 50px;
        padding-bottom: 50px;
        padding-left: 15px;
    }
    /**Why Choose Us Media Css Ends**/
    /** QA Page Css starts**/
    .qa_test_sec {
        margin: auto;
    }
    .container-fluid.software_sec {
        padding-top: 50px;
        padding-bottom: 50px;
    }
    .qacol_text h3 {
        text-align: center;
    }
    .qacol_text p {
        text-align: center;
    }
    .qacol_text {
        padding-left: 0px;
    }
    /** QA Page Css ends**/
    /**About media css starts**/
    .abt_apppic_sec img.abtapimg {
        position: unset;
        top: 0px;
        width: 100%;
    }
    .abtapp_rt {
        margin-left: 0px;
    }
    .abtapp_rt h2 {
        margin-top: 30px;
        text-align: center;
    }
    .abtapp_rt hr {
        margin-left: auto;
    }
    .abtapp_rt img.abtapimg2 {
        display: block;
        padding-top: 20px;
        margin: auto;
        max-width: 50%;
    }
    .abt_apppic_sec img.abtapimg {
        display: none;
    }
    .abt_appicn_sec {
        margin: auto;
    }
    .col-lg-10.mt-5.abtcolpadrt {
        margin-top: 20px !important;
    }
    .misn_coleft {
        margin-top: 30px;
    }
    .container-fluid.abt_hd.abt_misn {
        padding-left: 20px;
        padding-right: 20px;
    }
    .misn_sec h3 {
        font-size: 24px;
    }
    .abt_hd h2 {
        font-size: 24px;
    }
    .container-fluid.recent_event_sec {
        background-color: #F2F3F8;
        padding-top: 50px;
        padding-bottom: 100px;
        padding-left: 30px;
        padding-right: 30px;
        overflow: hidden;
    }
    .events_coleft button.btn.btn-primary {
        margin-bottom: 40px;
    }
    /**About media css ends**/
    /**Service media css starts**/
    .mobile_serv_sec p {
        font-size: 18px;
        line-height: 27px;
    }
    img.srvc_icn1 {
        padding: 50px;
        border-radius: 50%;
    }
    img.srvc_icn2 {
        padding: 50px;
        border-radius: 50%;
    }
    img.srvc_icn3 {
        padding: 50px;
        border-radius: 50%;
    }
    .servc_coll.text-center {
        margin-top: -25px;
    }
    .smbapp_sec {
        margin: 50px 0px;
    }
    .servc_quote h5 {
        width: auto;
    }
    .servc_quote h5 span {
        background: #fff;
        padding: 0 10px;
    }
    .servc_quote h5 span button {
        border: none;
        border-radius: 30px;
        padding: 7px 13px;
        font-size: 9px;
        text-transform: uppercase;
    }
    .servc_quote.mt-5 {
        margin-top: 0px !important;
        margin-bottom: 60px;
    }
    .srvc_rtcoll h2 {
        font-size: 20px;
        line-height: 30px;
        text-align: center;
    }
    .srvc_rtcoll hr {
        margin-left: auto;
    }
    .srvc_imgicon {
        width: 100px;
        height: 100px;
        border: 2px dashed #003D70;
        border-radius: 50%;
        display: flex;
        margin: auto;
    }
    .srvc_rtcoll h5 {
        font-size: 16px;
        line-height: 24px;
        text-align: center;
        margin-top: 15px;
        margin-bottom: 5px;
    }
    .srvc_rtcoll p {
        font-size: 16px;
        line-height: 24px;
        text-align: center;
    }
    .container-fluid.srvcap_sec {
        padding-right: 0px;
        padding-left: 0px;
    }
    .chs_srvc2,
    .chs_srvc1 {
        position: unset;
    }
    .chs_srvc1 p {
        text-align: center;
        margin-right: 0px;
    }
    .chs_srvc2 p {
        text-align: center;
        margin-right: 0px;
    }
    .chs_srvc1 h4 {
        text-align: center;
        margin-right: 0px;
        margin-top: 10px;
    }
    .choose_srve_icn {
        border-radius: 50%;
        border: 2px solid #1EB8E2;
        width: 200px;
        height: 200px;
        display: flex;
        background: white;
        margin: auto;
    }
    .chs_srvc1 p.chs_lineicn img {
        display: none;
    }
    .chs_srvc2 p.chs_lineicn img {
        display: none;
    }
    .chs_srvc2 {
        margin-top: 50px;
    }
    .chs_srvc2 h4 {
        text-align: center;
        margin-top: 20px;
    }
    .chs_srvc1 span {
        left: 80px;
    }
    .container-fluid.srvchoose_sec {
        margin: 60px 0px;
        margin-bottom: 60px;
    }
    .srvlast_sec h5 {
        font-size: 20px;
        text-transform: uppercase;
        line-height: 30px;
    }
    .servc_coll h4 {
        font-size: 18px;
        line-height: 27px;
        margin-top: 20px;
        margin-bottom: 5px;
    }
    .srvchoose_sec h4 {
        font-size: 18px;
        line-height: 27px;
        margin-bottom: 5px;
        margin-top: 15px;
    }
    .row.chs_srvc1 {
        flex-direction: column-reverse;
    }
    /**Service media css ends**/
    /**Digital media css starts**/
    img.img-fluid.dm_arrow3 {
        display: none;
    }
    img.img-fluid.dm_arrow2 {
        display: none;
    }
    img.img-fluid.dm_arrow1 {
        display: none;
    }
    .row.dm_title {
        top: 15px;
        left: 40px;
    }
    .dm_title h3 {
        padding-left: 10px;
        font-size: 14px;
    }
    .dig_serve_sec p {
        font-size: 14px;
        line-height: 24px;
    }
    img.img-fluid.icon_dm {
        width: 30px;
        height: 30px;
    }
    .dm_do_rt h2 {
        text-align: center;
    }
    .abt_hd hr {
        margin-left: auto;
    }
    .abt_hd .misn_coleft hr {
        margin-left: 0px;
    }
    .events_coleft hr {
        margin-left: 0px;
    }
    .dm_what_sec {
        margin: auto;
    }
    .dm_whatdo_sec {
        padding: 50px 0px;
    }
    .dmcol_txt h3 {
        text-align: center;
    }
    .dmcol_txt p {
        text-align: center;
    }
    .dig_serve_sec {
        height: auto;
    }
    /**Digital media css ends**/
    /**Portfolio media css starts**/
    div#laundris_sec .port_laundris {
        padding-left: 15px;
        padding-right: 15px;
    }
    div#portfolio_sec1_pl {
        flex-direction: column-reverse;
    }
    .nav-tabs a.nav-item {
        font-size: 16px;
        margin: 0px 10px;
    }
    .port_miapp {
        padding: 40px 0px;
    }
    .port_miapp {
        padding-left: 15px !important;
        padding-right: 15px !important;
    }
    .port_miapp h2 img.port_logo {
        padding-right: 10px;
        width: 100px;
    }
    .sec_evolution .port_miapp h2 img.port_logo {
        padding-right: 10px;
        width: 200px;
    }
    .port_miapp h2 {
        font-size: 24px;
        line-height: 36px;
    }
    .webport_btn button.btn.btn-primary {
        margin-left: 15px;
        font-size: 14px;
        padding: 8px 30px;
    }
    .row.technlgy_row {
        padding-left: 15px;
        padding-right: 15px;
    }
    /**Portfolio media css ends**/
    /**WEB DEVELOPMENT Media Css Starts**/
    .webdev-tech {
        padding: 20px 15px;
    }
    .front-end-colleft img {
        position: unset;
    }
    .webd_para.mt-5 {
        margin-top: 20px !important;
    }
    .webdevt_sec {
        margin: 50px 0px;
    }
    .row.php-row {
        flex-direction: column-reverse;
    }
    .row.webd-technology {
        margin-top: 50px;
    }
    .row.webd-hybrid.mt-5 {
        margin-top: 10px !important;
    }
    .hybrid-app-icon img {
        width: 100px;
    }
    .hybrid-msec {
        padding: 30px 15px;
        height: auto;
        border: 1px solid #c26f6f38;
    }
    .frontt_tech {
        height: auto;
    }
    .framework-logo {
        padding: 15px 15px;
    }
    .hybrid-msec p {
        font-size: 16px;
        line-height: 28px;
    }
    .hybrid-msec h4 {
        margin: 15px 0px;
    }
    .framework-logo h5 {
        font-size: 20px;
        padding-left: 15px;
    }
    .framework-logo span img {
        width: 50px;
    }
    .frontend_ltcoll h2 {
        font-size: 20px;
        line-height: 30px;
    }
    .container-fluid.webd-frontendtech {
        margin: 50px 0px;
    }
    .framework-rtsec.mt-3 {
        margin-top: 0px !important;
    }
    .webd_framework_sec {
        padding: 50px 0px;
    }
    /**WEB DEVELOPMENT Media Css ends**/
    /**Career Page media css starts**/
    .career_coll h4 {
        font-size: 16px;
        line-height: 24px;
    }
    .career_section h3 {
        font-size: 20px;
        line-height: 30px;
    }
    .career_hd_sec p {
        font-size: 16px;
        line-height: 28px;
        margin-top: 20px;
    }
    .coll-leftbg {
        padding: 20px 0px;
    }
    .career_icnbg img {
        width: 80%;
    }
    /**Career Page media css ends**/
    /**Career Modal Page media css starts**/
    .modal-content {
        width: 100% !important;
        height: 400px !important;
        margin: auto;
        background-color: #13B9E5;
        padding: 20px 20px !important;
        position: relative;
        overflow-y: scroll;
        overflow-x: hidden;
    }
    .modal-body.row {
        padding: 0px 15px;
    }
    .modal_left_col h2 {
        font-size: 14px;
        line-height: 21px;
    }
    .modal_left_col hr {
        width: 60px;
        margin-top: 12px;
    }
    .modal_left_col img.modall_img {
        width: 60%;
        position: unset;
        bottom: unset;
        margin: auto;
        display: table;
        margin-bottom: 20px;
    }
    /**Career Modal Page media css starts**/
    /**EVENTS PAGE MOBILE VIEW MEDIA CSS STARTS HERE**/
    .annual_collft_img {
        margin-top: 0px;
        margin-bottom: 20px;
    }
    .annual_colrt h2 span {
        font-size: 36px;
        font-weight: 300;
    }
    .row.colrvrs {
        flex-direction: column-reverse;
    }
    .annual_colrt h2:before {
        background-color: #D3EDF4;
        content: '';
        position: absolute;
        height: 125px;
        width: 60px;
        z-index: -1;
        top: 0px;
        left: 0px;
    }
    .mobile_slide {
        display: block !important;
        position: relative;
        bottom: 0px;
        left: 0px;
    }
    #eventimg_slidr {
        position: relative;
        bottom: 0px;
        left: 0px;
    }
    #eventimg18_slidr {
        position: relative;
        bottom: 0px;
        left: 0px;
    }
    #annualimg_slidr {
        position: relative;
        bottom: 0px;
        left: 0px;
    }
    #teambrdyimg_slidr {
        position: relative;
        bottom: 0px;
        left: 0px;
    }
    #ceobdyimg_slidr {
        position: relative;
        bottom: 0px;
        left: 0px;
    }
    #diwaliimg_slidr {
        position: relative;
        bottom: 0px;
        left: 0px;
    }
    #partyimg_slidr {
        position: relative;
        bottom: 0px;
        left: 0px;
    }
    .eventslide a.carousel-control-prev {
        z-index: -1;
    }
    .eventslide a.carousel-control-next {
        z-index: -1;
    }
    .eventslide.eventleftsld span.carousel-control-next-icon {
        right: 0px !important;
        position: absolute;
    }
    .eventslide.eventleftsld span.carousel-control-prev-icon {
        right: 20px !important;
        position: absolute;
    }
    #eventimg_slidr span.carousel-control-prev-icon {
        left: 0px;
    }
    #eventimg_slidr span.carousel-control-next-icon {
        left: -90px;
        padding-left: 4px;
    }
    #eventimg18_slidr span.carousel-control-prev-icon {
        left: 0px;
    }
    #eventimg18_slidr span.carousel-control-next-icon {
        left: -90px;
    }
    #diwaliimg_slidr span.carousel-control-prev-icon {
        left: 0px;
    }
    #diwaliimg_slidr span.carousel-control-next-icon {
        left: -90px;
    }
    #ceobdyimg_slidr span.carousel-control-next-icon {
        left: -90px;
    }
    #ceobdyimg_slidr span.carousel-control-prev-icon {
        left: 0px;
    }
    .desk_view {
        display: none !important;
    }
    .typeapp-txt span i.fa-angle-right {
        font-size: 19px;
        padding-left: 10px;
        padding-top: 2px;
    }
    .uix_icn {
        width: 160px;
        height: 160px;
    }
    .uix_icn img {
        margin: auto;
        width: 60px;
    }
    /**EVENTS PAGE MOBILE VIEW MEDIA CSS ENDS HERE**/
}

@media (max-width: 330px) {
    .slider_sec .carousel-caption h5 {
        font-size: 14px;
        line-height: 21px;
    }
    .slider_sec .carousel-caption h2 {
        font-size: 16px;
        line-height: 24px;
    }
    .banr_ctxt {
        margin-top: 8px;
    }
    #space_sec h2 {
        font-size: 18px;
        line-height: 27px;
    }
    .chs_srvc1 span {
        left: 60px;
    }
    .chs_srvc2 span {
        right: 60px;
    }
    .about_leftext span:before {
        height: 17px;
    }
    .about_leftext h2 {
        font-size: 23px !important;
        line-height: 33px !important;
        padding-left: 10px;
    }
    .eventslide.eventleftsld span.carousel-control-prev-icon {
        right: 36px !important;
        position: absolute;
    }
    .annual_colrt h2 span {
        font-size: 30px;
        position: relative;
        top: 4px;
    }
    .annual_colrt h2:before {
        height: 90px;
    }
    .annual_colrt h2 {
        padding-top: 12px;
        font-size: 20px !important;
    }
    div#event18 .col-md-3.col-3 {
        padding-left: 5px;
        padding-right: 5px;
    }
    .dm_title h3 {
        padding-left: 8px;
        font-size: 11px;
    }
    img.img-fluid.icon_dm {
        width: 25px;
        height: 25px;
    }
}

@media (min-width: 1601px) {
    .colweb_text {
        align-items: center;
        display: flex;
    }
    img.img-fluid.record_bgraphics {
        position: relative;
        max-width: 55%;
    }
    img.img-fluid.record_fpic {
        position: absolute;
        left: -50px;
        max-width: 85%;
    }
    .wig_imgsec img.img-fluid.wigfpic {
        position: relative;
        max-width: 90%;
    }
    .square_imgsec img.img-fluid.sqr_bgraph {
        position: relative;
        left: 0px;
        max-width: 90%;
    }
    .square_imgsec img.img-fluid.square_fpic {
        width: 80%;
        padding-top: 50px;
    }
    img.pink_crcle {
        width: 44%;
    }
    .you_ltpic img.img-fluid.you_bg {
        position: relative;
        top: 0px;
        height: 691px;
        left: 0px;
    }
    #partyimg_slidr span.carousel-control-prev-icon {
        right: -32px;
    }
    #teambrdyimg_slidr span.carousel-control-prev-icon {
        right: -32px;
    }
    .footer_sec {
        padding-right: 6% !important;
        padding-left: 6% !important;
    }
    .all_right_foot {
        padding-right: 6% !important;
        padding-left: 6% !important;
    }
    .time_col {
        padding-left: 65px !important;
    }
    .row.mia_rtrow img {
        position: absolute;
        right: 0px;
    }
    .holla_imgsec img {
        max-width: 90%;
    }
    .slid_bnr_sec {
        padding-left: 6%;
        padding-right: 8%;
    }
    .form-row.select_plt .check_icon {
        width: 60px;
        height: 60px;
    }
    .form-row.select_plt label.custom-control-label {
        padding-left: 45px;
        padding-top: 15px;
    }
    .time_col p.chs_lineicn img {
        left: -20px !important;
    }
    #annualimg_slidr span.carousel-control-prev-icon {
        right: -33px;
    }
    .srvc_ltcol img {
        margin-top: 50px;
    }
    .abtcolpadrt {
        padding-left: 0px;
        margin-left: -30px;
    }
    /**Home Page XL media css starts**/
    span#avera_ap1 {
        right: 132px;
        top: 125px;
    }
    span#avera_ap2 {
        right: 87px !important;
        top: 275px;
    }
    span#avera_ap3 {
        top: 434px;
        right: 123px;
    }
    span#djsant_img2 {
        position: absolute;
        right: 70px;
    }
    span#djsant_img1 {
        position: absolute;
        right: 132px;
        top: 130px;
    }
    span#djsant_img3 {
        position: absolute;
        top: 414px;
        right: 110px;
    }
    .hm_apc2 {
        position: absolute;
        top: 100px;
        left: 50px;
        width: 430px;
    }
    .hm_apc1 {
        position: absolute;
        right: 50px;
        top: 140px;
        z-index: 1;
        width: 430px;
    }
    span.java_img {
        position: absolute;
        top: 20px;
        right: 220px;
    }
    span.php_img {
        position: absolute;
        right: 225px;
        top: 537px;
    }
    span.cde_img {
        position: absolute;
        top: 270px;
        right: 35px;
    }
    span#tact_im1 {
        top: 125px;
        right: 133px;
    }
    span#tact_im2 {
        top: 270px;
        right: 45px;
    }
    span#tact_im3 {
        top: 420px;
        right: 125px;
    }
    .choose_dotted img {
        position: absolute;
        top: 25%;
        right: -23%;
        width: calc(100% - 290px);
    }
    .choose_dotted2 img {
        position: absolute;
        top: 25%;
        right: -23%;
        width: calc(100% - 290px);
    }
    .banr_ctxt {
        margin-top: 35%;
    }
    .phn_apppic img {
        margin-left: 40px;
        margin-top: -25px;
    }
    .side_techicn img {
        width: 80px;
    }
    .clients_img.partner_ln::before {
        height: 1px;
        width: 200px;
        background-color: #BEBEBE;
        position: absolute;
        content: "";
        bottom: -28px;
        left: 35px;
    }
    .footer_sec ul li a {
        font-size: 15px;
    }
    .footer_sec span {
        font-size: 15px;
    }
    .all_right_foot h6 {
        font-size: 15px;
        margin-top: 10px;
    }
    .col-lg-9.col-md-9.col-sm-8.col-8.adrs_txt {
        padding-left: 0px;
        margin-left: -15px;
        margin-top: 5px;
    }
    .alert.alert-success.mt-3.alrt_getquote {
        padding: 9px 60px;
        bottom: 40px;
    }
    /**Home Page XL media css ends**/
    .appstore_coll img {
        position: relative;
        left: -12px;
    }
    /**About Page XL media css starts**/
    .abtbanr_text {
        margin-top: 100px;
    }
    /**About Page XL media css ends**/
}

@media (max-width:640px) {
    .events_slider {
        display: none;
    }
    .events_slider2 {
        display: block;
    }
    .events_slider2 .carousel .carousel-control-prev-icon {
        bottom: 0px;
        position: absolute;
        width: 40px;
        height: 40px;
        background-color: #003D70;
        border-radius: 50%;
        background-position: 0px 1px;
    }
    .events_slider2 a.carousel-control-prev {
        left: 34%;
    }
    .events_slider2 a.carousel-control-next {
        left: 52%;
    }
    .events_slider2 .carousel .carousel-control-next-icon {
        bottom: 0px;
        position: absolute;
        width: 40px;
        height: 40px;
        background-color: #043E6E;
        border-radius: 50%;
        background-position: 2px 1px;
    }
    .events_slider2 ol.carousel-indicators {
        display: none;
    }
    .events_slider2 .carousel.carousel.slide.carousel-fade {
        padding-bottom: 60px;
    }
}

@media (min-width: 1481px) and (max-width: 1601px) {
    .colweb_text {
        align-items: center;
        display: flex;
    }
    .row.avera_imgsec img.img-fluid {
        max-width: 81%;
    }
    .row.mia_rtrow img {
        max-width: 95%;
    }
    .holla_imgsec img {
        max-width: 92%;
    }
    .cow_imgsec img {
        max-width: 100%;
        margin-top: 75px;
    }
    .bigtime_imgsec img.img-fluid.big_time_pic {
        position: relative;
        max-width: 89%;
        left: 60px;
    }
    .bigtime_imgsec img.img-fluid.bigt_graphics {
        padding-left: 85px;
    }
    .wig_imgsec img.img-fluid.wigfpic {
        position: relative;
        max-width: 92%;
    }
    .square_imgsec img.img-fluid.sqr_bgraph {
        max-width: 95%;
    }
    .square_imgsec img.img-fluid.square_fpic {
        width: 82%;
    }
    .you_ltpic img.img-fluid.you_bg {
        height: 620px;
    }
    .you_ltpic img.img-fluid.youpicf {
        position: absolute;
        top: 25px;
    }
    img.pink_crcle {
        width: 50%;
    }
    .row.mia_rtrow img {
        max-width: 90%;
    }
    img.mapple_crcl {
        position: absolute;
        top: 15px;
    }
    .mapple_imgsec img.img-fluid {
        position: absolute;
        top: 72px;
    }
    .typeapp-txt span i.fa-angle-right {
        padding-left: 10px;
    }
    .addrs-rt span p.adrs_line {
        font-size: 15px;
    }
    .addrs-rt p span {
        font-size: 15px;
    }
    .time_col {
        padding-left: 50px !important;
    }
    .time_col p.chs_lineicn img {
        left: -40px !important;
    }
    .events_slider a.carousel-control-prev {
        left: 38%;
    }
    img.img-fluid.record_bgraphics {
        max-width: 64%;
    }
    .captcha {
        margin-bottom: 50px;
    }
    span#djsant_img2 {
        position: absolute;
        right: 50px;
    }
    span#djsant_img1 {
        position: absolute;
        right: 105px;
        top: 110px;
    }
    span#djsant_img3 {
        position: absolute;
        top: 360px;
        right: 87px;
    }
    span.kotlin_ap {
        right: 65px !important;
    }
    span#avera_ap3 {
        top: 334px;
        right: 85px;
    }
    .slider_sec .carousel-indicators li {
        right: 56%;
    }
    span#cbrgimg1 {
        top: 40px;
        right: 160px;
    }
    span#cbrgimg2 {
        top: 137px;
        right: 94px;
    }
    span#cbrgimg3 {
        top: 255px;
        right: 52px !important;
    }
    span#cbrgimg4 {
        top: 372px;
        right: 95px;
    }
    span#cbrgimg5 {
        top: 462px;
        right: 170px;
    }
    span#safe_img1 {
        right: 170px;
        top: 35px;
    }
    span#safe_img2 {
        right: 110px;
        top: 130px;
    }
    span#compete_img1 {
        top: 28px;
        right: 190px;
    }
    span#compete_img2 {
        top: 126px;
        right: 99px;
    }
    span#compete_img3 {
        top: 245px;
        right: 23px;
    }
    span#compete_img4 {
        top: 370px;
        right: 95px;
    }
    span#compete_img5 {
        top: 465px;
        right: 195px;
    }
    span#tact_im1 {
        top: 125px;
        right: 100px;
    }
    span#tact_im2 {
        top: 245px;
        right: 28px;
    }
    span#tact_im3 {
        top: 370px;
        right: 110px;
    }
    .abtbnr_sec .slid_bnr_sec h2 {
        font-size: 42px;
    }

    .srvc_imgicon {
        width: 110px;
        height: 110px;
    }
    div#sweet_sec .port_sweet {
        padding-left: 15%;
    }
    div#laundris_sec .port_laundris {
        padding-left: 15%;
    }
    #httssec .htts_port {
        padding-right: 0%;
        padding-left: 14%;
    }
    img.hm_pic1_web {
        width: 1130px;
        position: relative;
        top: 70px;
        left: -70px;
        z-index: 9999;
    }
    .addrs-rt {
        margin-top: 5px;
        padding-left: 10px;
    }
    .footer_sec ul li a {
        font-size: 14px;
    }
    .annual_colrt h2 {
        font-size: 52px;
    }
    .annual_colrt h2 span {
        font-size: 56px;
    }
    .alert.alert-success.mt-3.alrt_getquote {
        bottom: 24px;
    }
    .chose_coleft img.chs_leftbg {
        position: absolute;
        left: 0px;
        max-width: 100%;
        height: auto;
    }
    .events_slider a.carousel-control-next {
        left: 47%;
        bottom: -70px;
    }
    .uix_icn {
        width: 220px;
        height: 220px;
    }
    img.img-fluid.dm_arrow2 {
        position: absolute;
        left: 45%;
        top: 40%;
        max-width: calc(260px - 130px);
    }
    img.img-fluid.dm_arrow3 {
        position: absolute;
        right: 20%;
        top: 40%;
        max-width: calc(260px - 130px);
    }
    img.img-fluid.dm_arrow1 {
        position: absolute;
        top: 40%;
        left: 20%;
        max-width: calc(260px - 130px);
    }
    /* .col-lg-7.coladrs {
        margin-left: 0px;
    } */
    .choose_btnlink::before {
        width: 560px;
    }
    .choose_btnlink::after {
        width: 560px;
    }
    .slide_ryt .hm_appbg {
        margin-top: 40px;
        max-width: 100%;
        height: auto;
        margin-left: 35px;
    }
    .choose_srve_icn img {
        margin: auto;
        display: flex;
        width: 60px;
    }
    .choose_srve_icn {
        border-radius: 50%;
        border: 2px solid #1EB8E2;
        width: 180px;
        height: 180px;
        display: flex;
        background: white;
    }
    /**Game Page media css starts**/
    .gm_tch_rtextsec ul li {
        margin-top: 10px;
        padding-right: 20px;
    }
    .gm_tech_sec ul li img {
        width: 20px;
        margin-right: 5px;
    }
    .gm_tch_rtextsec ul {
        margin-left: 90px;
    }
    .gm_tch_rtextsec p {
        margin-left: 90px;
        padding-right: 50px;
        font-size: 16px;
        line-height: 24px;
        margin-top: 10px;
    }
    .gm_tch_rtextsec h3 {
        margin-left: 90px;
        font-size: 32px;
        margin-top: 30px;
    }
    .gm_tch_rtextsec {
        padding: 30px 0px;
        position: relative;
    }
    .gm_2d_leftxtsec ul li {
        margin-top: 10px;
        padding-right: 50px;
    }
    .gm_2d_leftxtsec ul {
        margin-left: 30px;
    }
    .gm_2d_leftxtsec p {
        margin-left: 30px;
        padding-right: 75px;
        font-size: 16px;
        line-height: 24px;
        margin-top: 10px;
    }
    .gm_2d_leftxtsec h3 {
        margin-left: 30px;
        margin-top: 0px;
    }
    .gm_2d_leftxtsec {
        padding: 30px 0px;
        position: relative;
    }
    /**Game Page media css ends**/
    /**Service Page media css starts**/
    .srvc_ltcol img {
        margin-top: 80px;
    }
    .chs_srvc2 span {
        right: 80px;
    }
    /**Service Page media css ends**/
    /**DM Page media css starts**/
    .dig_serve_sec {
        border: 1px solid #DEDEDE;
        background-color: #fff;
        border-radius: 30px;
    }
    .dm_icon_sec h4 {
        padding-top: 30px;
        font-size: 18px;
        padding-left: 10px;
        line-height: 27px;
        padding-right: 15px;
    }
    .dm_icon_sec img.digital_icn {
        padding-top: 30px;
        padding-left: 15px;
    }
    .dm_title h3 {
        font-size: 18px;
    }
    .dmcol_txt {
        padding-left: 20px;
    }
    /**DM Page media css ends**/
    /**Portfolio Page media css starts**/
    div#portfolio_sec2_pr .port_miapp {
        padding-right: 13%;
    }
    div#portfolio_sec1_pl .port_miapp {
        padding-left: 15%;
    }
    .port_miapp h3 {
        margin-bottom: 20px;
        margin-top: 15px;
    }
    .technology_logo_img {
        padding: 15px;
        height: 115px;
    }
    .technology_logo_img h6 {
        padding-top: 15px;
        font-size: 12px;
    }
    .port_miapp {
        padding: 70px 0px;
    }
    .port_imgauto {
        padding: 50px 30px;
    }
    /**Portfolio Page media css ends**/
    /**WEB DEVELOPMENT Media Css Starts**/
    .framework-rtsec hr {
        margin-bottom: 20px;
    }
    /**WEB DEVELOPMENT Media Css ends**/
    /**Why CHoose Us Media Css Starts**/
    .success_col18 {
        position: absolute;
        width: 300px;
        bottom: 300px;
        left: -35px;
    }
    .success_col20 {
        position: absolute;
        width: 300px;
        left: 125px;
        top: 215px;
    }
    .success_col19 {
        position: absolute;
        width: 310px;
        right: 30px;
        bottom: 100px;
    }
    /**Why CHoose Us Media Css Ends**/
    .chscol_txt h3 {
        font-size: 18px;
        line-height: 27px;
    }
    .chscol_txt p {
        font-size: 14px;
        line-height: 24px;
    }
    .chs_appicn_sec {
        width: 90px;
        height: 90px;
    }
    .chose_coleft img.img-fluid {
        position: relative;
        padding-left: 50px;
        padding-top: 30px;
        max-width: 80%;
    }
    .appstore_coll img {
        position: relative;
        left: -15px;
    }
}


/* // Small devices (landscape phones, 576px and up) */

@media (min-width: 576px) and (max-width: 767.98px) {
    .webport_btn {
        margin: unset;
        display: table;
    }
    /* nav.navbar .navbar-brand {
        margin-left: 20px;
    } */
    .choose_icn img {
        width: 30%;
    }
    .banr_ctxt {
        margin-top: 75px;
    }
    img.hm_pic1_web {
        display: none;
    }
    .serve_box {
        padding: 15px 25px;
    }
    .serve_box h4 {
        font-size: 14px;
        line-height: 21px;
    }
    .serve_box p {
        font-size: 14px;
        line-height: 24px;
    }
    .typeapp-txt {
        padding-top: 12px;
        padding-bottom: 20px;
        padding-left: 8px;
        padding-right: 8px;
    }
    .typeapp-txt h6 {
        font-size: 10px;
    }
    .typeapp-txt span i.fa-angle-right {
        width: 15px;
        height: 15px;
        font-size: 12px;
        padding-left: 6px;
        padding-top: 1px;
    }

    .addrs-rt {
        margin-top: 3px;
        padding-left: 0px;
    }
    /* .col-lg-7.coladrs {
        margin-left: 0px;
    } */
    .events_slider a.carousel-control-next {
        left: 50%;
    }
    .chs_srvc1 span {
        left: 215px;
    }
    .chs_srvc2 span {
        right: 240px;
    }
    .uix_icn {
        width: 180px;
        height: 180px;
    }
    .alert.alert-success.mt-3.alrt_getquote {
        bottom: 36px;
        right: 35px;
        left: auto;
        width: 60%;
    }
    .getquotes {
        padding-bottom: 15px;
    }
    .abtbanr_text {
        margin-top: 20px;
    }
    .dm_icon_sec h4 {
        font-size: 14px;
        padding-left: 0px;
        line-height: 22px;
        padding-right: 15px;
    }
    .hm_apc2 {
        display: none;
    }
    .hm_apc1 {
        display: none;
    }
    .hm_apc1 {
        display: none;
    }
    .slide_ryt .hm_appbg {
        display: none;
    }
    #banr_slider .banr_ctxt {
        margin-top: 80px;
    }
    .partnership_col img.agora_pimg {
        max-width: 240px;
        height: auto;
    }
    .serve_box {
        min-height: 355px;
        max-height: 390px;
        padding: 12px 15px;
    }
}

@media (min-width: 1700px) and (max-width: 1860px) {
    .serve_box {
        min-height: 360px !important;
        max-height: 370px !important;
    }
}

